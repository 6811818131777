import { all } from "redux-saga/effects";

//public
import AuthenticationSaga from "./authentication/saga";
import subscriptionsSaga from "./userSubscriptions/saga";
import sessionsSaga from "./sessions/saga";
import userSaga from "./users/saga";
import categoriesSaga from "./categories/saga";
import settings from "./settings/saga";
import paymentSaga from "./payment/saga";
// import announcementSaga from "./announcement/saga";

export default function* rootSaga() {
  yield all([
    AuthenticationSaga(),
    subscriptionsSaga(),
    sessionsSaga(),
    userSaga(),
    categoriesSaga(),
    settings(),
    paymentSaga(),
    // announcementSaga(),
  ]);
}
