const { default: server } = require("./server");

export const getAllSettingsApi = async () => {
  const response = await server().get("/settings");

  return response.data;
};

export const editMainSettingsApi = async (data) => {
  const response = await server().put("/settings/main", data);

  return response.data;
};

export const editSocialsSettingsApi = async (data) => {
  const response = await server().put("/settings/social", data);

  return response.data;
};

export const getFrontEndTestCategory = async () => {
  const response = await server().get("/pages/home");

  return response.data;
};
