import { useState } from "react";
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getAllSubscriptions } from "store/actions";
import Table from "./Table";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import { IoTimer } from "react-icons/io5";

const Subscriptions = () => {
  const { subscriptions, loading, error } = useSelector(
    (state) => state.subscriptions
  );
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  const [isDispatched, setIsDispatched] = useState(false);

  useEffect(() => {
    setIsDispatched(true);
    dispatch(getAllSubscriptions());
  }, [dispatch]);

  // error
  // useEffect(() => {
  //   if (error && isDispatched) {
  //     if (error.statusCode === 422)
  //       toastErrorMessage(formatMessage({ id: "delete_sub_error" }), locale);
  //     if (error.statusCode === 404)
  //       toastErrorMessage(
  //         `${formatMessage({ id: "item" })} ${formatMessage({
  //           id: "no-exist",
  //         })}`,
  //         locale
  //       );
  //     else
  //       toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
  //   }
  // }, [error, formatMessage, locale, isDispatched]);

  console.log("locale" ,  locale )

  const upgradeLink = locale === 'ar'
    ? process.env.REACT_APP_EXAMIST
    : process.env.REACT_APP_EXAMISTEN;

  return (
    <>
      <div className="subscriptions-wrap">
        <Table data={subscriptions} loading={loading} />
      </div>
      <div className="upgrade">
        <div className="time">
          <div className="image">
            <IoTimer />
          </div>
          <div className="title">
            <h3>   <FormattedMessage id="Upgrade" /> </h3>
            <p><FormattedMessage id="Improve" /></p>
          </div>
        </div>
        <a href={`${upgradeLink}subscriptions`} className="">
          <FormattedMessage id="Upgrade" />
        </a>
      </div>
    </>
  );
};

export default Subscriptions;
