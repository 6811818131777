import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import LightBox from "../shared/LightBox";
import { extractImagesSrcsNodes } from "../../helpers/functions";

function QuestionExplanation({ currentQuesion }) {
  const [imagesSrcs, setIMagesSrcs] = useState([]);

  useEffect(() => {
    const srcs = extractImagesSrcsNodes(currentQuesion?.explanation);
    setIMagesSrcs(srcs);
  }, [currentQuesion]);


  return (
    <div
      className="question_explantion_area__"
      dir="auto"
    >
      <Row className="demo_explantion_content__ justify-content-between align-items-center">
          <div
            className="html_content__"
            dir="auto"
            dangerouslySetInnerHTML={{
              __html: currentQuesion?.explanation,
            }}
          />
          <LightBox imagesSrcs={imagesSrcs} />
      </Row>
    </div>
  );
}

export default QuestionExplanation;
