import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  GET_APPLE_PAY_MERCHANT_SESSION,
  GET_PAYMENT_METHODS,
  SEND_PAYMENT,
  SEND_COUPON_CODE,
} from "./actionTypes";
import {
  getApplePayMerchantSessionApi,
  getPaymentMethodsApi,
  sendCouponCodeApi,
  sendPaymentApi,
} from "api/payment";
import {
  sendPaymentSuccess,
  sendPaymentFailure,
  getApplePayMerchantSessionSuccess,
  getApplePayMerchantSessionFailure,
  getPaymentMethodsSuccess,
  getPaymentMethodsFailure,
  sendCouponCodeFailure,
  sendCouponCodeSuccess
} from "./actions";


function* sendCouponCode({payload}) {
  try {
    const { data } = yield call(sendCouponCodeApi, payload.data);
    yield put(sendCouponCodeSuccess(data));

 } catch (error) {
    yield put(sendCouponCodeFailure(error.response.data || error));
  }
}

function* sendPaymentSaga({ payload }) {
  try {
    const { data } = yield call(sendPaymentApi, payload.data);

    yield put(sendPaymentSuccess(data));
  } catch (error) {
    yield put(sendPaymentFailure(error.response.data || error));
  }
}

function* getPaymentMethods({ payload }) {
  try {
    const { data } = yield call(getPaymentMethodsApi);

    yield put(getPaymentMethodsSuccess(data));
  } catch (error) {
    yield put(getPaymentMethodsFailure(error.response.data || error));
  }
}

function* getApplePayMerchantSession({ payload }) {
  try {
    const { data } = yield call(getApplePayMerchantSessionApi, payload.data);

    yield put(getApplePayMerchantSessionSuccess(data));
  } catch (error) {
    yield put(getApplePayMerchantSessionFailure(error.response.data || error));
  }
}

export function* watchPayment() {
  yield takeEvery(SEND_PAYMENT, sendPaymentSaga);
}

export function* watchGetPaymentMethods() {
  yield takeEvery(GET_PAYMENT_METHODS, getPaymentMethods);
}

export function* watchGetAppleMerchant() {
  yield takeEvery(GET_APPLE_PAY_MERCHANT_SESSION, getApplePayMerchantSession);
}

export function* watchSendCouponCode() {
  yield takeEvery(SEND_COUPON_CODE, sendCouponCode);
}

function* paymentSaga() {
  yield all([fork(watchPayment)]);
  yield all([fork(watchGetPaymentMethods)]);
  yield all([fork(watchGetAppleMerchant)]);
  yield all([fork(watchSendCouponCode)]);
}

export default paymentSaga;
