// import React from "react";
// import { getSidebarData    } from "../../data";
// import TopBlockItem from "./TopBlockItem";
// import { useIntl } from "react-intl";

// const BlocksTop = () => {
//   const topCards = sidebarData.map((topcard) => {
//     return <TopBlockItem key={topcard.id} topcard={topcard} />;
//   });
//   const { locale } = useIntl();
//   const sidebarData = locale === "ar" ? getSidebarData("ar") : getSidebarData("en");
//   return (
//     <>
//       <div className="topCards">{/* <Row>{topCards}</Row> */}</div>
//     </>
//   );
// };

// export default BlocksTop;


import React from "react";
 
const BlocksTop = () => {
  return (
    <>
      <div className="topCards"></div>
    </>
  );
};

export default BlocksTop;
