import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { editUser, getAllUniversities, getAllCities } from "store/actions";
import Loader from "components/shared/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import Select from "react-select";
import { ReactComponent as Correct } from "assets/svgs/correct.svg";
import { ReactComponent as InCorrect } from "assets/svgs/inCorrect.svg";
import ImageUpload from "components/shared/ImageUpload";

const EditUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { locale, formatMessage } = useIntl();

  const {
    authentication: { user, loading },
    user: { universities: universitiesRaw, cities: citiesRaw },
  } = useSelector((state) => state);

  const [citiesOptions, setCitiesOptions] = useState([]);
  const [universitiesOptions, setUniversitiesOptions] = useState([]);
  const [city, setCity] = useState("");
  const [university, setUniversity] = useState("");
  const [selectedImage, setSelectedImage] = useState({});

  const { register, handleSubmit, control, reset } = useForm();

  useEffect(() => {
    dispatch(getAllUniversities());
    dispatch(getAllCities());
  }, []);
  useEffect(() => {
    reset({
      fullName: user?.fullName,
      email: user?.email,
      phone: user?.phone,
      // city: user?.city?.name?.[locale],
      type: user?.type,
      // university: user?.university?.name,
      // isVerified: user?.isVerified,
    });

    setCity(user?.city?._id || user?.city);
    setUniversity(user?.university?._id || user?.university);
    setSelectedImage({ path: user?.image });

    if (citiesRaw?.length > 0 && universitiesRaw?.length > 0) {
      setCitiesOptions(
        citiesRaw?.map((e) => ({
          label: e?.name?.[locale],
          value: e?.id,
        }))
      );
      setUniversitiesOptions(
        universitiesRaw?.map((e) => ({
          label: e?.name,
          value: e?.id,
        }))
      );
    }
  }, [reset, user, citiesRaw, universitiesRaw]);

  const onSubmit = (data) => {
    // delete data.university;
    // delete data.isVerified;
    // delete data.city;
    // data.type = user.type;
    data.city = city;
    data.university = university;
    if (selectedImage?.preview != undefined)
      data.image = selectedImage?.path || "";

    dispatch(editUser({ id, data, navigate }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>{formatMessage({ id: "editAccount" })}</h4>
          </div>
          <div className="card-body">
            <Row>
              <ImageUpload
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
              />
            </Row>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group">
                      <h5>{formatMessage({ id: "userName" })}</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={formatMessage({ id: "userName" })}
                          {...register("fullName", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group">
                      <h5>{formatMessage({ id: "phoneNumber" })}</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={formatMessage({ id: "phoneNumber" })}
                          {...register("phone", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group">
                      <h5>{formatMessage({ id: "email" })} </h5>
                      <div>
                        <input
                          type="email"
                          className="form-control form-outline"
                          placeholder={formatMessage({ id: "email" })}
                          {...register("email", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  {/* <Col lg={6} xs={12}>
                    <div className="form-group">
                      <h5>{formatMessage({ id: "city" })} </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={formatMessage({ id: "city" })}
                          {...register("city", { required: true })}
                        />
                      </div>
                    </div>
                  </Col> */}
                  {/* <Col lg={6} xs={12}>
                    <div className="form-group">
                      <h5>{formatMessage({ id: "type" })} </h5>
                      <div>
                      </div>
                      </div>
                    </Col> */}
                  <input
                    type="hidden"
                    {...register("type", { required: true })}
                  />
                  <Col lg={6} xs={12}>
                    <div className="form-group">
                      <h5>{formatMessage({ id: "isVerified" })} </h5>
                      <div>
                        {/* <input
                          type="checkbox"
                          disabled={true}
                          className="form-control"
                          placeholder={formatMessage({ id: "isVerified" })}
                          {...register("isVerified", { required: false })}
                        /> */}
                        {user?.isVerified ? (
                          <span className="badge rounded-pill bg-success position-relative">
                            <Correct
                              className="position-static"
                              style={{ transform: "unset" }}
                              fill="white"
                              width="24"
                            />
                          </span>
                        ) : (
                          <span className="badge rounded-pill bg-danger">
                            <InCorrect
                              className="position-static"
                              style={{ transform: "unset" }}
                              fill="white"
                              width="24"
                            />
                          </span>
                        )}
                      </div>
                    </div>
                  </Col>

                  {/* <Col lg={6} xs={12}>
                    <div className="form-group">
                      <h5>{`${formatMessage({ id: "name" })} ${formatMessage({
                        id: "university",
                      })}`}</h5>
                      <div>
                        <input
                          type="text"
                          disabled={true}
                          className="form-control"
                          placeholder={formatMessage({ id: "university" })}
                          {...register("university.name", { required: false })}
                        />
                      </div>
                    </div>
                  </Col> */}
                  <Col lg={6} xs={12}>
                    <div className="form-group">
                      <h5>{`${formatMessage({ id: "name" })} ${formatMessage({
                        id: "city",
                      })}`}</h5>
                      <div>
                        <Select
                          placeholder={formatMessage({
                            id: "city",
                          })}
                          className="basic-single"
                          classNamePrefix="select"
                          options={citiesOptions}
                          value={citiesOptions.filter((e) => e.value == city)}
                          onChange={(e) => {
                            setCity(e.value);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group">
                      <h5>{`${formatMessage({ id: "name" })} ${formatMessage({
                        id: "university",
                      })}`}</h5>
                      <div>
                        <Select
                          placeholder={formatMessage({
                            id: "university",
                          })}
                          className="basic-single"
                          classNamePrefix="select"
                          options={universitiesOptions}
                          value={universitiesOptions.filter(
                            (e) => e.value == university
                          )}
                          onChange={(e) => {
                            setUniversity(e.value);
                          }}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col className="col-12"></Col>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        {formatMessage({ id: "save" })}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUser;
