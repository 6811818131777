/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { ReactComponent as FinalResult } from "assets/svgs/final-result.svg";
import { ReactComponent as Skipped } from "assets/svgs/skipped.svg";
import { ReactComponent as Verify } from "assets/svgs/verify.svg";
import { ReactComponent as Wrong } from "assets/svgs/close-circle.svg";
import ResultQuestionComponent from "./ResultQuestionComponent";
import "styles/sessions.css";
import { useMemo } from "react";

export default function ExamResultComponent({
  activeSession,
  terminateConfirmation = () => {},
  page = "",
  filterFlag = "",
}) {
  const parentRef = useRef();
  const [pageNum, setPageNum] = useState(1);
  const paginationsLimit = 37,
    paginationInnerNum = 6;
  const [filter, setFilter] = useState(filterFlag);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterCheck = (q) => {
    if (filter == "inCorrect") return q.isCorrect == false;
    if (filter == "isCorrect") return q.isCorrect == true;
    if (filter == "isSkipped")
      return (
        (q.isSkipped == undefined && q.isCorrect == undefined) ||
        q.isSkipped == true
      );
    return false;
  };

  const questions = useMemo(
    () =>
      activeSession?.questions
        ?.filter((q) => filterCheck(q))
        ?.map((q) => ({
          ...q,
          answered: q?.isCorrect != undefined || q?.isSkipped != undefined,
        })) || [],
    [activeSession?.questions, filterCheck]
  );

  const ViewCheck = ({ type }) => (
    <a
      className={`result-view-more ${filter == type && "active"}`}
      onClick={() => (filter == type ? setFilter("") : setFilter(type))}
    >
      {filter == type ? (
        <FormattedMessage id="hide" />
      ) : (
        <FormattedMessage id="view" />
      )}
    </a>
  );

  const renderQuestions = useMemo(
    () =>
      // questionsChunks[pageNum]
      (questions?.length > paginationsLimit
        ? questions?.slice(0, pageNum * paginationInnerNum)
        : questions
      )?.map((q, index) => (
        <ResultQuestionComponent
          key={index}
          questionsIndex={index}
          question={{ ...q?.question, ...q, question: "", index }}
          page={page}
        />
      )),
    [JSON.stringify(activeSession?.questions), filter, pageNum]
  );

  return (
    <div className="result-wrapper ">
      <div className=" result-card">
        <div className="card-header">
          {page == "" ? (
            <FormattedMessage id="exam-result" />
          ) : (
            <FormattedMessage id="go-latest-session" />
          )}
        </div>
        {page != "type" && (
          <Row>
            <Col>
              <div className="icon">
                <FinalResult />
              </div>
              <div>
                <FormattedMessage id="resultPercentage" />
              </div>
              <div>
                {Math.round(
                  (activeSession?.correctAnswers /
                    activeSession?.numberOfQuestions) *
                    100
                )}{" "}
                %
              </div>
            </Col>
            <Col className="col-12 col-md-1">
              <div className="separator"></div>
            </Col>
            <Col>
              <div className="icon">
                <Skipped />
              </div>
              <div>
                <FormattedMessage id="skippedAnswers" />
              </div>
              <div>
                {activeSession?.numberOfQuestions -
                  (activeSession?.correctAnswers +
                    activeSession?.inCorrectAnswers) || "0"}
              </div>
              <div>
                {activeSession?.numberOfQuestions -
                  (activeSession?.correctAnswers +
                    activeSession?.inCorrectAnswers) >
                  0 && <ViewCheck type="isSkipped" />}
              </div>
            </Col>
            <Col className="col-12 col-md-1">
              <div className="separator"></div>
            </Col>
            <Col>
              <div className="icon">
                <Verify />
              </div>
              <div>
                <FormattedMessage id="correctAnswers" />
              </div>
              <div>{activeSession?.correctAnswers}</div>
              <div>
                {activeSession?.correctAnswers > 0 && (
                  <ViewCheck type="isCorrect" />
                )}
              </div>
            </Col>
            <Col className="col-12 col-md-1">
              <div className="separator"></div>
            </Col>
            <Col>
              <div className="icon">
                <Wrong />
              </div>
              <div>
                <FormattedMessage id="inCorrectAnswers" />
              </div>
              <div>{activeSession?.inCorrectAnswers}</div>
              <div>
                {activeSession?.inCorrectAnswers > 0 && (
                  <ViewCheck type="inCorrect" />
                )}
              </div>
            </Col>
          </Row>
        )}
      </div>
      <Row className="result-questions-wrapper" ref={parentRef}>
        {renderQuestions}
      </Row>
      {questions?.length > paginationsLimit &&
        questions?.length > pageNum * paginationInnerNum && (
          <button
            className="show-more-button"
            // className={`page-link page-item next-link`}
            onClick={(e) => {
              e.preventDefault();
              setPageNum(pageNum + 1);
            }}
          >
            <FormattedMessage id="show-more" />
          </button>
        )}
      {page === "" && (
        <Row className="result-finish">
          <a onClick={terminateConfirmation} className="finish">
            <FormattedMessage id="finish" />
          </a>
        </Row>
      )}
    </div>
  );
}

function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}
