import React, { useEffect, useState } from "react";
import { Col, Modal, ProgressBar, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import BlockItem from "./BlockItem";
import { getId, handleImage, handleIsoDate } from "helpers/functions";
import { ReactComponent as SubIcon } from "assets/svgs/subscription.svg";
import { ReactComponent as DaysIcon } from "assets/svgs/month-icon.svg";
import { ReactComponent as SubCatIcon } from "assets/svgs/multiple-layers-icon.svg";
import { ReactComponent as SessionsIcon } from "assets/svgs/sessions.svg";
import { ReactComponent as Correct } from "assets/svgs/correct.svg";
import { ReactComponent as InCorrect } from "assets/svgs/inCorrect.svg";
import { ReactComponent as ExclamationIcon } from "assets/svgs/exclamation-round-line-icon.svg";
import { ReactComponent as Star } from "assets/svgs/star.svg";
import { ReactComponent as Comment } from "assets/svgs/chat-square-text.svg";
import { ReactComponent as Alert } from "assets/svgs/alert.svg";
import dashPlaceholder from "assets/images/dashboard-placeholder.png";
import { Link } from "react-router-dom";
import CheckToDisableComponent from "components/shared/CheckToDisableComponent";
import { useDispatch, useSelector } from "react-redux";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { dismissAnnouncement } from "store/actions";
// import { Swiper, SwiperSlide } from "swiper/react";
import { IoSearch } from "react-icons/io5";
import { MdOutlineQuestionMark } from "react-icons/md";
import { FaFire } from "react-icons/fa6";
import media from "../../assets/images/171274892.png"

const Blocks = ({ savedQuestions, commentedOnQuestions }) => {
  const { formatMessage, locale, formatDate } = useIntl();
  const { userStats, user } = useSelector((state) => state.authentication);
  const { notifications } = useSelector((state) => state.user);

  const {
    totalSessions,
    totalAnswersResult,
    correctAnswersResult,
    inCorrectAnswersResult,
    skippedAnswersResult,
    resultPercentage,
  } = userStats;

  const unReadNotifications = notifications?.filter((n) => !n.read);

  const subscriptionBlocks = () => {
    const daysLeftMessage =
      userStats?.currentSubscription?.subscriptionDaysReminder > 1
        ? "days"
        : userStats?.currentSubscription?.subscriptionDaysReminder !=
          undefined
          ? "day"
          : "notFound",
      plan =
        userStats?.currentSubscription?.plan ||
        userStats?.currentSubscription?.subscription?.plan ||
        "0",
      planMessage =
        userStats?.currentSubscription?.subscription?.plan > 1
          ? "months"
          : "month";



    return [
      <BlockItem
        title={`${formatMessage({
          id: "subscriptionStart",
        })} -- ${formatDate(userStats?.currentSubscription?.startAt, {
          day: "numeric",
          month: "long",
          year: "numeric",
        }) ||
        formatMessage({
          id: "notFound",
        })
          }`}
        date={`${formatMessage({
          id: "subscriptionEnd",
        })} -- ${formatDate(userStats?.currentSubscription?.endAt, {
          day: "numeric",
          month: "long",
          year: "numeric",
        }) ||
        formatMessage({
          id: "notFound",
        })
          }`}
        className="card-grey-bg"
        Icon={SubIcon}
        key="1"
      />,
      <BlockItem
        title={`${formatMessage({
          id: "days",
        })} ${formatMessage({
          id: "left",
        })}`}
        count={`${userStats?.currentSubscription?.subscriptionDaysReminder > 0
          ? userStats?.currentSubscription?.subscriptionDaysReminder
          : 0 || ""
          } ${formatMessage({
            id: daysLeftMessage,
          })}`}
        className="card-grey-bg"
        Icon={DaysIcon}
        key="2"
      />,
      <BlockItem
        title={"subscriptionStatus"}
        count={`
        ${plan}
        ${formatMessage({
          id: planMessage,
        })}`}
        className="card-grey-bg"
        Icon={SubCatIcon}
        key="3"
      />,
    ];
  };
  const blocks = [
    <BlockItem
      title={"sessions"}
      count={totalSessions}
      className="card-grey-bg"
      Icon={SessionsIcon}
      key="1"
    />,
    <BlockItem
      title={"answers"}
      className="card-blue-bg"
      count={totalAnswersResult || "0"}
      key="2"
    />,
    <BlockItem
      title={"correctAnswers"}
      className="card-green-bg"
      count={correctAnswersResult || "0"}
      Icon={Correct}
      type="isCorrect"
      key="3"
    />,

    <BlockItem
      title={"inCorrectAnswers"}
      className="card-red-bg"
      count={inCorrectAnswersResult || "0"}
      Icon={InCorrect}
      type="inCorrect"
      key="4"
    />,
    <BlockItem
      title={"skippedAnswers"}
      className="card-yellow-bg"
      count={skippedAnswersResult || "0"}
      Icon={ExclamationIcon}
      type="isSkipped"
      key="5"
    />,
    <BlockItem
      title={"resultPercentage"}
      className="card-white-bg"
      count={(resultPercentage || "0") + " %"}
      key="6"
    />,
  ];

  const randomNumbers = Array.from({ length: 7 }, () => Math.floor(Math.random() * 100) + 1);

  const [selectedNumber, setSelectedNumber] = useState(0);

  const handleRadioClick = (index) => {
    setSelectedNumber(randomNumbers[index]);
  };


  return (
    <>
      <div className="projects-blocks card">
        <div className="welcome">
          <Row>

            <Col md={6} >
              <div className="info">
                <h2>{formatMessage({ id: "welcom" })} , {user?.fullName} <span className="wave">👋</span>  </h2>
                <p>{formatMessage({ id: "niceDay" })}</p>
              </div>

            </Col>
            <Col md={6} >

            </Col>
          </Row>

        </div>
        <CheckToDisableComponent>

          {/* {!totalSessions > 0 && (
            <Row className="mx-auto mb-3 mt-2 text-white justify-content-center">
              <FormattedMessage id="have-not-begin" />
            </Row>
          )} */}
          <Row className="mb-5">

            <Col md={6} >
              <div className="questions">
                <div className="image">
                  <MdOutlineQuestionMark />

                </div>
                <h3 className="title mt-4"> {formatMessage({ id: "questions" })}
                </h3>
                <div className="progress_questions">
                  <ProgressBar now={(correctAnswersResult / totalAnswersResult) * 100 || 0} />
                </div>
                <div className="static">
                  <span> {correctAnswersResult || "0"} {formatMessage({ id: "answered" })}   </span>
                  <span>  {formatMessage({ id: "totalqwe" })}  {totalAnswersResult || "0"}</span>
                </div>
                <Link to="/sessions/add" className=" mt-auto   d-flex w-100  ">
                  <button
                    type="button"
                    className=" mt-auto  new-session-anchor   active"
                  >
                    <FormattedMessage id="begin-new-session" />
                  </button>
                </Link>
              </div>

            </Col>

            <Col md={6} >
              <div className="questions">
                <div className="image">
                  <FaFire />
                </div>
                <h5 className="special"> {formatMessage({ id: 'DailyStreak' })} </h5>
                <div className="streak">
                  <div className="circle">
                    <span>{selectedNumber}</span>
                    <p>{formatMessage({ id: 'DailyStreak' })}</p>
                  </div>
                </div>
                <div className="radio-buttons-container">
                  {['Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'].map((day, index) => (
                    <div className="radio-button" key={index}>
                      <input
                        name="radio-group"
                        id={`radio${index}`}
                        className="radio-button__input"
                        type="radio"
                        onClick={() => handleRadioClick(index)}
                      />
                      <label htmlFor={`radio${index}`} className="radio-button__label">
                        <span className="radio-button__custom"></span>
                        {day}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          </Row>


        </CheckToDisableComponent>

       
        <Row className="mt-4">
          {userStats?.currentSubscription && subscriptionBlocks()}
          {totalSessions > 0 && userStats?.currentSubscription && blocks}
          {savedQuestions?.length > 0 && (
            <BlockItem
              title={"savedQuestions"}
              className="card-gold-bg"
              count={savedQuestions?.length || "0"}
              Icon={Star}
              type="page"
              url="/saved-questions"
            />
          )}
          {commentedOnQuestions?.length > 0 && (
            <BlockItem
              title={"commentedOn"}
              className={`card-blue-gradient-bg ${
                unReadNotifications?.length > 0 ? "alert" : ""
              }`}
              count={commentedOnQuestions?.length || "0"}
              Icon={Comment}
              type="page"
              url="/commented-on"
            />
          )}
          {/* {(totalSessions > 0 || commentedOnQuestions?.length > 0) &&
            user?.newAnnouncements?.filter((e) => e?.announcement)?.length >
              0 && <Announces locale={locale} data={user?.newAnnouncements} />} */}
        </Row>
      </div >
    </>
  );
};

const Announces = ({ locale, data }) => {
  const dispatch = useDispatch();
  const dir = locale === "ar" ? "rtl" : "ltr";
  const autoPlayTime = 5500;

  const [modalShow, setModalShow] = useState(false);

  const [selectedAnnounce, setSelectedAnnounce] = useState();
  const [backColor, setBackColor] = useState("");

  const filteredData = data?.filter((e) => e?.announcement);

  const handleSwipeChange = (swiper) =>
    setBackColor(filteredData?.[swiper?.activeIndex]?.announcement?.color);

  // useEffect(() => {
  //   let interval = setInterval(() => {
  //     console.log("swiper.activeIndex", swiper?.activeIndex);
  //     setBackColor(filteredData?.[swiper?.activeIndex]?.announcement?.color);
  //   }, autoPlayTime);

  //   return () => clearInterval(interval);
  // }, [filteredData, swiper?.activeIndex]);

  const renderSlides = filteredData?.map(({ announcement, _id }, index) => (
    <SwiperSlide key={index} className="py-2">
      <Row
        className="mx-0 cursor-pointer"
        onClick={() => {
          setSelectedAnnounce(announcement);
          setModalShow(true);
        }}
      >
        <Col xs="9">
          <Row>
            <Col xs="4">
              <img
                src={handleImage(announcement?.image)}
                className="rounded-3 w-auto mw-100"
                alt=""
                style={{ height: "50px", objectFit: "cover" }}
              />
            </Col>
            <Col xs="8" className="d-flex flex-column justify-content-evenly">
              <h4 className="fs-5" title={announcement?.title?.[locale]}>
                {announcement?.title?.[locale]}
              </h4>
              <h4 title={announcement?.description?.[locale]}>
                {announcement?.description?.[locale]}
              </h4>
            </Col>
          </Row>
        </Col>
        <Col xs="3" className="align-self-center text-center">
          <button
            className="bg-transparent py-1 border border-1 border-white rounded-circle"
            onClick={() => dispatch(dismissAnnouncement(_id))}
          >
            <InCorrect width="20" fill="white" />
          </button>
        </Col>
      </Row>
    </SwiperSlide>
  ));

  return (
    <Col xs={12} sm={6} md={8} xl="6">
      <div
        className="card card-semi-transparent"
        style={{ background: backColor }}
      >
        <div
          className="b-info d-flex flex-column justify-content-evenly"
          style={{ width: "100%" }}
        >
          <h4>
            <FormattedMessage id="announcements" />
          </h4>
          <Swiper
            key={dir}
            dir={dir}
            onActiveIndexChange={handleSwipeChange}
            direction="vertical"
            effect={"fade"}
            centerInsufficientSlides
            autoplay={{
              delay: autoPlayTime,
              disableOnInteraction: false,
            }}
            slidesPerView="auto"
            spaceBetween={7}
            modules={[Autoplay]}
            style={{ maxHeight: "62px", width: "100%" }}
          >
            {renderSlides}
          </Swiper>
          <Modal show={modalShow} onHide={() => setModalShow(!modalShow)}>
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id="announcements" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column">
              {filteredData?.map(({ announcement, _id }, index) => (
                <>
                  <Row
                    key={index}
                    style={{
                      background: announcement?.color,
                      borderRadius: "8px",
                      padding: "8px 0",
                    }}
                  >
                    <Col xs="4">
                      <img
                        src={handleImage(announcement?.image)}
                        className="rounded-3 w-auto mw-100"
                        alt=""
                        style={{ height: "50px", objectFit: "cover" }}
                      />
                    </Col>
                    <Col
                      xs="8"
                      className="d-flex flex-column justify-content-evenly"
                    >
                      <h4
                        className="fs-5"
                        title={announcement?.title?.[locale]}
                      >
                        {announcement?.title?.[locale]}
                      </h4>
                      <h4
                        title={announcement?.description?.[locale]}
                        className="d-block fs-6 opacity-75"
                      >
                        {announcement?.description?.[locale]}
                      </h4>
                    </Col>
                  </Row>
                  {index !== filteredData?.length - 1 && (
                    <hr
                      key={index}
                      className="m-auto my-3 border-0 bg-secondary"
                      style={{ height: "1px" }}
                    />
                  )}
                </>
              ))}
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </Col>
  );
};

export default Blocks;
