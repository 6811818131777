import { Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import visa from 'assets/images/Visa_Logo.png';
import mastercard from 'assets/images/mastercard-logo-mastercard-logo-png-vector-download-19.png';
import stcPay from 'assets/images/stc-pay.png';
import { ReactComponent as Apple } from 'assets/svgs/apple-pay.svg';
import 'styles/payment.css';
import tamara from 'assets/images/tamara.png';

const PaymentOptions = ({ paymentOption, setPaymentOption, safariBrowser, paymentMethods }) => {
  return (
    <div className='payment-methods-options card'>
      <Row>
        <div>
          <label className='payment-option'>
            <input
              type='radio'
              name='paymentMethod'
              value={paymentMethods?.[0]?.id || 'visa'}
              checked={paymentOption?.type === 'creditcard'}
              onChange={() =>
                setPaymentOption({
                  id: paymentMethods?.[0]?.id,
                  name: paymentMethods?.[0]?.name?.en,
                  type: 'creditcard',
                })
              }
            />
            <div className='card'>
              <img src={visa} alt='Visa' />
              <img src={mastercard} alt='MasterCard' />
            </div>
          </label>
          <label className='payment-option'>
            <input
              type='radio'
              name='paymentMethod'
              value={paymentMethods?.[1]?.id || 'tamara'}
              checked={paymentOption?.type === 'tamara'}
              onChange={() =>
                setPaymentOption({
                  id: paymentMethods?.[1]?.id,
                  name: paymentMethods?.[1]?.name?.en,
                  type: 'tamara',
                })
              }
            />
            <div className='card'>
              <img src={tamara} alt='Tamara' />
            </div>
          </label>

          {safariBrowser && (
            <label className='payment-option'>
              <input
                type='radio'
                name='paymentMethod'
                value={paymentMethods?.[0]?.id || 'apple'}
                checked={paymentOption?.name === 'apple'}
                onChange={() =>
                  setPaymentOption({
                    id: paymentMethods?.[0]?.id,
                    name: 'apple',
                    type: 'apple',
                  })
                }
              />
              <div className='card'>
                <Apple />
              </div>
            </label>
          )}
        </div>
      </Row>
    </div>
  );
};

export default PaymentOptions;
