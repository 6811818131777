import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import Loader from "components/shared/Loader";
import "styles/sessions.css";
import ExamSession from "components/session/ExamComponent";

const View = () => {
  const { loading: catLoading } = useSelector((state) => state.categories);
  const { loading: sessionLoading } = useSelector((state) => state.sessions);

  if (catLoading || sessionLoading) return <Loader />;

  return (
    <>
      <div className="view-page session-add addExams">
        <div className="card">
          <div className="card-body ">
            <Col md={12}>
              <Row>
                <ExamSession />
              </Row>
            </Col>
          </div>
        </div>
      </div>
    </>
  );
};

export default View;
