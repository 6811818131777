import { getSidebarData      } from "data";
import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

import logoInfo from "assets/images/Examist-1.svg";
import { ReactComponent as Arrow } from "assets/svgs/arrow.svg";
import { ReactComponent as Day } from "assets/svgs/day.svg";
import { ReactComponent as Night } from "assets/svgs/night.svg";
import { FormattedMessage, useIntl } from "react-intl";
import {
  sidebarToggleFunction,
  themeSwitcherFunction,
} from "helpers/functions";
// import { checkPermissions } from "helpers/authentication/checkPermissions";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

const Sidebar = () => {
  const store = localStorage.getItem("store");
  const { userStats, logo } = useSelector((state) => state.authentication);
  const { locale } = useIntl();

  const { pathname } = useLocation();
  // store the theme in the localStorage and invoke the theme function
  const themeSwitcher = () => {
    const theme = localStorage.getItem("theme");
    if (!theme) {
      localStorage.setItem("theme", "dark");
    }
    if (theme) {
      localStorage.setItem("theme", theme === "dark" ? "light" : "dark");
    }
    themeSwitcherFunction();
  };

  const sidebarStatus = (e) => {
    // if (!e?.target?.closest(".has-children-menu")) {
    // }
    sidebarToggleFunction();
  };

  const isActiveMenuChilren = (currentElemntLocation, hasChildrenClass) => {
    if (!hasChildrenClass) {
      return false;
    }
    return window.location.pathname.includes(currentElemntLocation?.to);
  };

  const handleMenuClick = (e) => {
    const element = e.target.closest(".has-children-menu");

    if (element) {
      element.classList.add("open-children-menu");
    } else {
      document.querySelectorAll(".has-children-menu").forEach((ele) => {
        ele.classList.remove("open-children-menu");
      });
    }
  };

  useEffect(() => {
    const theme = localStorage.getItem("theme"),
      token = Cookies.get("dash-token");
    if (theme === "dark" && token) {
      document.querySelector("body").classList.add("dark");
      document.querySelector(".themetoggle").classList.add("fire");
    }
  }, []);

  const sidebarData = locale === "ar" ? getSidebarData("ar") : getSidebarData("en");
  const logOut = locale === "ar" ? getSidebarData("ar") : getSidebarData("en");

  return (
    <>
      <div className="main-sidebar">

        <div className="side-nav">

         
              <ul>
                {sidebarData?.map((item) => {
                  if (item.storeNeeded && !store) return false;
                  if (item.subscriptionSensitive && !userStats?.currentSubscription)
                    return false;

                  // if (!checkPermissions(item?.permissions)) return false;

                  if (item?.separatedHead) {
                    return (
                      <div key={item?.id} className="separated-head">
                        <h3 className="title">
                          {<FormattedMessage id={item?.text} />}
                        </h3>
                      </div>
                    );
                  }

                  return (
                    <li key={item?.id}>
                      <div
                        className={`side-nav-child ${item?.children?.length >= 1 && "has-children-menu"
                          } ${isActiveMenuChilren(
                            item,
                            item?.children?.length >= 1 ? true : false
                          )
                            ? "active-children-menu"
                            : ""
                          }`}
                        onClick={handleMenuClick}
                      >
                        <NavLink
                          to={item?.to !== "sessions" && item?.to}
                          className={({ isActive }) =>
                            item?.to === pathname ? "active" : ""
                          }
                          onClick={item?.to !== "sessions" && sidebarStatus}
                        >
                          {item?.svg}
                          <span>
                            <FormattedMessage id={item?.text} />
                          </span>
                        </NavLink>
                        {item?.children?.length >= 1 && (
                          <div className="arrow">
                            <Arrow />
                          </div>
                        )}
                        {item?.children?.length >= 1 && (
                          <ul className="children">
                            <li>
                              {item?.children?.map((childNav) => (
                                <NavLink
                                  key={childNav?.id}
                                  to={childNav?.to}
                                  // isActive={() => pathname == childNav?.to}
                                  className={({ isActive }) => {
                                    return pathname == childNav?.to
                                      ? "active-child"
                                      : "";
                                  }}
                                  onClick={sidebarStatus}
                                >
                                  {childNav?.svg}
                                  <span>
                                    <FormattedMessage
                                      id={`${childNav?.text ?? null}`}
                                    />
                                  </span>
                                </NavLink>
                              ))}
                            </li>
                          </ul>
                        )}
                      </div>
                    </li>
                  );
                })}
              </ul>
           

        </div>
        <div className="side-actions">
          <button className="themetoggle" onClick={themeSwitcher}>
            <i>
              <Day />
            </i>
          </button>
          <div className="logout">
            <NavLink to="/logout">
              <Night />

              <span>
                <FormattedMessage id={"logout"} />
              </span>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
