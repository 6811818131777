import server from "./server";


export const sendCouponCodeApi = async (data) => {
  const response = await server().post(
    "/coupons/verify",
    data
  );
  return response.data;
};

export const sendPaymentApi = async (data) => {
  const response = await server().post("/auth/userSubscriptions", data);

  return response.data;
};

export const payemntOTPApi = async (data) => {
  const response = await server().post(
    '/auth/userSubscriptions/payment/otp',
    data
  );

  return response.data;
};
export const getPaymentMethodsApi = async () => {
  const response = await server().get("/paymentMethodes");

  return response.data;
};

export const getApplePayMerchantSessionApi = async () => {
  const response = await server().get(
    "/userSubscription/createMerchantSession"
  );

  return response.data;
};
