import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { addSession } from "store/actions";
import { ReactComponent as Questionmark } from "assets/svgs/question-mark.svg";
import { useForm } from "react-hook-form";
import { Input } from "components/shared/FormComponents";
import Select from "react-select";
import "styles/sessions.css";
import { toast } from "react-toastify";
import { getFrontEndTestCategory } from "api/settings";
import { getId } from "helpers/functions";
import server from "api/server";

const questionTypeFilters = [
  { key: "questionsCount", value: "complete" },
  { key: "inCorrectQuestions", value: "incorrect" },
  { key: "isskippedQuestions", value: "skipped" },
  { key: "markedQuestions", value: "marked" },
  { key: "notCompletedQestion", value: "notCompleted" },
];

const Form = () => {
  const getOptions = (arr) =>
    arr?.length > 0
      ? arr
          ?.sort((a, b) => a?.order - b?.order)
          ?.map((e) => ({
            label: e?.name?.[locale],
            value: e?.id || e?._id,
            subCategories: e?.subCategories || [],
            questionsCount: e?.questionsCount || "",
          }))
      : [{ label: formatMessage({ id: "no-options" }) }];

  const { locale, formatMessage } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedSubCategory, setSelectedSubCategory] = useState(undefined);
  const [subCategoriesChildren, setSubCategoriesChildren] = useState([]);
  const [duration, setSelectedDuration] = useState(0);
  const [numberOfQuestions, setQuestionNum] = useState(0);
  const [mainCategoriesList, setMainCategoriesList] = useState([]);
  const [selectedSubCategoryChild, setSelectedSubCategoryChild] = useState(); //hashed feature of the sub sub category -- 4-4-2023
  // const [newQuestionsOnly, setNewQuestionsOnly] = useState(false); //hashed feature of new questions only check -- 1-6-2023
  const [questionsType, setQuestionsType] = useState("complete");

  const [subCatStats, setSubCatStats] = useState();

  const [durations, setDurations] = useState(
    ["Unlimited", ...Array.from(Array(50))].map((d, k) => ({
      label: d == "Unlimited" ? formatMessage({ id: d || "notFound" }) : k * 4,
      value: d == "Unlimited" ? d : k * 4,
    }))
  );

  const { categories } = useSelector((state) => state.categories);
  const { error } = useSelector((state) => state.sessions);

  useEffect(() => {
    // let unmounted = false;
    // (async () => {
    //   let testCatId = await getFrontEndTestCategory();
    //   testCatId =
    //     testCatId?.data?.page?.content?.demoTest?.category?.mainCategory
    //       ?.value || "";
    //   if (!unmounted)
    //     setMainCategoriesList(
    //       categories?.filter((e) => e?._id != testCatId) || []
    //     );
    // })();
    // return () => {
    //   unmounted = true;
    // };
    setMainCategoriesList(categories?.filter(item => !item.category));

  }, [categories]);

  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm();

  useEffect(() => {
    if (error != "")
      toast.error(
        formatMessage({ id: error?.message || "sendingErrorMessage" })
      );
  }, [error]);

  const handleSelectSubCat = async (selectedOption) => {
    setSelectedSubCategory(selectedOption);
    setSelectedSubCategoryChild("");

    if (selectedOption?.value?.length > 0) {
      setSubCategoriesChildren(
        categories?.filter((e) => getId(e?.category) === selectedOption?.value)
      );
      getCatStats(selectedOption);
    }
  };

  const getCatStats = async (selectedOption) => {
    setSubCatStats();

    try {
      const { data } = await server().get(
        `/categories/${selectedOption?.value}`
      );
      setSubCatStats(
        data?.data?.category?.statistis || data?.data?.category?.statistics
      );
    } catch (error) {
      console.log("err", error);
    }
  };

  console.log('subCatStats', subCatStats)
  const onSubmit = (e) => {
    e?.preventDefault();
    if (!selectedSubCategory?.value || !duration || !numberOfQuestions) {
      if (!selectedCategory?.value)
        toast.error(
          `${formatMessage({
            id: "parent-section",
          })} ${formatMessage({ id: "missing" })}`,
          {}
        );
      else if (!selectedSubCategory?.value)
        toast.error(
          `${formatMessage({
            id: "secondary-section",
          })} ${formatMessage({ id: "missing" })}`,
          {}
        );
      else if (!numberOfQuestions)
        toast.error(
          `${formatMessage({
            id: "question-num",
          })} ${formatMessage({ id: "missing" })}`,
          {}
        );
      else if (!duration)
        toast.error(
          `${formatMessage({
            id: "duration",
          })} ${formatMessage({ id: "missing" })}`,
          {}
        );

      return;
    } else {
      if (subCatStats && !questionsType)
        toast.error(
          `${formatMessage({
            id: "questionsType",
          })} ${formatMessage({ id: "missing" })}`,
          {}
        );
    }
    dispatch(
      addSession({
        data: {
          category:
            selectedSubCategoryChild?.value || selectedSubCategory?.value,
          numberOfQuestions,
          duration,
          filtertype: questionsType,
        },
        navigate,
      })
    );
  };

  const maxQuestionsNum =
    subCatStats?.[
      questionTypeFilters?.find((e) => e?.value == questionsType)?.key
    ];

    console.log('subCatStats', subCatStats)
    console.log('maxQuestionsNum', maxQuestionsNum)
    console.log('selectedSubCategoryChild', selectedSubCategoryChild)

    
  // const thirdCatClass =
  //   subCategoriesChildren?.length > 0 ? "col-xl-3 col-md-4" : "";

  return (
    <form onSubmit={onSubmit} className="">
      <Col lg={12} xs={12} className='details-wrapper'>
        <Row>
          <Col className='col-12 col-md-6'>
            <div className='form-group '>
              <h5>
                <FormattedMessage id={'parent-section'} />
              </h5>
              <Select
                // {...register("category")}
                placeholder={<FormattedMessage id={'parent-section'} />}
                options={getOptions(
                  mainCategoriesList?.filter((e) => !e?.category)
                )}
                onChange={(selectedOption) => {
                  setSelectedCategory(selectedOption);
                  setSelectedSubCategory(' ');
                  setSubCategoriesChildren([]);
                }}
                isRtl={true}
                isSearchable={false}
                className='basic-single'
                classNamePrefix='select'
              />
            </div>
          </Col>
          <Col className='col-12 col-md-6'>
            <div className='form-group '>
              <h5>
                <FormattedMessage id={'secondary-section'} />
              </h5>
              <Select
                // {...register("subCategory")}
                value={selectedSubCategory}
                placeholder={<FormattedMessage id={'secondary-section'} />}
                options={getOptions(selectedCategory?.subCategories)}
                onChange={handleSelectSubCat}
                isRtl={true}
                isSearchable={false}
                className='basic-single'
                classNamePrefix='select'
              />
            </div>
          </Col>
          {subCategoriesChildren?.length > 0 && (
            <Col className='col-12'>
              <div className='form-group '>
                <h5>
                  <FormattedMessage id='subCategoryChild' />
                </h5>

                <Select
                  isClearable
                  clear
                  // {...register("subCategory")}
                  value={selectedSubCategoryChild}
                  placeholder={<FormattedMessage id='subCategoryChild' />}
                  options={getOptions(subCategoriesChildren)}
                  onChange={(selectedOption) => {

                    if (selectedOption !== null) {
                      setSelectedSubCategoryChild(selectedOption);
                      getCatStats(selectedOption);
                    } else {
                      handleSelectSubCat(selectedSubCategory);
                      setSelectedSubCategoryChild();
                    }
                  }}
                  isRtl={true}
                  isSearchable={false}
                  className='basic-single'
                  classNamePrefix='select'
                />
              </div>
            </Col>
          )}
        </Row>

        {selectedCategory?.value && selectedSubCategory?.value && (
          <>
            <Row className='mx-0 available-questions flex-column gap-0'>
              {/* {subCatStats && (
                <div>
                  <span className='icon'>
                    <Questionmark />
                  </span>
                  <span className='content'>
                    <FormattedMessage id='available-questions' /> :
                    {selectedSubCategoryChild?.questionsCount ||
                      selectedSubCategory?.questionsCount ||
                      '0'}
                  </span>
                </div>
              )} */}
            {!!subCatStats ? (
                <>
                  <span className='content px-0 mx-0 mb-2'>
                    <FormattedMessage id='filterQuestions' /> :
                  </span>

                  {questionTypeFilters?.map((item, index) => (
                    <Col className='px-0' xs='12' key={index}>
                      <label className='form-group d-flex gap-2 align-items-center cursor-pointer mb-0'>
                        <Input
                          className='position-relative'
                          name='questionsType'
                          type='radio'
                          required={false}
                          checked={questionsType == item?.value}
                          // register={register}
                          // errors={errors}
                          onChange={(e) => setQuestionsType(item?.value)}
                        />
                        <h5>
                          <FormattedMessage id={item?.value + 'Questions'} /> (
                          {subCatStats?.[item?.key] || 0})
                        </h5>
                      </label>
                    </Col>
                  ))}
                </>
              ) : (
                <div>
                  <span className='icon'>
                    <Questionmark />
                  </span>
                  <span className='content'>
                    <FormattedMessage id='available-questions' /> :
                    {selectedSubCategoryChild?.questionsCount ||
                      selectedSubCategory?.questionsCount ||
                      '0'}
                  </span>
                </div>
              )} 
            </Row>
          </>
        )}

        <Row>
          <Col className='col-12 col-md-6'>
            <Input
              langId={'question-num'}
              name='question-num'
              type='number'
              // register={register}
              // errors={errors}
              onChange={(e) => setQuestionNum(Number(e.target.value))}
              min='0'
              max={
                selectedSubCategoryChild?.questionsCount || maxQuestionsNum >= 0
                  ? maxQuestionsNum
                  : selectedSubCategory?.questionsCount
              }
              placeholder={formatMessage({ id: 'question-num' })}
            />
          </Col>
          <Col className='col-12 col-md-6'>
            <div className='form-group '>
              <h5>
                <FormattedMessage id='duration' />{' '}
                <small>
                  (<FormattedMessage id='minutes' />)
                </small>
              </h5>

              <Select
                // {...register("duration")}
                placeholder={<FormattedMessage id={'duration'} />}
                options={durations}
                isRtl={true}
                isSearchable={false}
                onChange={(e) => setSelectedDuration(e.value)}
                className='basic-single'
                classNamePrefix='select'
              />
            </div>
          </Col>
        </Row>

        {/* <Row>
          <Col className="col-12 col-md-6">
            <label className="form-group d-flex gap-2 align-items-center">
              <Input
                // {...register("question-num")}
                className="position-relative"
                // langId="newQuestionsOnly"
                name="newQuestionsOnly"
                type="checkbox"
                required={false}
                register={register}
                errors={errors}
                onChange={(e) => setNewQuestionsOnly(e?.target?.checked)}
              />
              <h5>
                <FormattedMessage id="newQuestionsOnly" />
              </h5>
            </label>
          </Col>
        </Row> */}

        <Row>
          <button>
            <FormattedMessage id={'begin-exam'} />
          </button>
        </Row>
      </Col>
    </form>
  );
};
export default Form;
