import {
  GET_ALL_CATEGORIES,
  GET_ALL_CATEGORIES_FAILURE,
  GET_ALL_CATEGORIES_SUCCESS,
} from "./actionTypes";

const initialState = {
  categories: [],
  loading: false,
  error: "",
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    // get all sessions
    case GET_ALL_CATEGORIES:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_CATEGORIES_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        categories: action.payload.categories,
      };
      break;

    case GET_ALL_CATEGORIES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
