import FsLightbox from "fslightbox-react";
import React, { useState } from "react";
// import Shimmer from './Shimmer'

const LightBox = ({ imagesSrcs }) => {
  const [toggler, setToggler] = useState(false);

  // const handleQuestionImageClick = () => {
  //   setToggler((prevToggler) => {
  //     return !prevToggler;
  //   });
  // };

  return (
    <>
      {imagesSrcs ? (
        <>
          {/* <div className="questions_images">
            {imagesSrcs?.map((imgsrc, index) => {
              return (
                <div key={`image_${index}`} className="col__">
                  <Shimmer
                    src={imgsrc}
                    alt="question image"
                    width={200}
                    height={200}
                    onClick={handleQuestionImageClick}
                    layout="fill"
                    objectFit="cover"
                  />
                </div>
              );
            })}
          </div> */}
          <FsLightbox toggler={toggler} sources={imagesSrcs} />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default LightBox;
