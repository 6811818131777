import { Col, Modal, Row } from "react-bootstrap";
import { useLayoutEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import ExInput from "./ExamInput";
import { QuestionTitleImgsNodes } from "./QuestionTitleImages";
import Comments from "./ExamComments";

export default function ResultQuestionComponent({
  questionsIndex,
  question,
  page = "",
}) {
  const [answerKey, setAnswerKey] = useState(0);
  const [explainQuestion, setExplainQuestion] = useState(false);
  const [questionTitleImages, setQuestionTitleImages] = useState([]);
  const [testAnswer, setTestAnswer] = useState(false);

  useLayoutEffect(() => {
    let parsed = new window.DOMParser().parseFromString(
      question?.title,
      "text/html"
    );
    let imgsSrcArr = [...parsed.querySelectorAll("img")].map((e) => e?.src);
    setQuestionTitleImages(imgsSrcArr?.map((e) => e));
  }, [question?.title]);

  const renderAnswers = () =>
    question?.answers?.map((a, index) => (
      <Col className={`col-12 col-md-6`} key={index}>
        <label
          className={`form-control ${
            a?.isCorrect &&
            (question?.isSkipped == undefined ||
              question?.choisenAnswerIndex?.length == 0) &&
            question?.isCorrect != undefined &&
            "correct"
          } ${
            !a?.isCorrect &&
            !question.isCorrect &&
            question?.choisenAnswerIndex?.includes(a?.index) &&
            "wrong"
          } ${
            // a?.isCorrect &&
            !question.isCorrect &&
            question?.choisenAnswerIndex?.includes(a?.index) &&
            "active"
          }`}
          dir="auto"
        >
          <ExInput
            question={question}
            answer={""}
            questionsIndex={questionsIndex}
            value={a?.index}
            index={answerKey}
            readOnly={true}
          />

          <p title={a?.answer}>{a?.answer}</p>
        </label>
      </Col>
    )) || [];
  if (!question?.title)
    return (
      <span className="d-block my-2 text-center position-relative rounded-1 w-100 p-3 bg-warning bg-opacity-75 border-warning text-black bahij-bold">
        {questionsIndex + 1}) <FormattedMessage id="emptyQuestion" />
      </span>
    );

  return (
    <>
      <Col xs="12" xxl="6">
        <div className="result-question">
          <span className="icon">{questionsIndex + 1}</span>
          <Row>
            <Col>
              <p
                className="question-title"
                dir="auto"
                dangerouslySetInnerHTML={{
                  __html: question?.title,
                }}
              ></p>
              <div className="question-title-imgs">
                <QuestionTitleImgsNodes html={question?.title || ""} />
              </div>
            </Col>
          </Row>
          <Row className="answers mb-4" dir="auto">
            <Col className="align-self-center">
              <Row>{renderAnswers()}</Row>
            </Col>
          </Row>
          <Row>
            {page === "session-view" && (
              <a
                onClick={() => {
                  setTestAnswer(!testAnswer);
                }}
                className={`btn btn-blue rounded-pill w-auto m-auto ${
                  explainQuestion && "active"
                }`}
              >
                <FormattedMessage id="show-comments" />
              </a>
            )}
            <a
              onClick={() => setExplainQuestion(!explainQuestion)}
              className={`btn btn-blue rounded-pill w-auto m-auto ${
                explainQuestion && "active"
              }`}
            >
              <FormattedMessage id="explain-question" />
            </a>
          </Row>
        </div>
      </Col>

      <Modal
        show={explainQuestion}
        onHide={() => setExplainQuestion(!explainQuestion)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="explanation" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p
            dir="auto"
            className="question-title"
            dangerouslySetInnerHTML={{
              __html: question?.explanation,
            }}
          ></p>
          <div className="question-title-imgs">
            <QuestionTitleImgsNodes html={question?.explanation || ""} />
          </div>
        </Modal.Body>
      </Modal>

      {page === "session-view" && (
        <Modal show={testAnswer} onHide={() => setTestAnswer(!testAnswer)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Row className="w-100 m-auto">
              <Col>
                <Comments question={question} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
