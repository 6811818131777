import {
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  GET_ALL_UNIVERSITIES,
  GET_ALL_UNIVERSITIES_FAILURE,
  GET_ALL_UNIVERSITIES_SUCCESS,
  GET_ALL_CITIES,
  GET_ALL_CITIES_FAILURE,
  GET_ALL_CITIES_SUCCESS,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  READ_NOTIFICATION,
  READ_NOTIFICATION_SUCCESS,
  READ_NOTIFICATION_FAILURE,
} from "./actionTypes";

// edit user
export const editUser = (payload) => {
  return {
    type: EDIT_USER,
    payload: payload,
  };
};

export const editUserSuccess = (payload) => {
  return {
    type: EDIT_USER_SUCCESS,
    payload: payload,
  };
};

export const editUserFailure = (error) => {
  return {
    type: EDIT_USER_FAILURE,
    payload: error,
  };
};

// get universities for user profile edit

export const getAllUniversities = (payload) => {
  return {
    type: GET_ALL_UNIVERSITIES,
    payload: payload,
  };
};
export const getAllUniversitiesSuccess = (payload) => {
  return {
    type: GET_ALL_UNIVERSITIES_SUCCESS,
    payload: payload,
  };
};

export const getAllUniversitiesFailure = (error) => {
  return {
    type: GET_ALL_UNIVERSITIES_FAILURE,
    payload: error,
  };
};
// get cities for user profile edit

export const getAllCities = (payload) => {
  return {
    type: GET_ALL_CITIES,
    payload: payload,
  };
};
export const getAllCitiesSuccess = (payload) => {
  return {
    type: GET_ALL_CITIES_SUCCESS,
    payload: payload,
  };
};

export const getAllCitiesFailure = (error) => {
  return {
    type: GET_ALL_CITIES_FAILURE,
    payload: error,
  };
};

// get notifications for user

export const getNotifications = (payload) => {
  return {
    type: GET_NOTIFICATIONS,
    payload: payload,
  };
};
export const getNotificationsSuccess = (payload) => {
  return {
    type: GET_NOTIFICATIONS_SUCCESS,
    payload: payload,
  };
};

export const getNotificationsFailure = (error) => {
  return {
    type: GET_NOTIFICATIONS_FAILURE,
    payload: error,
  };
};

// read notifications for user

export const readNotification = (payload) => {
  return {
    type: READ_NOTIFICATION,
    payload: payload,
  };
};
export const readNotificationSuccess = (payload) => {
  return {
    type: READ_NOTIFICATION_SUCCESS,
    payload: payload,
  };
};

export const readNotificationFailure = (error) => {
  return {
    type: READ_NOTIFICATION_FAILURE,
    payload: error,
  };
};
