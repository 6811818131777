import server from './server';

export const getSettingsInfo = async (data) => {
  const response = await server().get('/settings', data);
  return (
    response.data?.data?.settings
      .filter((e) => e._id == 'main')?.[0]
      .items?.filter((e) => e.title == 'appLogo')?.[0]?.value || ''
  );
};

export const checkUserVerify = async (data) => {
  try {
    const response = await server().post(
      '/auth/userDashboard/checkUserVerify',
      data
    );
    return response.data;
  } catch (error) {
    // Throw the error to be caught by the calling function
    throw error;
  }
};

export const postUserLogin = async (data) => {
  const response = await server().post('/auth/userDashboard/login', data);
  return response.data;
};

export const resetPasswordApi = async (data) => {
  const response = await server().post('/auth/resendPassword', data);

  return response.data;
};

export const updatePasswordApi = async (data) => {
  const response = await server().post('/auth/updatePassword', data);

  return response.data;
};

export const getCurrentUser = async (data) => {
  const response = await server().get('/auth/myProfile', data);

  return response?.data;
};

export const getCurrentUserStatistics = async (data) => {
  const response = await server().get('/auth/userDashboardStatistics', data);

  return response.data;
};

export const editUserApi = async (data) => {
  const response = await server().put(`/auth/editProfile`, data);

  return response.data;
};

export const getAllUniversitiesApi = async () => {
  const response = await server().get('/universities');

  return response.data;
};

export const getAllCitiesApi = async () => {
  const response = await server().get('/cities');

  return response.data;
};

export const getNotificationsApi = async () => {
  const response = await server().get('/auth/notifications');

  return response.data;
};

export const readNotificationApi = async (id) => {
  const response = await server().put(`/auth/notifications/${id}`);

  return response.data;
};
