import { toastErrorMessage } from "components/shared/toastErrorMessage";
import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  CURRENT_USER,
  CURRENT_USER_SUCCESS,
  CURRENT_USER_FAILURE,
  CURRENT_USER_STATISTICS,
  CURRENT_USER_STATISTICS_SUCCESS,
  CURRENT_USER_STATISTICS_FAILURE,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  SAVE_EMAIL,
  LOGIN_FAILURE,
  DISMISS_ANNOUNCEMENT,
  DISMISS_ANNOUNCEMENT_FAILURE,
  DISMISS_ANNOUNCEMENT_SUCCESS,
} from "./actionTypes";
import Cookies from "js-cookie";

const initialState = {
  user: {},
  userStats: {},
  isLoggedIn: null,
  // isLoggedIn: true, //auto auth in if test mode offline.
  loading: false,
  error: "",
  currentUserLoading: false,
};

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EDIT_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        user: action.payload?.user,
        isLoggedIn: true,
      };
      break;
    case EDIT_USER_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        user: action.payload,
        isLoggedIn: true,
      };
      break;
    case LOGIN_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        user: {},
        isLoggedIn: false,
      };
      break;
    //----------------
    case DISMISS_ANNOUNCEMENT:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case DISMISS_ANNOUNCEMENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        user: action.payload?.user,
      };
      break;
    case DISMISS_ANNOUNCEMENT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: "",
      };
      break;
    //----------------

    case LOGOUT_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGOUT_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: {},
        userStats: {},
        isLoggedIn: false,
      };
      break;
    case CURRENT_USER:
      state = {
        ...state,
        currentUserLoading: true,
      };
      break;
    case CURRENT_USER_SUCCESS:
      state = {
        ...state,
        currentUserLoading: false,
        user:
          // {
          //   ...
          action.payload?.user,
        //   currentSubscription: {
        //     ...action.payload?.user?.currentSubscription,
        //     status: "expired",
        //   },
        // },
        logo: action.payload.logo,
        isLoggedIn: true,
      };
      break;

    case CURRENT_USER_FAILURE:
      state = {
        ...state,
        currentUserLoading: false,
        user: {},
        loading: false,
        // isLoggedIn: false,
      };
      break;

    case CURRENT_USER_STATISTICS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CURRENT_USER_STATISTICS_SUCCESS:
      state = {
        ...state,
        loading: false,
        userStats: action.payload.user,
      };
      break;

    case CURRENT_USER_STATISTICS_FAILURE:
      state = {
        ...state,
        loading: false,
        userStats: {},
      };
      break;

    case API_ERROR:
      const error = { ...(action.payload || {}) };
      let statusCode = error?.response?.status || error?.status,
        errorMessage = error?.response?.data?.errors?.[0]?.msg;

      switch (statusCode) {
        case 400:
          // Handle specific status code 400 (Bad Request) here
          break;
        case 401:
        case 403:
          if (
            Cookies?.get("dash-token") &&
            { ...error }?.response?.status === 403
          ) {
            Cookies.set("dash-token", "", {
              // domain: ".examist.sa",
              domain: process.env.REACT_APP_COOKIES_DOMAIN,
              expires: 365,
            });
            Cookies.remove("dash-token");
            errorMessage = "token-expired";

            // setTimeout(() => window?.location?.reload(), 2000);
          }
          state.isLoggedIn = false;
          // localStorage.removeItem("token");
          // window.location.href = "/login";

          // Handle specific status code 401 (Unauthorized) here
          break;

        case 404:
          // Handle specific status code 404 (Not Found) here
          break;
        // Add more cases for other status codes as needed

        default:
          errorMessage = "Request failed due to a network or server error";
          if (!window?.location?.href?.includes("server") && statusCode == 500)
            window.location.href = "/server-down";
          state.isLoggedIn = false;

        // Handle other status codes here
      }
      toastErrorMessage(errorMessage);

      state = { ...state, error: action.payload, loading: false };
      break;
    case SAVE_EMAIL:
      state = { ...state, email: action.payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default authentication;
