import { useState } from "react";
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getAllArchivedSessions } from "store/actions";
import Icon from "assets/svgs/sessions.svg";
import Table from "./Table";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import { Col, Row } from "react-bootstrap";
import Loader from "components/shared/Loader";

const Archived = () => {
  const { archivedSessions, archivedLoading, error, loading } = useSelector(
    (state) => state.sessions
  );
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  const [isDispatched, setIsDispatched] = useState(false);
  useEffect(() => {
    setIsDispatched(true);
    dispatch(getAllArchivedSessions());
  }, [dispatch]);

  // error
  useEffect(() => {
    if (error && isDispatched) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error, formatMessage, locale, isDispatched]);
  if (loading) return <Loader />;

  return (
    <>
      <div className="control-wrp">
        <Row>
          <Col>
            <div className="card">
              <h5>
                <img src={Icon} width={20} height={20} alt="" />
                <FormattedMessage id={"sessions"} />
                {" / "}
                <FormattedMessage id={"archived"} />
              </h5>
            </div>
          </Col>
        </Row>
      </div>

      <div className="sessions-wrap">
        <Table
          data={archivedSessions}
          loading={archivedLoading}
          title="sessionsTableShowArchived"
        />
      </div>
    </>
  );
};

export default Archived;
