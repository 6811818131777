import { combineReducers } from "redux";

// Authentication
import authentication from "./authentication/reducer";
import subscriptions from "./userSubscriptions/reducer";
import sessions from "./sessions/reducer";
import user from "./users/reducer";
import categories from "./categories/reducer";
import settings from "./settings/reducer";
import payment from "./payment/reducer";
// import announcement from "./announcement/reducer";
const rootReducer = combineReducers({
  authentication,
  subscriptions,
  sessions,
  user,
  categories,
  settings,
  payment,
  // announcement,
});

export default rootReducer;
