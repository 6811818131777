import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

function Timer({ submit }) {
  const { activeSession } = useSelector((state) => state.sessions);

  const paddingZero = (v) => String(v).padStart(2, "0");

  const [time, setTime] = useState(0);
  const [update, setUpdate] = useState(0);
  const [durationInSeconds, setDurationInSeconds] = useState(0);
  const [startTimeInSeconds, setStartTimeInSeconds] = useState(0);
  const [difference, setDifference] = useState(1);

  useEffect(() => {
    setDurationInSeconds(activeSession?.duration * 60 || 0);
    setStartTimeInSeconds(new Date(activeSession?.startAt || "").getTime());

    let interval = setInterval(() => {
      // console.count("timer");
      setUpdate(Date.now());
    }, 1000);
    return () => clearInterval(interval);
  }, [activeSession]);

  useEffect(() => {
    let currentTime = new Date().getTime(),
      timeElapced = (currentTime - startTimeInSeconds) / 1000,
      differenceTmp = durationInSeconds - timeElapced;

    setDifference(differenceTmp);

    if (differenceTmp == NaN || differenceTmp <= 0) {
      setTime("00:00:00");
    } else {
      setTime(toHHMMSS(differenceTmp));
    }

    if (timeElapced >= durationInSeconds - 2 && durationInSeconds != 0) {
      submit();
    }
  }, [update]);

  return activeSession?.duration != "Unlimited" ? (
    time
  ) : (
    <FormattedMessage id="Unlimited" />
  );
}

const toHHMMSS = (s) => {
  var sec_num = parseInt(s, 10);
  var hours = Math.floor(sec_num / 3600) || 0;
  var minutes = Math.floor((sec_num - hours * 3600) / 60) || 0;
  var seconds = Math.round(sec_num - hours * 3600 - minutes * 60) || 0;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
};

export default Timer;
