import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  EDIT_USER,
  GET_ALL_UNIVERSITIES,
  GET_ALL_CITIES,
  GET_NOTIFICATIONS,
  READ_NOTIFICATION,
} from "./actionTypes";
import {
  editUserApi,
  getAllUniversitiesApi,
  getAllCitiesApi,
  getNotificationsApi,
  readNotificationApi,
} from "api/users";
import {
  editUserFailure,
  editUserSuccess,
  getAllUniversitiesFailure,
  getAllUniversitiesSuccess,
  getAllCitiesFailure,
  getAllCitiesSuccess,
  getNotificationsSuccess,
  getNotificationsFailure,
  readNotificationSuccess,
  readNotificationFailure,
} from "./actions";

function* editUser({ payload }) {
  try {
    const { data } = yield call(editUserApi, payload.data);
    yield put(editUserSuccess(data));
  } catch (error) {
    yield put(editUserFailure(error.response.data || error));
  }
}

function* getAllUniversities() {
  try {
    const { data } = yield call(getAllUniversitiesApi);
    yield put(getAllUniversitiesSuccess(data));
  } catch (error) {
    yield put(getAllUniversitiesFailure(error.response.data || error));
  }
}

function* getAllCities() {
  try {
    const { data } = yield call(getAllCitiesApi);
    yield put(getAllCitiesSuccess(data));
  } catch (error) {
    yield put(getAllCitiesFailure(error.response.data || error));
  }
}

function* getNotifs() {
  try {
    const { data } = yield call(getNotificationsApi);
    yield put(getNotificationsSuccess(data));
  } catch (error) {
    yield put(getNotificationsFailure(error.response.data || error));
  }
}

function* readNotification({ payload }) {
  try {
    const { data } = yield call(readNotificationApi, payload);
    yield put(readNotificationSuccess(data));
  } catch (error) {
    yield put(readNotificationFailure(error.response.data || error));
  }
}

export function* watchEditUser() {
  yield takeEvery(EDIT_USER, editUser);
}

export function* watchGetAllUniversities() {
  yield takeEvery(GET_ALL_UNIVERSITIES, getAllUniversities);
}
export function* watchGetAllCities() {
  yield takeEvery(GET_ALL_CITIES, getAllCities);
}

export function* watchGetNotifs() {
  yield takeEvery(GET_NOTIFICATIONS, getNotifs);
}

export function* watchReadNotification() {
  yield takeEvery(READ_NOTIFICATION, readNotification);
}

function* usersSaga() {
  yield all([
    fork(watchEditUser),
    fork(watchGetAllUniversities),
    fork(watchGetAllCities),
    fork(watchGetNotifs),
    fork(watchReadNotification),
  ]);
}

export default usersSaga;
