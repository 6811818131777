import React, { useState, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom"; // Import useLocation
import Arabic from "./lang/ar.json";
import English from "./lang/en.json";
import Cookies from "js-cookie";

const Wrapper = (props) => {
  const [locale, setLocale] = useState({
    lang: "ar",
    messages: Arabic,
  });

  const location = useLocation();
  const navigate = useNavigate();
  const detectLangFromURL = () => {
    const pathLang = location.pathname.split('/')[1];
    return pathLang === 'en' ? 'en' : 'ar';
  };
  const handleLang = () => {
    const langFromURL = detectLangFromURL();

    setLocale({
      lang: langFromURL,
      messages: langFromURL === "ar" ? Arabic : English,
    });

    document.body.setAttribute("dir", langFromURL === "ar" ? "rtl" : "ltr");
    document.documentElement.lang = langFromURL;
    document.querySelector("title").textContent = langFromURL === "ar" ? "لوحة التحكم" : "Dashboard";

    Cookies.set("site-lang", langFromURL);
  };


  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const isPaymentPage = pathSegments.includes("payment") ||
      pathSegments.includes("commented-on") ||
      pathSegments.includes("saved-questions") ||
      pathSegments.includes("subscriptions");
    const hasLang = pathSegments[1] === 'ar' || pathSegments[1] === 'en';

    // If it's a payment page but no language in URL, redirect with default language
    if (isPaymentPage && !hasLang) {
      const savedLang = Cookies.get("site-lang") || "ar"; // Use saved language or default to "ar"
      const newPath = `/${savedLang}${location.pathname}`;
      navigate(newPath, { replace: true });
    } else if (!location.pathname.includes("/login")) {
      handleLang();
    }
  }, [location.pathname]);

  useEffect(() => {
    handleLang();
  }, []);





  return (
    <Context.Provider value={{ locale, setLocale }}>
      <IntlProvider messages={locale.messages} locale={locale.lang} onError={() => { }}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export const Context = React.createContext();
export default Wrapper;
