import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import { Table } from "react-bootstrap";
import { ReactComponent as Correct } from "assets/svgs/correct.svg";
import { ReactComponent as InCorrect } from "assets/svgs/inCorrect.svg";

const View = () => {
  const { formatMessage, locale } = useIntl();
  const navigate = useNavigate();

  const { loading, error, singleQuestion } = useSelector(
    (state) => state.sessions
  );

  useEffect(() => {
    if (singleQuestion == undefined || Object.keys(singleQuestion)?.length == 0)
      navigate("/sessions");
  }, [singleQuestion]);

  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
      navigate("/sessions");
    }
  }, [error, formatMessage, locale, navigate]);

  const renderAnswers =
    singleQuestion?.answers?.length > 0 &&
    singleQuestion?.answers.map((answer, k) => {
      return (
        <tr key={answer?._id}>
          <td>{answer?.index}</td>
          <td>{answer?.answer}</td>

          <td>
            {answer?.isCorrect != undefined ? (
              answer.isCorrect ? (
                <span className="badge rounded-pill bg-success">
                  <Correct fill="white" width="24" />
                </span>
              ) : (
                <span className="badge rounded-pill bg-danger">
                  <InCorrect fill="white" width="24" />
                </span>
              )
            ) : (
              <span className="badge rounded-pill bg-secondary">
                <FormattedMessage id="notYet" />
              </span>
            )}
          </td>
        </tr>
      );
    });

  if (loading) return <Loader />;

  return (
    <>
      <div className="view-page">
        <div className="card questions">
          <div className="card-head">
            <h4>
              <FormattedMessage id={"details"} />{" "}
              <FormattedMessage id={"question"} />
            </h4>
          </div>
          <div className="card-body">
            <Col xl={8} md={12}>
              <Row>
                <Col lg={12} xs={12}>
                  <Table className="table-bordered">
                    <tbody>
                      <tr>
                        <th>
                          <FormattedMessage id="title" />
                        </th>
                        <td>
                          <span
                            dir="auto"
                            dangerouslySetInnerHTML={{
                              __html: singleQuestion?.title,
                            }}
                          ></span>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <FormattedMessage id="type" />
                        </th>
                        <td>
                          <FormattedMessage
                            id={singleQuestion?.type || "notFound"}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <FormattedMessage id="explanation" />
                        </th>
                        <td>
                          <span
                            dir="auto"
                            dangerouslySetInnerHTML={{
                              __html: singleQuestion?.explanation,
                            }}
                          ></span>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <FormattedMessage id="chosen-answer" />
                        </th>
                        <td>
                          <span>
                            {singleQuestion?.isSkipped ? (
                              <FormattedMessage id="notFound" />
                            ) : (
                              singleQuestion?.choisenAnswerIndex?.join(", ")
                            )}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Col>
          </div>
        </div>
        <div className="card questions">
          <div className="card-head">
            <h4>
              <FormattedMessage id={"details"} />{" "}
              <FormattedMessage id={"answers"} />
            </h4>
          </div>
          <Col>
            <Row>
              <Table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>
                      <FormattedMessage id="answer" />
                    </th>
                    <th>
                      <FormattedMessage id="isCorrect" />
                    </th>
                  </tr>
                </thead>
                <tbody>{renderAnswers}</tbody>
              </Table>
            </Row>
          </Col>
        </div>
      </div>
    </>
  );
};

export default View;
