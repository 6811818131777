import React, { useMemo, useState, forwardRef } from "react";
import DataTable from "react-data-table-component";
import { Col, Dropdown } from "react-bootstrap";
import Filter from "components/shared/Filter";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Loader from "components/shared/Loader";
import { FormattedMessage, useIntl } from "react-intl";
import { deleteSession, archiveSession } from "store/actions";
import {
  getId,
  handleIsoDate,
  handleSearchParamsChange,
} from "helpers/functions";
import { ReactComponent as Arrow } from "assets/svgs/arrow.svg";
import BootyPagination from "components/shared/Pagination";

const Table = (props) => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [selectedRows, setSelectedRows] = useState([]);
  const BootyCheckbox = new forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props?.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const customWait = async (dur) =>
    await new Promise((res) => setTimeout(() => res(), dur));

  const handleArchive = (ids) => {
    ids?.map(async (id) => {
      dispatch(archiveSession(id));
      await customWait(75);
    });
    setSelectedRows(selectedRows?.filter((r) => !ids?.includes(r)));
  };

  const handleShowSub = (ids) => {
    navigate(`/sessions/${ids?.[0]}`);
  };
  const handleDelete = (ids) => {
    ids?.map((id) => dispatch(deleteSession(id)));
    setSelectedRows(selectedRows?.filter((r) => !ids?.includes(r)));
  };

  const columns = [
    {
      name: <FormattedMessage id={"category"} />,
      selector: (row) => {
        const content = `${row?.category?.name?.[locale] || "--"} ${
          row?.category?.category?.name?.[locale] || ""
        }`;
        return <Link to={`/sessions/${getId(row)}`}>{content}</Link>;
      },
      sortable: true,
    },
    {
      name: <FormattedMessage id={"numberOfQuestions"} />,
      selector: (row) => {
        const questionMessage =
          row?.numberOfQuestions > 2 ? "sumQuestions" : "singleQuestion";
        return `${row?.numberOfQuestions} ${formatMessage({
          id: questionMessage,
        })}`;
      },
      sortable: true,
    },
    {
      name: <FormattedMessage id="correctAnswers" />,
      selector: (row) => row?.correctAnswers,
      sortable: true,
    },
    {
      name: <FormattedMessage id={"updatedAtDate"} />,
      selector: (row) => {
        const updatedDate = row?.startAt
          ? // ? new Date(row?.startAt).toLocaleString(locale)
            handleIsoDate(row?.startAt)
          : formatMessage({
              id: "notFound",
            });
        return updatedDate;
      },
      sortable: true,
    },
    {
      cell: (row) => <ActionsDropMenu ids={[row?._id || row?.id]} />,
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  const ActionsDropMenu = ({ ids, title = false }) => (
    <div className="global-dropdown">
      <Dropdown>
        <Dropdown.Toggle>
          {title ? (
            <div className="fast-action">
              {title} <Arrow />
            </div>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-three-dots-vertical"
              viewBox="0 0 16 16"
            >
              <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
            </svg>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={(e) => handleShowSub(ids)}
            // to={`/subscriptions/${row?.id}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#fff"
              className="bi bi-eye-fill show"
              viewBox="0 0 16 16"
            >
              <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
              <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
            </svg>
            <FormattedMessage id={"view"} />
          </Dropdown.Item>
          {props.title != "sessionsTableShowArchived" && (
            <Dropdown.Item onClick={() => handleArchive(ids)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-archive"
                viewBox="0 0 16 16"
              >
                <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
              </svg>
              <FormattedMessage id={"archive"} />
            </Dropdown.Item>
          )}

          {
            <Dropdown.Item onClick={() => handleDelete(ids)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="#fff"
                className="bi bi-trash delete"
                viewBox="0 0 16 16"
              >
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                <path
                  fillRule="evenodd"
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                />
              </svg>
              <FormattedMessage id={"delete"} />
            </Dropdown.Item>
          }
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );

  // const onChangePage = (clickedPage, limit) => {
  //   const search = Object.fromEntries([...searchParams]);

  //   const params = {};
  //   if (clickedPage) params["page"] = clickedPage;
  //   if (limit) params["limit"] = limit;

  //   const newSearch = handleSearchParamsChange(search, params);

  //   navigate(window.location.pathname + newSearch, { replace: true });
  // };

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col>
            <div className="card-head w-100">
              <div>
                <h4>
                  <FormattedMessage id={"sessionsTableShow"} />{" "}
                </h4>
                <p>
                  <FormattedMessage id={props?.title || "notFound"} />
                </p>
              </div>
            </div>
            {selectedRows?.length > 0 && (
              <ActionsDropMenu
                ids={selectedRows}
                title={formatMessage({ id: "take-action" })}
              />
            )}
          </Col>
          <div className="card-body">
            <DataTable
              // pagination
              // paginationComponentOptions={{
              //   selectAllRowsItem: true,
              //   selectAllRowsItemText: "ALL",
              // }}
              className="DataTableSub"
              columns={columns}
              data={filteredItems}
              progressPending={props.loading}
              progressComponent={<Loader />}
              defaultSortField="name"
              selectableRows
              subHeader
              subHeaderComponent={subHeaderComponent}
              selectableRowsComponent={BootyCheckbox}
              onSelectedRowsChange={(state) => {
                setSelectedRows(
                  state.selectedRows?.map((r) => r?._id || r?.id)
                );
              }}
              // onChangePage={onChangePage}
            />
            <BootyPagination metaData={props?.metadata} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
