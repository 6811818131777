import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import media from "../../assets/images/171274892.png"

const CheckToDisableComponent = ({ children }) => {
  const {
    user: { currentSubscription },
  } = useSelector((state) => state.authentication);


  const { locale } = useIntl();

  const link = locale === 'ar' 
    ? process.env.REACT_APP_EXAMIST 
    : process.env.REACT_APP_EXAMISTEN;

  return (
    <>
      {currentSubscription?.status != 'active' ? (
        <>

          <div className='disabled-component'>{children}</div>
          <div className='  '>
            <div className="begin">
              <div className="image">
                <img src={media} alt="" />
              </div>
              <div className="info">
                <h6>  <FormattedMessage id='Letsbegin' />  </h6>
                <h2>    {currentSubscription != undefined ? (
                  <FormattedMessage id='subscription-not-active' />
                ) : (
                  <FormattedMessage id='subscription-not-found' />
                )} </h2>
                <a
                 href={`${link}subscriptions`}
                  className='w-100 text-center    m-b-0 mt-5 new-session-anchor'
                >
                  <FormattedMessage id='pick-subscription' />
                </a>
              </div>
            </div>


          </div>
        </>
      ) : (
        children
      )}
    </>
  );
};

export default CheckToDisableComponent;
