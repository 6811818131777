import images from "react-payment-inputs/images";
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import CouponDiscount from "./CouponDiscount";
import BtnPayment from "./BtnPayment";
export default function VisaPayment({ setCouponCode }) {
  const {
    meta,
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();

  return (
    <div className="card-body payment-method">
      <div className="card">
        <Row className="w-100">
          <Col xs="12">
            <div>
              <FormattedMessage id="card-name" />
            </div>
            <label>
              <input type="text" name="name" required={true} />
            </label>
          </Col>
        </Row>
        <Row className="w-100">
          <Col xs="12">
            <div>
              <FormattedMessage id="card-num" />
            </div>
            <label>
              <input {...getCardNumberProps()} name="number" required={true} />
              <svg {...getCardImageProps({ images })} />
            </label>
          </Col>
        </Row>
        <Row className="w-100 mt-2">
          <Col className="col-12 col-md-6">
            <div>
              <FormattedMessage id="expiration-date" />
            </div>
            <label>
              <input {...getExpiryDateProps()} name="date" required={true} />
            </label>
          </Col>
          <Col className="col-12 col-md-6">
            <div>
              <FormattedMessage id={`card`} />
              {meta?.cardType?.code?.name || "CVV"}
            </div>
            <label>
              <input
                {...getCVCProps()}
                type="password"
                name="cvc"
                required={true}
              />
            </label>
          </Col>
          <CouponDiscount />

          {/* <BtnPayment /> */}
        </Row>
      </div>
    </div>
  );
}
