import React from "react";

import { Col, Row } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FormattedMessage } from "react-intl";
import Chart from "./Chart";
import { FaCheck } from "react-icons/fa6";
import { BsFillSkipForwardFill } from "react-icons/bs";
import { IoClose } from "react-icons/io5";

function ResultChart({ userSession }) {
  const getAnswersPercentage = () => {
    const questionsCount = userSession?.questions?.length;
    let rightAnswers = userSession?.correctAnswers;
    let wrongAnswers = userSession?.inCorrectAnswers;
    let passedQuesions =
      userSession?.numberOfQuestions -
      (userSession?.correctAnswers + userSession?.inCorrectAnswers) || "0";

    const calculatePercentage = (count) =>
      Math.round((count / questionsCount) * 100);

    const percentageRight = calculatePercentage(rightAnswers);
    const percentageWrong = calculatePercentage(wrongAnswers);
    const percentagePassed = calculatePercentage(passedQuesions);

    return {
      percentageRight,
      percentageWrong,
      percentagePassed,
    };
  };

  return (
    <div className="pt-5 pt-lg-4" data-aos="fade-in" data-aos-duration="1500">
      <Row className="  ">
        <Col sm={12} md={12} lg={9}>
          <Row className="answers_progress details h-100 align-items-center">
            <Col
              lg={3}
              className="answer_progress      right-answer  "
            >
              <div className="right_answers d-none d-lg-flex icons_circle">
                <div className="image">
                <FaCheck />
                </div>
                <FormattedMessage id="rightAnswers" />
              </div>
              <div className="skip_answers d-none d-lg-flex icons_circle">
                <div className="image">
                <BsFillSkipForwardFill />

                </div>
                <FormattedMessage id="passedQuesions" />
              </div>
              <div className="wrong_answers d-none d-lg-flex icons_circle">
                <div className="image">
                <IoClose />
                </div>
                <FormattedMessage id="wrongAnswers" />
              </div>
            </Col>
            <Col lg={9} className="answer_progress gap-3 d-flex flex-column ">
              <div className="right_answers d-lg-none  mx-auto">
                <FormattedMessage id="rightAnswers" />
              </div>
              <div className="perce right_answers d-flex justify-content-center align-items-center   gap-2">
                <ProgressBar now={getAnswersPercentage().percentageRight} />
                <div style={{ width: "50px" }} className="text_  ms-2 ">
                  {getAnswersPercentage().percentageRight}%
                </div>
                {/* <span className="box_ me-2"></span> */}
              </div>
              <div className="skip_answers d-lg-none  mx-auto">
                <FormattedMessage id="passedQuesions" />
              </div>

              <div className="perce skip_answers d-flex justify-content-center align-items-center   gap-2">
                <ProgressBar now={getAnswersPercentage().percentagePassed} />
                <div style={{ width: "50px" }} className="text_  ms-2 ">
                  {getAnswersPercentage().percentagePassed}%
                </div>
                {/* <span className="box_ me-2"></span> */}
              </div>
              <div className="wrong_answers d-lg-none  mx-auto">
                <FormattedMessage id="wrongAnswers" />
              </div>
              <div className="perce wrong_answers d-flex justify-content-center align-items-center   gap-2">
                <ProgressBar now={getAnswersPercentage().percentageWrong} />
                <div style={{ width: "50px" }} className="text_  ms-2 ">
                  {getAnswersPercentage().percentageWrong}%
                </div>
                {/* <span className="box_ me-2"></span> */}
              </div>
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={12} lg={3} className="mt-5 mt-lg-0">
          <Chart
            userSession={userSession}
            answersPercentage={getAnswersPercentage()}
          />
        </Col>
      </Row>
    </div>
  );
}

export default ResultChart;
