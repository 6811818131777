import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import Loader from "components/shared/Loader";
import { getAllCategories } from "store/actions";
import "styles/sessions.css";
import AddForm from "components/session/AddForm";
import { useNavigate } from "react-router-dom";

const View = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading: catLoading } = useSelector((state) => state.categories);
  const { loading: sessionLoading, activeSession } = useSelector(
    (state) => state.sessions
  );
  

  useEffect(() => {
    if (Object.keys(activeSession).length > 0) navigate("/sessions/exam");
  }, []);

  useEffect(() => {
    dispatch(getAllCategories());
  }, []);

  if (catLoading || sessionLoading) return <Loader />;

  return (
    <>
      <div className="view-page session-add">
        <div className="card add_session_form">
          <div className="card-body ">
            <Col md={12}>
              <Row>
                <AddForm />
              </Row>
            </Col>
          </div>
        </div>
      </div>
    </>
  );
};

export default View;
