import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { GET_ALL_CATEGORIES } from "./actionTypes";
import { getCategoriesApi } from "api/categories";
import { getAllCategoriesSuccess, getAllCategoriesFailure } from "./actions";

function* getAllCategoriesSaga() {
  try {
    const { data } = yield call(getCategoriesApi);

    yield put(getAllCategoriesSuccess(data));
  } catch (error) {
    yield put(getAllCategoriesFailure(error.response.data || error));
  }
}

export function* watchCategories() {
  yield takeEvery(GET_ALL_CATEGORIES, getAllCategoriesSaga);
}

function* categoriesSaga() {
  yield all([fork(watchCategories)]);
}

export default categoriesSaga;
