import {
  ADD_SESSION,
  ADD_SESSION_FAILURE,
  ADD_SESSION_SUCCESS,
  DELETE_SESSION,
  DELETE_SESSION_FAILURE,
  DELETE_SESSION_SUCCESS,
  EDIT_SESSION,
  EDIT_SESSION_FAILURE,
  EDIT_SESSION_SUCCESS,
  GET_ALL_SESSIONS,
  GET_ALL_SESSIONS_FAILURE,
  GET_ALL_SESSIONS_SUCCESS,
  GET_ALL_ARCHIVED_SESSIONS,
  GET_ALL_ARCHIVED_SESSIONS_FAILURE,
  GET_ALL_ARCHIVED_SESSIONS_SUCCESS,
  GET_SESSION,
  GET_SESSION_FAILURE,
  GET_SESSION_SUCCESS,
  ARCHIVE_SESSION,
  ARCHIVE_SESSION_SUCCESS,
  GET_QUESTION,
  GET_QUESTION_SUCCESS,
  GET_QUESTION_FAILURE,
  ANSWER_QUESTION,
  ANSWER_QUESTION_SUCCESS,
  TERMINATE_ACTIVE_SESSION,
  ANSWER_QUESTION_FAILURE,
  ADD_QUESTION_COMMENT,
  ADD_QUESTION_COMMENT_SUCCESS,
  ADD_QUESTION_COMMENT_FAILURE,
  ANSWER_QUESTIONS,
  ANSWER_QUESTIONS_SUCCESS,
  ANSWER_QUESTIONS_FAILURE,
  SAVED_QUESTIONS,
  SAVED_QUESTIONS_SUCCESS,
  SAVED_QUESTIONS_FAILURE,
  GET_COMMENTED_ON_QUESTIONS,
  GET_COMMENTED_ON_QUESTIONS_SUCCESS,
  GET_COMMENTED_ON_QUESTIONS_FAILURE,
  GET_ACTIVE_SESSION,
  ARCHIVE_SESSION_FAILURE,
  GET_TYPE_ON_QUESTIONS,
  GET_TYPE_ON_QUESTIONS_SUCCESS,
  GET_TYPE_ON_QUESTIONS_FAILURE,
} from "./actionTypes";

export const getAllSessions = (payload) => {
  return {
    type: GET_ALL_SESSIONS,
    payload: payload,
  };
};
export const getAllSessionsSuccess = (payload) => {
  return {
    type: GET_ALL_SESSIONS_SUCCESS,
    payload: payload,
  };
};

export const getAllSessionsFailure = (error) => {
  return {
    type: GET_ALL_SESSIONS_FAILURE,
    payload: error,
  };
};
//--------------
export const getAllArchivedSessions = (payload) => {
  return {
    type: GET_ALL_ARCHIVED_SESSIONS,
    payload: payload,
  };
};
export const getAllArchivedSessionsSuccess = (payload) => {
  return {
    type: GET_ALL_ARCHIVED_SESSIONS_SUCCESS,
    payload: payload,
  };
};

export const getAllArchivedSessionsFailure = (error) => {
  return {
    type: GET_ALL_ARCHIVED_SESSIONS_FAILURE,
    payload: error,
  };
};

export const addSession = (payload) => {
  return {
    type: ADD_SESSION,
    payload: payload,
  };
};

export const getActiveSessionSuccess = (payload) => {
  return {
    type: ADD_SESSION_SUCCESS,
    payload: payload,
  };
};

export const getActiveSessionFailure = (error) => {
  return {
    type: ADD_SESSION_FAILURE,
    payload: error,
  };
};

export const archiveSession = (payload) => {
  return {
    type: ARCHIVE_SESSION,
    payload: payload,
  };
};
export const archiveSessionSuccess = (payload) => {
  return {
    type: ARCHIVE_SESSION_SUCCESS,
    payload: payload,
  };
};
export const archiveSessionFailure = (payload) => {
  return {
    type: ARCHIVE_SESSION_FAILURE,
    payload: payload,
  };
};

export const getSession = (payload) => {
  return {
    type: GET_SESSION,
    payload: payload,
  };
};
export const getSessionSuccess = (payload) => {
  return {
    type: GET_SESSION_SUCCESS,
    payload: payload,
  };
};

export const getSessionFailure = (error) => {
  return {
    type: GET_SESSION_FAILURE,
    payload: error,
  };
};

export const getActiveSession = (payload) => {
  return {
    type: GET_ACTIVE_SESSION,
    payload: payload,
  };
};

//question----
export const getQuestion = (payload) => {
  return {
    type: GET_QUESTION,
    payload: payload,
  };
};
export const getQuestionSuccess = (payload) => {
  return {
    type: GET_QUESTION_SUCCESS,
    payload: payload,
  };
};

export const getQuestionFailure = (error) => {
  return {
    type: GET_QUESTION_FAILURE,
    payload: error,
  };
};

//answering question----
export const answerQuestion = (payload) => {
  return {
    type: ANSWER_QUESTION,
    payload: payload,
  };
};
export const answerQuestionSuccess = (payload) => {
  return {
    type: ANSWER_QUESTION_SUCCESS,
    payload: payload,
  };
};

export const answerQuestionFailure = (error) => {
  return {
    type: TERMINATE_ACTIVE_SESSION,
    type: ANSWER_QUESTION_FAILURE,
    payload: error,
  };
};
export const terminateActiveSession = (error) => {
  return {
    type: TERMINATE_ACTIVE_SESSION,
    payload: error,
  };
};

//adding a question comment in exam
export const addQuestionComment = (payload) => {
  return {
    type: ADD_QUESTION_COMMENT,
    payload: payload,
  };
};
export const addQuestionCommentSuccess = (payload) => {
  return {
    type: ADD_QUESTION_COMMENT_SUCCESS,
    payload: payload,
  };
};
export const addQuestionCommentFailure = (payload) => {
  return {
    type: ADD_QUESTION_COMMENT_FAILURE,
    payload: payload,
  };
};

//answering a single question
export const answerQuestions = (payload) => {
  return {
    type: ANSWER_QUESTIONS,
    payload: payload,
  };
};
export const answerQuestionsSuccess = (payload) => {
  return {
    type: ANSWER_QUESTIONS_SUCCESS,
    payload: payload,
  };
};

export const answerQuestionsFailure = (error) => {
  return {
    type: ANSWER_QUESTIONS_FAILURE,
    payload: error,
  };
};

export const editSession = (payload) => {
  return {
    type: EDIT_SESSION,
    payload: payload,
  };
};
export const editSessionSuccess = (payload) => {
  return {
    type: EDIT_SESSION_SUCCESS,
    payload: payload,
  };
};

export const editSessionFailure = (error) => {
  return {
    type: EDIT_SESSION_FAILURE,
    payload: error,
  };
};

export const deleteSession = (payload) => {
  return {
    type: DELETE_SESSION,
    payload: payload,
  };
};

export const deleteSessionSuccess = (payload) => {
  return {
    type: DELETE_SESSION_SUCCESS,
    payload: payload,
  };
};

export const deleteSessionFailure = (error) => {
  return {
    type: DELETE_SESSION_FAILURE,
    payload: error,
  };
};

//get saved questions
export const getSavedQuestions = (payload) => {
  return {
    type: SAVED_QUESTIONS,
    payload: payload,
  };
};

export const getSavedQuestionsSuccess = (payload) => {
  return {
    type: SAVED_QUESTIONS_SUCCESS,
    payload: payload,
  };
};

export const getSavedQuestionsFailure = (error) => {
  return {
    type: SAVED_QUESTIONS_FAILURE,
    payload: error,
  };
};

//get saved questions
export const getCommentedOnQuestions = (payload) => {
  return {
    type: GET_COMMENTED_ON_QUESTIONS,
    payload: payload,
  };
};

export const getCommentedOnQuestionsSuccess = (payload) => {
  return {
    type: GET_COMMENTED_ON_QUESTIONS_SUCCESS,
    payload: payload,
  };
};

export const getCommentedOnQuestionsFailure = (error) => {
  return {
    type: GET_COMMENTED_ON_QUESTIONS_FAILURE,
    payload: error,
  };
};

//get type questions
export const getTypeQuestions = (payload) => {
  return {
    type: GET_TYPE_ON_QUESTIONS,
    payload: payload,
  };
};

export const getTypeQuestionsSuccess = (payload) => {
  return {
    type: GET_TYPE_ON_QUESTIONS_SUCCESS,
    payload: payload,
  };
};

export const getTypeQuestionsFailure = (error) => {
  return {
    type: GET_TYPE_ON_QUESTIONS_FAILURE,
    payload: error,
  };
};
