import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getSubscription } from "store/actions";
import { sendCouponCode } from "store/payment/actions";

function CouponDiscount() {
  const { id } = useParams();
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const { singleSubscription } = useSelector((state) => state?.subscriptions);
  const { coupon, couponLoading } = useSelector((state) => state?.payment);

  const [code, setCode] = useState(null);

  useEffect(() => {
    if (singleSubscription?.id !== id) dispatch(getSubscription(id));
  }, [dispatch]);

  const onSubmit = () => {
   
      dispatch(
        sendCouponCode({ data: { code, subscription: singleSubscription.id } })
      );
  };
  console.log(singleSubscription?.id , "singleSubscription.id");

  return (
    <Col xs="12">
      <div>
        <FormattedMessage id="coupon" />
      </div>
      <label>
        <input
          type="text"
          name="couponCode"
          onChange={(e) => setCode(e.target.value)}
          disabled={coupon}
        />
      </label>
      <button
        style={{
          backgroundColor: coupon && "#35a735",
        }}
        disabled={couponLoading || coupon}
        onClick={onSubmit}
        type="button"
      >
        {couponLoading ? (
          "Loading..."
        ) : coupon ? (
          <FormattedMessage id="couponApplied" />
        ) : (
          <FormattedMessage id="applyCoupon" />
        )}
      </button>
    </Col>
  );
}

export default CouponDiscount;
