import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const GuestRoute = ({ children }) => {
  const { locale } = useIntl();

  const isLoggedIn = useSelector((state) => state.authentication.isLoggedIn);
  return !isLoggedIn ? children : <Navigate replace={true} to={`/${locale}`} />;
};

export default GuestRoute;
