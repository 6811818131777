import { useState } from "react";
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getAllSessions } from "store/actions";
import Icon from "assets/svgs/sessions.svg";
import Table from "./Table";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import ControlArea from "components/Layout/ControlArea";
import Loader from "components/shared/Loader";
import { useSearchParams } from "react-router-dom";

const Sessions = () => {
  const { sessions, metadata, loading, error } = useSelector(
    (state) => state.sessions
  );
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { formatMessage, locale } = useIntl();
  const [isDispatched, setIsDispatched] = useState(false);
  
  useEffect(() => {
    setIsDispatched(true);
    dispatch(getAllSessions(searchParams?.toString()));
  }, [dispatch, searchParams]);

  // error
  useEffect(() => {
    if (error && isDispatched) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error, formatMessage, locale, isDispatched]);
  if (loading) return <Loader />;

  return (
    <>
      <div className="sessions-wrap">
        <ControlArea
          btnTxt={<FormattedMessage id={"addSession"} />}
          cardTxt={<FormattedMessage id={"session"} />}
          icon={Icon}
          url="/sessions/add"
        />

        <Table
          data={sessions}
          metadata={metadata}
          loading={loading}
          title="sessionsTableShowAll"
        />
      </div>
    </>
  );
};

export default Sessions;
