export const GET_ALL_SESSIONS = "GET_ALL_SESSIONS";
export const GET_ALL_SESSIONS_SUCCESS = "GET_ALL_SESSIONS_SUCCESS";
export const GET_ALL_SESSIONS_FAILURE = "GET_ALL_SESSIONS_FAILURE";

export const GET_ALL_ARCHIVED_SESSIONS = "GET_ALL_SESSIONS";
export const GET_ALL_ARCHIVED_SESSIONS_SUCCESS =
  "GET_ALL_ARCHIVED_SESSIONS_SUCCESS";
export const GET_ALL_ARCHIVED_SESSIONS_FAILURE =
  "GET_ALL_ARCHIVED_SESSIONS_FAILURE";

export const GET_QUESTION = "GET_QUESTION";
export const GET_QUESTION_SUCCESS = "GET_QUESTION_SUCCESS";
export const GET_QUESTION_FAILURE = "GET_QUESTION_FAILURE";

export const ANSWER_QUESTION = "ANSWER_QUESTION";
export const ANSWER_QUESTION_SUCCESS = "ANSWER_QUESTION_SUCCESS";
export const ANSWER_QUESTION_FAILURE = "ANSWER_QUESTION_FAILURE";
export const TERMINATE_ACTIVE_SESSION = "TERMINATE_ACTIVE_SESSION";

export const ADD_QUESTION_COMMENT = "ADD_QUESTION_COMMENT";
export const ADD_QUESTION_COMMENT_SUCCESS = "ADD_QUESTION_COMMENT_SUCCESS";
export const ADD_QUESTION_COMMENT_FAILURE = "ADD_QUESTION_COMMENT_FAILURE";

export const ANSWER_QUESTIONS = "ANSWER_QUESTIONS";
export const ANSWER_QUESTIONS_SUCCESS = "ANSWER_QUESTIONS_SUCCESS";
export const ANSWER_QUESTIONS_FAILURE = "ANSWER_QUESTIONS_FAILURE";

export const GET_SESSION = "GET_SESSION";
export const GET_SESSION_SUCCESS = "GET_SESSION_SUCCESS";
export const GET_SESSION_FAILURE = "GET_SESSION_FAILURE";

export const GET_ACTIVE_SESSION = "GET_ACTIVE_SESSION";

export const ADD_SESSION = "ADD_SESSION";
export const ADD_SESSION_SUCCESS = "ADD_SESSION_SUCCESS";
export const ADD_SESSION_FAILURE = "ADD_SESSION_FAILURE";

export const ARCHIVE_SESSION = "ARCHIVE_SESSION";
export const ARCHIVE_SESSION_SUCCESS = "ARCHIVE_SESSION_SUCCESS";
export const ARCHIVE_SESSION_FAILURE = "ARCHIVE_SESSION_FAILURE";

export const EDIT_SESSION = "EDIT_SESSION";
export const EDIT_SESSION_SUCCESS = "EDIT_SESSION_SUCCESS";
export const EDIT_SESSION_FAILURE = "EDIT_SESSION_FAILURE";

export const DELETE_SESSION = "DELETE_SESSION";
export const DELETE_SESSION_SUCCESS = "DELETE_SESSION_SUCCESS";
export const DELETE_SESSION_FAILURE = "DELETE_SESSION_FAILURE";

export const SAVED_QUESTIONS = "SAVED_QUESTIONS";
export const SAVED_QUESTIONS_SUCCESS = "SAVED_QUESTIONS_SUCCESS";
export const SAVED_QUESTIONS_FAILURE = "SAVED_QUESTIONS_FAILURE";

export const GET_COMMENTED_ON_QUESTIONS = "GET_COMMENTED_ON_QUESTIONS";
export const GET_COMMENTED_ON_QUESTIONS_SUCCESS =
  "GET_COMMENTED_ON_QUESTIONS_SUCCESS";
export const GET_COMMENTED_ON_QUESTIONS_FAILURE =
  "GET_COMMENTED_ON_QUESTIONS_FAILURE";

export const GET_TYPE_ON_QUESTIONS = "GET_TYPE_ON_QUESTIONS";
export const GET_TYPE_ON_QUESTIONS_SUCCESS = "GET_TYPE_ON_QUESTIONS_SUCCESS";
export const GET_TYPE_ON_QUESTIONS_FAILURE = "GET_TYPE_ON_QUESTIONS_FAILURE";
