import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Col, Row } from "react-bootstrap";
import Loader from "components/shared/Loader";
import { FormattedMessage, useIntl } from "react-intl";
import "styles/payment.css";
import { getCurrentUserStatistics } from "store/actions";
import { Link } from "react-router-dom";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

const Payment = () => {
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();

  useEffect(() => {
    dispatch(getCurrentUserStatistics());
  }, [dispatch]);

  const { userStats, loading, error } = useSelector(
    (state) => state.authentication
  );
  const subscription = userStats?.currentSubscription?.subscription;

  useEffect(() => {
    if (error) {
      if (error.statusCode === 404)
        toastErrorMessage(
          `${formatMessage({ id: "item" })} ${formatMessage({
            id: "no-exist",
          })}`,
          locale
        );
      else
        toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error]);

  if (loading) return <Loader />;



  return (
    <div className="view-page payment-page">
      <div className="card text-center py-5">
        <div className="card-body ">
          <Col md={12}>
            {!error && (
              <div className="h1 py-3">
                <FormattedMessage id="payment-done" />{" "}
                <Badge bg="success">
                  <FormattedMessage id="success" />
                </Badge>
              </div>
            )}
            <div>
              <FormattedMessage id="subscribed-to" /> {subscription?.plan}{" "}
              <FormattedMessage
                id={subscription?.plan > 1 ? "months" : "month"}
              />
            </div>

            <div className="py-3">
              <Link to="/subscriptions">
                <FormattedMessage id="go-subscriptions" />
              </Link>
            </div>
          </Col>
        </div>
      </div>
    </div>
  );
};

export default Payment;
