import React, { Suspense, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { Navigate, Route, Routes } from "react-router";
import GuestRoute from "helpers/authentication/guestRoute";
import AuthRoute from "helpers/authentication/authRoute";
import Layout from "./components/Layout/Layout";
import Dashboard from "views/dashboard";
import NotFound from "views/NotFound";
import Login from "views/login";
import Logout from "views/Logout";
import {
  getAllSettings,
  getAnnouncement,
  getCurrentUser,
  getCurrentUserStatistics,
} from "store/actions";
import Loader from "./components/shared/Loader";
import Subscriptions from "views/Subscriptions";
import SubscriptionView from "views/Subscriptions/View";

import SavedQuestions from "views/Question/saved";

import Account from "views/Account";
import Question from "views/Question";
import Sessions from "views/Sessions";
import Archived from "views/Sessions/Archived";
import Exam from "views/Sessions/Exam";
import TypeView from "views/Sessions/TypeView";
import SessionView from "views/Sessions/View";
import AddSession from "views/Sessions/Add";
import ForgotPassword from "views/forgotPassword";
import ResetPassword from "views/resetPassword";
// import AOS from "aos";
import Payment from "views/Payment/index";
import PaymentSuccess from "views/Payment/success";
import PaymentFailed from "views/Payment/failed";
import PaymentRequired from "views/Payment/required";
import CommentedOn from "views/Question/commentedOn";
import Cookies from "js-cookie";
import ServerDownPage from "views/ServerDown";
// import "aos/dist/aos.css";
import "swiper/swiper.css";
import SubscriptionRoute from "helpers/authentication/subscriptionRoute";
import { Context } from "wrapper";
// import "../node_modules/swiper/css";
// import "../node_modules/swiper/css/pagination";
// import "../node_modules/swiper/css/navigation";

const App = () => {
  const dispatch = useDispatch();
  const token = Cookies.get("dash-token");
  const { locale } = useContext(Context); // Access locale from context
  //selectors
  const { isLoggedIn, userStats } = useSelector(
    (state) => state?.authentication
  );
  const navigate = useNavigate();

 
  useEffect(() => {
    let search = window.location.search,
      params = new URLSearchParams(search);
    console.log("sub", params.has("sub"))
    
    if (params.has("sub") && Cookies.get("dash-token")) {
      let sub = params.get("sub");
  
      // Use navigate instead of window.history.replaceState
      if (locale.lang === "ar") {
        navigate(`/ar/payment/${sub}`, { replace: true });
      } else if (locale.lang === "en") {
        navigate(`/en/payment/${sub}`, { replace: true });
      } else {
        // Handle any other language case (fallback to 'ar' or 'en')
        navigate(`/ar/payment/${sub}`, { replace: true });
      }
    }
  }, [locale.lang, navigate]);
  

  useEffect(() => {
    if (token) {
      dispatch(getCurrentUser());
      // dispatch(getAnnouncement());
    }
  }, [token, dispatch]);

  const { mainSettings } = useSelector((state) => state.settings);
  const logo = mainSettings?.find((element) => {
    return element.title === "appLogo";
  });

  useEffect(() => {
    if (token) dispatch(getCurrentUserStatistics());
    dispatch(getAllSettings());
  }, [dispatch, token]);


  const RenderApp = () => {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        {/* <BrowserRouter> */}
        <Routes>
          <Route element={<Layout />}>
            <Route
              path="/:lang"
              element={
                <AuthRoute>
                  <Dashboard />
                </AuthRoute>
              }
            />

            {/* subscriptions */}
            <Route
              path="/:lang/subscriptions"
              element={
                <AuthRoute>
                  <Subscriptions />
                </AuthRoute>
              }
            />
            <Route
              path="/:lang/subscriptions/:id"
              element={
                <AuthRoute>
                  <SubscriptionView />
                </AuthRoute>
              }
            />

            {/* {userStats?.currentSubscription && (
                <> */}
            {/* saved question */}
            <Route
              path="/:lang/saved-questions"
              element={
                <AuthRoute>
                  <SubscriptionRoute>
                    <SavedQuestions />
                  </SubscriptionRoute>
                </AuthRoute>
              }
            />

            {/* commented on question */}
            <Route
              path="/:lang/commented-on"
              element={
                <AuthRoute>
                  <SubscriptionRoute>
                    <CommentedOn />
                  </SubscriptionRoute>
                </AuthRoute>
              }
            />

            {/* sessions */}
            <Route
              path="/sessions"
              element={
                <AuthRoute>
                  <SubscriptionRoute>
                    <Sessions />
                  </SubscriptionRoute>
                </AuthRoute>
              }
            />
            <Route
              path="/sessions/add"
              element={
                <AuthRoute>
                  <SubscriptionRoute>
                    <AddSession />
                  </SubscriptionRoute>
                </AuthRoute>
              }
            />
            <Route
              path="/sessions/archived"
              element={
                <AuthRoute>
                  <SubscriptionRoute>
                    <Archived />
                  </SubscriptionRoute>
                </AuthRoute>
              }
            />
            <Route
              path="/sessions/exam"
              element={
                <AuthRoute>
                  <SubscriptionRoute>
                    <Exam />
                  </SubscriptionRoute>
                </AuthRoute>
              }
            />

            <Route
              path="/sessions/:id"
              element={
                <AuthRoute>
                  <SubscriptionRoute>
                    <SessionView />
                  </SubscriptionRoute>
                </AuthRoute>
              }
            />
            <Route
              path="/sessions/type/:filter"
              element={
                <AuthRoute>
                  <SubscriptionRoute>
                    <TypeView />
                  </SubscriptionRoute>
                </AuthRoute>
              }
            />
            <Route
              path="/sessions/:id/question/"
              element={
                <AuthRoute>
                  <SubscriptionRoute>
                    <Question />
                  </SubscriptionRoute>

                  {/* <SubscriptionRoute></SubscriptionRoute> */}
                </AuthRoute>
              }
            />
            {/* </>
              )} */}

            <Route
              path="/account/"
              element={
                <AuthRoute>
                  <Account />
                </AuthRoute>
              }
            />


            <Route
              path="/:lang/payment/required"
              element={
                <AuthRoute>
                  <PaymentRequired />
                </AuthRoute>
              }
            />
            <Route path="/:lang/payment/:id" element={<Payment />} />
            <Route path="/:lang/payment/failed" element={<PaymentFailed />} />
            <Route path="/:lang/payment/success" element={<PaymentSuccess />} />

            <Route
              path="*"
              element={
                <AuthRoute>
                  <NotFound />
                </AuthRoute>
              }
            />
            <Route path="/server-down" element={<ServerDownPage />} />
          </Route>

          {/*Auth Routes */}
          <Route
            path="/logout"
            element={
              <AuthRoute>
                <Logout />
              </AuthRoute>
            }
          />
          <Route
            path="/login"
            element={
              <GuestRoute>
                <Login logo={logo} />
              </GuestRoute>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <GuestRoute>
                <ForgotPassword logo={logo} />
              </GuestRoute>
            }
          />
          <Route
            path="/reset-password"
            element={
              <GuestRoute>
                <ResetPassword logo={logo} />
              </GuestRoute>
            }
          />
        </Routes>
        {/* </BrowserRouter> */}
      </Suspense>
    );
  };

  if (!token) return RenderApp();
  if (isLoggedIn !== null) return RenderApp();
  return <Loader />;
};

export default App;
