import {
  ADD_SUBSCRIPTION,
  ADD_SUBSCRIPTION_FAILURE,
  ADD_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION_FAILURE,
  DELETE_SUBSCRIPTION_SUCCESS,
  EDIT_SUBSCRIPTION,
  EDIT_SUBSCRIPTION_FAILURE,
  EDIT_SUBSCRIPTION_SUCCESS,
  GET_ALL_SUBSCRIPTIONS,
  GET_ALL_SUBSCRIPTIONS_FAILURE,
  GET_ALL_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_FAILURE,
  GET_SUBSCRIPTION_SUCCESS,
  GET_USER_SUBSCRIPTION,
  GET_USER_SUBSCRIPTION_SUCCESS,
  GET_USER_SUBSCRIPTION_FAILURE,
} from "./actionTypes";

const initialState = {
  subscriptions: [],
  loading: false,
  error: "",
  singleSubscription: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    // get all subscriptions
    case GET_ALL_SUBSCRIPTIONS:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_SUBSCRIPTIONS_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        subscriptions: action.payload.userSubscriptions,
      };
      break;

    case GET_ALL_SUBSCRIPTIONS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // add Subscription
    case ADD_SUBSCRIPTION:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;

    case ADD_SUBSCRIPTION_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleSubscription: action.payload.Subscription,
      };
      break;

    case ADD_SUBSCRIPTION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // get Subscription
    case GET_SUBSCRIPTION:
      state = {
        ...state,
        error: "",
        loading: true,
        singleSubscription: {},
      };
      break;

    case GET_SUBSCRIPTION_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleSubscription: action.payload.subscription,
      };
      break;
    case GET_SUBSCRIPTION_FAILURE:
      state = {
        ...state,
        error: "",
        loading: false,
        singleSubscription: null,
      };
      break;
    // get Subscription
    case GET_USER_SUBSCRIPTION:
      state = {
        ...state,
        error: "",
        loading: true,
        singleSubscription: {},
      };
      break;

    case GET_USER_SUBSCRIPTION_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleSubscription: action.payload.subscription,
      };
      break;

    case GET_USER_SUBSCRIPTION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // edit Subscription
    case EDIT_SUBSCRIPTION:
      state = {
        ...state,
        error: "",
        loading: true,
        singleSubscription: {},
      };
      break;

    case EDIT_SUBSCRIPTION_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleSubscription: action.payload.subscription,
      };
      break;

    case EDIT_SUBSCRIPTION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // delete Subscription
    case DELETE_SUBSCRIPTION:
      state = {
        ...state,
        error: "",
        loading: true,
        singleSubscription: {},
      };
      break;

    case DELETE_SUBSCRIPTION_SUCCESS:
      const subscriptionsAfterDeleteing = [
        ...state?.subscriptions?.filter((type) => type?.id !== action.payload),
      ];
      state = {
        ...state,
        error: "",
        loading: false,
        subscriptions: subscriptionsAfterDeleteing,
      };
      break;

    case DELETE_SUBSCRIPTION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
