import { Button, Col, Row } from "react-bootstrap";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ExInput from "./ExamInput";
import Comments from "./ExamComments";
import { handleImage } from "helpers/functions";
import FsLightbox from "fslightbox-react";
import { QuestionTitleImgsNodes } from "./QuestionTitleImages";
import { ReactComponent as Star } from "assets/svgs/star.svg";
import { ReactComponent as Flag } from "assets/svgs/flag.svg";
import { ReactComponent as Skip } from "assets/svgs/skip.svg";
import { toast } from "react-toastify";
import { removeSavedQuestionApi, saveQuestionApi } from "api/sessions";
import { useDispatch, useSelector } from "react-redux";
import { getSavedQuestions } from "store/actions";
import QuestionProgress from "./QuestionProgress";
import quesMark from "../../assets/images/ques-mark.png";
import QuestionExplanation from "./QuestionExplanation";
import { FaCheck, FaRegFileLines } from "react-icons/fa6";
import { FaCircleQuestion, FaHeart } from "react-icons/fa6";
import { FaFileAlt } from "react-icons/fa";
import { CgMenuGridR } from "react-icons/cg";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import { FaFlag } from "react-icons/fa";
import { IoBookmarks, IoClose } from "react-icons/io5";
import { MdOutlineInsertComment } from "react-icons/md";
import server from "api/server";

const shouldHideAnswers = () => {
  const currentURL = window.location.href;
  return (
    currentURL.includes("saved-questions") ||
    currentURL.includes("commented-on")
  );
};

const showIcons = () => {
  const currentURL = window.location.href;
  return currentURL.includes("exam");
};

export default function QuestionComponent({
  questionsState,
  questionsIndex,
  question,
  testAnswer,
  discussQuestion,
  setDiscussion,
  setTestAnswer,
  toggleDiscussion,
  setQuestionsState,
  viewOnly = false,
  savedCheck = false,
  inputParams = {},
  hideProgress = false,
  savedQuestions,
  showExplanation,
  setShowExplantion,
  setQuestionsIndex,
  // setSavedQuestions,
}) {
  const { activeSession, loading, questionLoading } = useSelector(
    (state) => state.sessions
  );
  const [answerKey, setAnswerKey] = useState(0);
  const [showComments, setShowComments] = useState(false);
  const [answerFlaged, setAnswerFlaged] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [incorrectQuestions, setIncorrectQuestions] = useState([]);

  const closeControl = () => {
    setIsActive(false);
  };
  const toggleControl = () => {
    setIsActive(!isActive);
  };
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const allQuestionsCount = questionsState.length;
  const incorrectQuestionsCount = questionsState.filter(
    (q) => q.isCorrect === false
  ).length;

  const comments = {};
  const updateQuestionValue = (name, value) => {
    if (question?.answered) return;

    let questionsTmp = questionsState,
      currQuestion = questionsTmp[questionsIndex];

    currQuestion[name] = value;
    questionsTmp[questionsIndex] = currQuestion;
    if (name === "answer" && !currQuestion.isCorrect) {
      setIncorrectQuestions((prevIncorrect) => [
        ...prevIncorrect,
        { ...currQuestion, index: questionsIndex },
      ]);
    }

    setQuestionsState(questionsTmp);
  };

  // useEffect(() => {
  //   handleFlag();
  // }, [answerFlaged]);
  const updateAnswersArray = ({ target: { value } }) => {
    if (question?.answered) return;

    let questionAnswer = questionsState[questionsIndex]?.answer || [];

    // if (question?.type != "multiselect") {
    if (questionAnswer === value) questionAnswer = [];
    else questionAnswer = [value];
    // } else {
    //   if (questionAnswer?.includes(value))
    //     questionAnswer = questionAnswer?.filter((e) => e != value);
    //   else {
    //     if (!questionAnswer?.length) questionAnswer = [];
    //     questionAnswer?.push(value);
    //   }
    // }

    questionAnswer.answered = true;
    updateQuestionValue("answer", questionAnswer);
    setAnswerKey(Date.now());
  };

  const saveQuestion = async () => {
    try {
      let res = await saveQuestionApi(question?.id, activeSession.id);
      if (res?.statusCode === 200) {
        dispatch(getSavedQuestions());
        questionsState[questionsIndex].isSaved = true;

        document.querySelector(".btn_save").classList.add("active");

        toast.success(formatMessage({ id: "question-saved" }));
      } else throw "";
    } catch (error) {
      toast.error(formatMessage({ id: "sendingErrorMessage" }));
    }
  };

  const removeQuestion = async (savedQuestion) => {
    try {
      let res = await removeSavedQuestionApi(
        savedQuestion?.id || savedQuestion?._id,
        activeSession.id
      );

      if (res?.statusCode == undefined) {
        dispatch(getSavedQuestions());
        questionsState[questionsIndex].isSaved = false;

        document.querySelector(".btn_save").classList.remove("active");

        toast.success(formatMessage({ id: "question-removed" }));
      } else throw "";
    } catch (error) {
      toast.error(formatMessage({ id: "sendingErrorMessage" }));
    }
  };

  const renderAnswers = () =>
    question?.answers?.map((a, index) => (
      <Col className="col-12" key={index}>
        <label
          className={`radio_group__ ${
            testAnswer && (!a?.isCorrect ? "wrong__" : "correct__")
          }
          ${showExplanation && (a?.isCorrect ? "correct__" : "wrong__")} 
          ${
            questionsState?.[questionsIndex]?.answer === a?.index ||
            questionsState?.[questionsIndex]?.answer?.includes(a?.index)
              ? testAnswer
                ? "selected-index"
                : "active"
              : ""
          }
          ${
            !a?.isCorrect &&
            questionsState?.[questionsIndex]?.answer === a?.index
              ? "selected-wrong"
              : ""
          }`}
        >
          <div className="d-flex align-items-center justify-content-center">
            <ExInput
              question={question}
              answer={questionsState?.[questionsIndex]?.answer}
              questionsIndex={questionsIndex}
              value={a?.index}
              onChange={!viewOnly ? updateAnswersArray : () => {}}
              index={answerKey}
              {...inputParams}
              disabled={showExplanation}
            />

            <p title={a?.answer}>{a?.answer}</p>
          </div>
          {showExplanation && a?.isCorrect && (
            <div className="w-100 pt-3">
              <QuestionExplanation currentQuesion={question} />
              <div className="question-title-imgs">
                <QuestionTitleImgsNodes html={question?.explanation} />
              </div>
            </div>
          )}
        </label>
      </Col>
    )) || [];

  if (!question?.title)
    return (
      <span className="position-relative rounded-1 w-100 p-3 bg-warning bg-opacity-75 border-warning text-black bahij-bold">
        {questionsIndex + 1} ) <FormattedMessage id="emptyQuestion" />
      </span>
    );

  const handleFlag = async (sessionId, id) => {
    try {
      const currentQuestion = questionsState[questionsIndex];

      const data = {
        flag: true,
      };
      const response = await server().post(
        `/auth/sessions/flagQuestion/${sessionId}/${id}`,
        data
      );

      if (response.status === 200) {
        currentQuestion.isFlagged = data.flag;
        setAnswerFlaged((prevFlagged) => [
          ...prevFlagged,
          { ...currentQuestion, index: questionsIndex },
        ]);
        toast.success(formatMessage({ id: "flagQuestionMessage" }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error handling flag:", error);
    }
  };

  return (
    <>
      <div
        className="question-title question_title_no_image"
        dir="auto"
        dangerouslySetInnerHTML={{
          __html: question?.title,
        }}
      ></div>
      <div className="question-title-imgs mb-4">
        <QuestionTitleImgsNodes html={question?.title} />
      </div>

      <Row className="answers" dir="auto">
        <Col className={`align-self-center col-12`}>
          {!shouldHideAnswers() && <Row>{renderAnswers()}</Row>}
        </Col>
        {showComments && (
          <>
            <Col className="col-12 col-md-1">
              <div className="separator"></div>
            </Col>
            <Col xs="12">
              <Comments question={question} comments={comments} />
            </Col>
          </>
        )}

        {!(
          window.location.href.includes("commented-on") ||
          window.location.href.includes("saved-questions")
        ) && (
          <div className="control-area mt-3">
            <div className="d-flex align-items-center gap-3">
              <button className="notes" onClick={toggleControl} type="button">
                <FormattedMessage id="cp" />
                <CgMenuGridR />
              </button>
              <button
                onClick={() => {
                  setShowExplantion(() => false);
                  setShowComments((prevState) => !prevState);
                }}
                className={`discuss-btn notes ${showComments && "active"}`}
                type="button"
              >
                <FormattedMessage id="notes" />
                <FaFileAlt />
              </button>
            </div>
            <button
              onClick={() => {
                let savedQuestionTmp = savedCheck();
                savedQuestionTmp
                  ? removeQuestion(savedQuestionTmp)
                  : saveQuestion();
              }}
              className={`flag btn_save ${
                questionsState[questionsIndex]?.isSaved && "q_saved"
              }`}
              type="button"
            >
              <FormattedMessage id="save" />
              <IoBookmarks />
            </button>

            <button
              className={`flag ${
                questionsState[questionsIndex]?.isFlagged ? "isFlagged" : ""
              }`}
              type="button"
              onClick={() => handleFlag(activeSession.id, question.id)}
            >
              <FormattedMessage id="flagg" />
              <FaFlag />
            </button>

            <button
              onClick={() => {
                setShowComments(() => false);
                setShowExplantion((prevState) => !prevState);
              }}
              className={`test-answer-btn${testAnswer && "active"}`}
              type="button"
            >
              {!testAnswer ? (
                <>
                  <FormattedMessage id="text-answer" />
                  <FaCheck />
                </>
              ) : (
                <FormattedMessage id="return-question" />
              )}
            </button>
          </div>
        )}
      </Row>

      <div className={`control ${isActive ? "active" : ""}`}>
        <div className="heading d-flex align-items-center  justify-content-between gap-3">
          <h3>
            {" "}
            <FormattedMessage id="cp" />
          </h3>
          <a className="btn_transparent IoClose" onClick={closeControl}>
            <IoClose />
          </a>
        </div>

        <ul>
          <li>
            <a href="/sessions" className="Go-Summary">
              <FaRegFileLines />
              <span>
                <FormattedMessage id="Summary" />
              </span>
            </a>
          </li>
        </ul>
        <div className="tab">
          <h3>
            <FormattedMessage id="questions" />
          </h3>

          <Tabs
            defaultActiveKey="All"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab
              eventKey="All"
              title={
                <>
                  <FormattedMessage id="All" />{" "}
                  <span>({allQuestionsCount})</span>
                </>
              }
            >
              <div className="tab-content-wrapper">
                <ul className="test">
                  {questionsState.map((q, index) => (
                    <li
                      key={index}
                      className={`li ${
                        questionsIndex === index ? "active" : ""
                      }`}
                      onClick={() => setQuestionsIndex(index)}
                    >
                      <FormattedMessage id="question" /> {index + 1}
                    </li>
                  ))}
                </ul>
              </div>
            </Tab>

            <Tab
              eventKey="Flagged"
              title={
                <>
                  <FormattedMessage id="Flagged" />
                  <span>
                    {" "}
                    ({" "}
                    {answerFlaged
                      ? answerFlaged.filter((q) => q.isFlagged === true).length
                      : null}{" "}
                    )
                  </span>
                </>
              }
            >
              <div className="tab-content-wrapper">
                <ul className="test tap_isFlagged">
                  {answerFlaged
                    ?.filter((q) => q.isFlagged === true)
                    ?.map((q, index) => (
                      <li
                        key={index}
                        className={`li ${
                          questionsIndex === q.index ? "active" : ""
                        }`}
                        onClick={() => setQuestionsIndex(q.index)}
                      >
                        <FormattedMessage id="question" /> {q.index + 1}
                      </li>
                    ))}
                </ul>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}

// {
/* <div className='question-title-imgs'>
                <QuestionTitleImgsNodes html={question?.title || ''} />
              </div> */
// }
// {
/* <div className='question-actions'> */
// }

// {
/* <button className='flag-btn' type='button'>
              <Flag />
              <span>
                <FormattedMessage id='flag' />
              </span>
            </button> */
// }
// {
/* <button className='skip-btn' type='button'>
              <Skip />
              <span>
                <FormattedMessage id='skip' />
              </span>
            </button> */
// }
// {
/* </div> */
// }
//  {/*
//             <Tab
//               eventKey="incorrectQuestions"
//               title={
//                 <>
//                   <FormattedMessage id="incorrectQuestions" />{" "}
//                   <span>({incorrectQuestions.length})</span>
//                 </>
//               }
//             >
//               <div className="tab-content-wrapper">
//                 <ul className="test tap_incorrectQuestions">
//                   {incorrectQuestions.map((q, index) => (
//                     <li
//                       key={index}
//                       className={`li ${
//                         questionsIndex === q.index ? "active" : ""
//                       }`}
//                       onClick={() => setQuestionsIndex(q.index)}
//                     >
//                       <FormattedMessage id="question" /> {q.index + 1}
//                     </li>
//                   ))}
//                 </ul>
//               </div>
//             </Tab> */}
