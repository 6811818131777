import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import { getSubscription, getUserSubscription } from "store/actions";
import { handleIsoDate } from "helpers/functions";

const View = () => {
  const { formatMessage, locale } = useIntl();
  // const dispatch = useDispatch();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { loading, error, singleSubscription } = useSelector(
    (state) => state.subscriptions
  );

  // TODO
  // effects
  useEffect(() => {
    if (singleSubscription.id !== id) dispatch(getUserSubscription(id));
  }, [dispatch, id, singleSubscription.id]);

  // error
  useEffect(() => {
    if (error) {
      // toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
      navigate("/subscriptions");
    }
  }, [error, formatMessage, locale, navigate]);

  if (loading) return <Loader />;
  const monthsMessage = (month) => (month > 1 ? "months" : "month"),
    content = `${singleSubscription?.subscription?.plan} ${formatMessage({
      id: monthsMessage(singleSubscription?.subscription?.plan),
    })}`;

  return (
    <>
      <div className="view-page">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id={"details"} />{" "}
              <FormattedMessage id={"subscription"} />
            </h4>
          </div>
          <div className="card-body">
            <Col xl={8} md={12}>
              {(singleSubscription?.plan !==
                singleSubscription?.subscription?.plan ||
                singleSubscription?.price !==
                  singleSubscription?.subscription?.price) && (
                <p className="p-1 mb-2 rounded-1 w-100 bg-warning bg-opacity-75 border-warning text-black">
                  <FormattedMessage id="subscriptionChangedFrom" />
                  {/* <br /> */}
                  <span className="bahij-bold">
                    {`${singleSubscription?.plan} ${formatMessage({
                      id: monthsMessage(singleSubscription?.plan),
                    })}, ${singleSubscription?.price} ${formatMessage({
                      id: "SAR",
                    })}`}
                  </span>
                  <br />
                  <FormattedMessage id="to" />{" "}
                  <span className="bahij-bold">
                    {`${singleSubscription?.subscription?.plan} ${formatMessage(
                      {
                        id: monthsMessage(
                          singleSubscription?.subscription?.plan
                        ),
                      }
                    )}, ${
                      singleSubscription?.subscription?.price
                    } ${formatMessage({
                      id: "SAR",
                    })}`}
                  </span>
                  <br />
                  <FormattedMessage id="in" />{" "}
                  <span className="bahij-bold">
                    <FormattedDate
                      value={singleSubscription?.subscription?.updatedAt}
                      day="numeric"
                      month="long"
                      year="numeric"
                    />
                  </span>
                  <br />
                  <FormattedMessage id="newSubAffect" />
                </p>
              )}
              <Row>
                <Col lg={12} xs={12}>
                  <Table>
                    <tbody>
                      <tr>
                        <th>
                          <FormattedMessage id={"price"} />
                        </th>
                        <td>{`${
                          singleSubscription?.subscription?.price || "0"
                        } ${formatMessage({ id: "SAR" })}`}</td>
                      </tr>
                      <tr>
                        <th>
                          <FormattedMessage id={"plan"} />
                        </th>
                        <td>{content || ""}</td>
                      </tr>
                      <tr>
                        <th>
                          <FormattedMessage id={"startAt"} />
                        </th>
                        <td>
                          {/* {new Date(singleSubscription?.startAt).toLocaleString(
                            locale
                          )} */}
                          {handleIsoDate(singleSubscription?.startAt)}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <FormattedMessage id={"endAt"} />
                        </th>

                        <td>
                          {/* {new Date(singleSubscription?.endAt).toLocaleString(
                            locale
                          )} */}
                          {handleIsoDate(singleSubscription?.endAt)}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <FormattedMessage id={"status"} />
                        </th>
                        <td>
                          <span
                            className={`badge rounded-pill ${
                              singleSubscription?.status == "active"
                                ? "bg-success"
                                : "bg-danger"
                            }`}
                          >
                            {formatMessage({
                              id: singleSubscription?.status || "status",
                            })}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Col>
          </div>
        </div>
        <div className="card ">
          <div className="card-head">
            <h4>
              <FormattedMessage id={"features"} />
            </h4>
          </div>
          <Col>
            <Row>
              <Table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th className="w-75">
                      <FormattedMessage id="name" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {singleSubscription?.subscription?.features?.length > 0 &&
                    singleSubscription?.subscription?.features.map(
                      (feature, k) => {
                        return (
                          <tr key={`${feature?.id}${k}`}>
                            <td>{k + 1}</td>
                            <td>{feature?.name?.[locale]}</td>
                          </tr>
                        );
                      }
                    )}
                </tbody>
              </Table>
            </Row>
          </Col>
        </div>
     
      </div>
    </>
  );
};

export default View;
