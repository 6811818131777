import React, { useEffect, useLayoutEffect, useState } from 'react';
import ExamCommentInput from './ExamCommentInput';
import { FormattedDate, FormattedMessage, FormattedTime, useIntl } from 'react-intl';
import FsLightbox from 'fslightbox-react';
import { useSelector } from 'react-redux';
import avatar from 'assets/images/avatar.png';
import 'styles/comments.css';
import { handleImage } from 'helpers/functions';
import { toast } from 'react-toastify';
import server from 'api/server';
import { IoClose } from 'react-icons/io5';

const Comments = ({ question }) => {
  const { user } = useSelector((state) => state.authentication);
  const { formatMessage } = useIntl();
  const [comments, setComments] = useState(question?.comments || []);

  useEffect(() => {
    setComments(question?.comments || []);
  }, [question]);

  const questionId = question?.id;
  const deleteQwetion = async (_id) => {
    try {
      await server().delete(`auth/pinQuestions/commentedOn/${questionId}/${_id}`);
      setComments((prevComments) => prevComments.filter((c) => c._id !== _id));
      toast.success(formatMessage({ id: 'question-removed' }));
    } catch (error) {
      console.error('Error deleting question:', error);
      toast.error(formatMessage({ id: 'delete-failed' }));
    }
  };

  const renderComments = comments
    ?.filter((e) => e.user === user._id || e.user?.id === user._id)
    ?.map((c, index) => {
      return (
        <div className='comment_block' key={index}>
          <div className='user_image_wrapper'>
            <img
              className='user_image'
              src={
                c?.user?.image?.length > 0 &&
                !c?.user?.image?.includes('undefined')
                  ? handleImage(c?.user?.image)
                  : avatar
              }
              alt={'avatar'}
            />
          </div>
          <div className='comment_content'>
            <span className='user_name'>{c?.user?.fullName || ''}</span>
            <span className='comment_time'>
              <FormattedTime value={c?.createdAt} />
              ,&nbsp;
              <FormattedDate
                value={c?.createdAt}
                day='numeric'
                month='long'
                year='numeric'
              />
            </span>
            <p className='comment_details'>{c?.comment}</p>
            <div className='images-wrapper'>
              {c?.images?.map((i, index) => (
                <Image images={c?.images} index={index} />
              ))}
            </div>
          </div>
          <a className="close_btn" onClick={() => deleteQwetion(c._id)}>
            <IoClose />
          </a>
        </div>
      );
    });

  return (
    <div className='comments'>
      <div className='title'>
        <FormattedMessage id='discussion' />
      </div>
      <div className='comments-wrapper'>
        {comments?.length > 0 ? renderComments : <p className='no_comments'>  <FormattedMessage id='no-comments' /></p>}
      </div>
      <ExamCommentInput question={question} />
      {/* {commentLoading && <div className='lds-dual-ring'></div>} */}
    </div>
  );
};



const Image = ({ images, index }) => {
  const [toggler, setToggler] = useState(false);

  return (
    <>
      <a onClick={() => setToggler(!toggler)} className='image'>
        <img src={handleImage(images?.[index])} alt='' />
      </a>
      <FsLightbox
        toggler={toggler}
        sources={images?.map((i) => handleImage(i))}
        currentSlide={index}
      />
    </>
  );
};

export default Comments;
