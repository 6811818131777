import React from "react";
import { FormattedMessage } from "react-intl";

export function Input({
  langId = "",
  register = () => {},
  name = "",
  errors,
  type = "text",
  className = "",
  required = true,
  ...rest
}) {
  const nameWithoutLocale = name.split(".").at(0);
  const locale = name.split(".").length >= 2 ? name.split(".").at(-1) : "";

  return (
    <div className="form-group required">
      {langId && (
        <h5>
          <FormattedMessage id={langId} />

          {locale === "ar" && (
            <>
              {" "}
              (<FormattedMessage id={"arabic-language"} />){" "}
            </>
          )}
          {locale === "en" && (
            <>
              {" "}
              (<FormattedMessage id={"english-language"} />){" "}
            </>
          )}
        </h5>
      )}
      <div>
        <input
          type={type}
          className={`form-control form-outline ${className}`}
          {...register(name, { required: required })}
          {...rest}
        />
      </div>
      {/* <p className="error-hint">
        {locale !== ""
          ? errors?.[nameWithoutLocale]?.[locale]?.type === "required" && (
              <FormattedMessage id={"requiredField"} />
            )
          : errors?.[nameWithoutLocale]?.type === "required" && (
              <FormattedMessage id={"requiredField"} />
            )}
      </p> */}
    </div>
  );
}

export function Textarea({ langId = "", register, name, errors, ...rest }) {
  const nameWithoutLocale = name.split(".").at(0);
  const locale = name.split(".").length >= 2 ? name.split(".").at(-1) : "";

  return (
    <div className="form-group required">
      {langId && (
        <h5>
          <FormattedMessage id={langId} />

          {locale === "ar" && (
            <>
              {" "}
              (<FormattedMessage id={"arabic-language"} />){" "}
            </>
          )}
          {locale === "en" && (
            <>
              {" "}
              (<FormattedMessage id={"english-language"} />){" "}
            </>
          )}
        </h5>
      )}
      <div>
        <textarea
          type="text"
          className="form-control form-outline"
          {...register(name, { required: true })}
          {...rest}
        ></textarea>
      </div>
      <p className="error-hint">
        {locale !== ""
          ? errors?.[nameWithoutLocale]?.[locale]?.type === "required" && (
              <FormattedMessage id={"requiredField"} />
            )
          : errors?.[nameWithoutLocale]?.type === "required" && (
              <FormattedMessage id={"requiredField"} />
            )}
      </p>
    </div>
  );
}
