export default function ExInput({
  question,
  questionsIndex,
  answer,
  value,
  onChange,
  index,
  ...rest
}) {
  return (
    <input
      className='radio_wrapper__ check_radio_input'
      onChange={onChange}
      type={'checkbox'}
      value={value}
      checked={answer?.includes(value) || answer === value}
      name={
        question?.type !== 'multiselect'
          ? `choisenAnswerIndex[${questionsIndex}]`
          : ''
      }
      key={index}
      {...rest}
    />
  );
}
