import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  CURRENT_USER,
  CURRENT_USER_SUCCESS,
  CURRENT_USER_FAILURE,
  CURRENT_USER_STATISTICS,
  CURRENT_USER_STATISTICS_SUCCESS,
  CURRENT_USER_STATISTICS_FAILURE,
  SAVE_EMAIL,
  LOGIN_FAILURE,
  DISMISS_ANNOUNCEMENT,
  DISMISS_ANNOUNCEMENT_FAILURE,
  DISMISS_ANNOUNCEMENT_SUCCESS,
} from "./actionTypes";

export const loginUser = (payload) => {
  return {
    type: LOGIN_USER,
    payload,
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};
export const loginFailure = (user) => {
  return {
    type: LOGIN_FAILURE,
    payload: user,
  };
};

export const logoutUser = () => {
  return {
    type: LOGOUT_USER,
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
  };
};

export const getCurrentUser = () => {
  return {
    type: CURRENT_USER,
  };
};

export const getCurrentUserSuccess = (payload) => {
  return {
    type: CURRENT_USER_SUCCESS,
    payload,
  };
};

export const getCurrentUserFailure = (payload) => {
  return {
    type: CURRENT_USER_FAILURE,
    payload,
  };
};

//---------------------
export const getCurrentUserStatistics = () => {
  return {
    type: CURRENT_USER_STATISTICS,
  };
};

export const getCurrentUserStatisticsSuccess = (payload) => {
  return {
    type: CURRENT_USER_STATISTICS_SUCCESS,
    payload,
  };
};

export const getCurrentUserStatisticsFailure = (payload) => {
  return {
    type: CURRENT_USER_STATISTICS_FAILURE,
    payload,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
export const saveTempEmail = (error) => {
  return {
    type: SAVE_EMAIL,
    payload: error,
  };
};

export const dismissAnnouncement = (payload) => {
  return {
    type: DISMISS_ANNOUNCEMENT,
    payload: payload,
  };
};

export const dismissAnnouncementSuccess = (payload) => {
  return {
    type: DISMISS_ANNOUNCEMENT_SUCCESS,
    payload: payload,
  };
};

export const dismissAnnouncementFailure = (error) => {
  return {
    type: DISMISS_ANNOUNCEMENT_FAILURE,
    payload: error,
  };
};
