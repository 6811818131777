import {
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  GET_ALL_UNIVERSITIES,
  GET_ALL_UNIVERSITIES_FAILURE,
  GET_ALL_UNIVERSITIES_SUCCESS,
  GET_ALL_CITIES,
  GET_ALL_CITIES_FAILURE,
  GET_ALL_CITIES_SUCCESS,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS_SUCCESS,
  READ_NOTIFICATION,
  READ_NOTIFICATION_SUCCESS,
  READ_NOTIFICATION_FAILURE,
} from "./actionTypes";

const initialState = {
  loading: false,
  error: "",
  universities: [],
  cities: [],
  notifications: [],
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    // get all universities
    case GET_ALL_UNIVERSITIES:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_UNIVERSITIES_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        universities: action.payload.universities,
      };
      break;
    case GET_ALL_UNIVERSITIES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // get all cities
    case GET_ALL_CITIES:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_CITIES_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        cities: action.payload.cities,
      };
      break;

    case GET_ALL_CITIES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // get notificaitons
    case GET_NOTIFICATIONS:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_NOTIFICATIONS_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        notifications: action.payload.notifications,
      };
      break;

    case GET_NOTIFICATIONS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // read notificaitons
    case READ_NOTIFICATION:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case READ_NOTIFICATION_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        // notifications: action.payload.notifications,
      };
      break;

    case READ_NOTIFICATION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
