import { useState, useLayoutEffect, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import {
  answerQuestion,
  terminateActiveSession,
  answerQuestions,
} from "store/actions";
import "styles/sessions.css";
import Timer from "./Timer";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import DangerTriangle from "assets/svgs/danger-triangle.svg";

import QuestionComponent from "./QuestionComponent";
import ExamResultComponent from "./ExamResultComponent";

import quesMark from "../../assets/images/ques-mark.png";
import clock from "../../assets/images/clock.png";
import savedImg from "../../assets/images/office-push-pin.svg";
import skipImg from "../../assets/images/skip.png";

import FinalResutls from "./FinalResutls";
import { ReactComponent as Star } from "assets/svgs/star.svg";
import { ReactComponent as Flag } from "assets/svgs/flag.svg";
import { ReactComponent as Skip } from "assets/svgs/skip.svg";
import { HiCalculator } from "react-icons/hi";
import { FaArrowRightLong, FaArrowLeftLong } from "react-icons/fa6";

const View = () => {
  const { activeSession, loading, questionLoading, savedQuestions } =
    useSelector((state) => state.sessions);
  // const [savedQuestions, setSavedQuestions] = useState([]);

  const questions =
    activeSession?.questions?.map((q) => ({
      ...q,
      // answered: q?.isCorrect != undefined || q?.isSkipped != undefined,
    })) || [];

  const checkQuestionSaved = () =>
    savedQuestions?.find((q) => q?.question?.id === question?.id);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [questionsIndex, setQuestionsIndex] = useState(0);
  const [discussQuestion, setDiscussion] = useState(false);

  const [testAnswer, setTestAnswer] = useState(false);

  const [questionsState, setQuestionsState] = useState([]);
  const [showExplanation, setShowExplantion] = useState(false);
  const [skippedQuestions, setSkippedQuestions] = useState([]);
  const [isQuizActive, setIsQuizActive] = useState(false);
  
  // const [modalToggle, setModalToggle] = useState(false);
  
  
  
  const isFlagged = activeSession.questions?.map((q) => q.isFlagged);
  
console.log(isFlagged , "isFlagged" )
  
  
  
  useLayoutEffect(() => {
    if (activeSession?.status === "active") {
      setIsQuizActive(true);
    } else {
      setIsQuizActive(false);
    }

    // Existing code...
  }, [activeSession]);

  const question = {
    ...(questions?.[questionsIndex]?.question || {}),
    choisenAnswerIndex: questions?.[questionsIndex]?.choisenAnswerIndex,
    answered: questions?.[questionsIndex]?.answered,
    index: questionsIndex,
  };

  useLayoutEffect(() => {
    if (!activeSession?.status)
      toast(formatMessage({ id: "searchActive" }), {
        toastId: "no-active",
      });

    let redirection = setTimeout(() => {
      if (activeSession?.status == undefined) {
        toast(formatMessage({ id: "no-active-session" }), {
          toastId: "no-active",
        });
        dispatch(terminateActiveSession());

        return navigate("/sessions");
      }
    }, 7000);

    if (activeSession?.status) toast.dismiss("no-active");

    let questionStateInit = questions?.map((q, i) => ({
      answer:
        q?.choisenAnswerIndex?.length > 0
          ? q?.choisenAnswerIndex
          : questionsState?.[i]?.answer,
      id: q?._id || q?.id,
      isCorrect: q?.isCorrect,
      isSkipped: q?.isSkipped,
      isSaved: q?.isSaved,
    }));
    setQuestionsState(questionStateInit);

    return () => {
      clearTimeout(redirection);
    };
  }, [activeSession]);

  useEffect(() => {
    let timeoutIndex = null;

    if (questions?.length > 0 && questionsIndex == 0) {
      // let lastQuestion = questions?.findLast((q) => q?.answered),
      let lastQuestion = questions?.findLast(
        (q) => q?.isCorrect != undefined || q?.isSkipped != undefined
      ),
        lastAnsweredQuestionNum = questions?.indexOf(lastQuestion || {});

      if (!activeSession?.testAnswer) {
        if (lastAnsweredQuestionNum + 1 <= questions?.length - 1)
          lastAnsweredQuestionNum += 1;
      } else if (activeSession?.testAnswer) setTestAnswer(true);

      if (activeSession?.discussAnswer) setDiscussion(true);
      timeoutIndex = setTimeout(() => {
        setQuestionsIndex(lastAnsweredQuestionNum);
      }, 10);
    }

    return () => {
      if (timeoutIndex != null) clearTimeout(timeoutIndex);
    };
  }, [activeSession, questionLoading]);

  const toggleDiscussion = () => {
    setTestAnswer(false);
    setDiscussion(!discussQuestion);
  };

  const next = () => {
    if (
      questionsState[questionsIndex].answer?.length < 1 ||
      questionsState[questionsIndex].answer === undefined
    ) {
      console.log("not anwered", question);
      setSkippedQuestions((prevPassedQuesions) => {
        return [...prevPassedQuesions, question];
      });
    } else {
      const passQ = skippedQuestions;
      const findIndex = passQ.findIndex((q) => q.id === question.id);

      if (findIndex >= 0) {
        passQ.splice(findIndex, 1);
        setSkippedQuestions((prevSavedQuestions) => {
          return [...passQ];
        });
      }
    }

    // formSubmit.current.click();
    if (questionsIndex < questions?.length - 1) {
      setDiscussion(false);
      setTestAnswer(false);
      setQuestionsIndex(questionsIndex + 1);
    }
    setShowExplantion(false);
  };

  const back = () => {
    const passQ = skippedQuestions;
    const prevQ = questions?.[questionsIndex - 1]?.question;
    const findIndex = passQ.findIndex((q) => q.id === prevQ.id);

    if (findIndex >= 0) {
      passQ.splice(findIndex, 1);
      setSkippedQuestions((prevSavedQuestions) => {
        return [...passQ];
      });
    }
    if (questionsIndex >= 1) {
      setDiscussion(false);
      setTestAnswer(false);
      setQuestionsIndex(questionsIndex - 1);
    }
    setShowExplantion(false);
  };

  const terminate = () => {
    setQuestionsIndex(questions?.length - 1);
    onSubmit();
    toast(formatMessage({ id: "session-saved" }), { toastId: "saved" });
  };

  const terminateConfirmation = () => {
    const hasFlaggedQuestions = isFlagged.includes(true); 
  
    if (!question?.answered && activeSession?.status === "active")
      submitSingleQuestion();
  
    if (activeSession?.status !== "active") {
      dispatch(terminateActiveSession());
      return navigate("/sessions");
    }
  
    Swal.fire({
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      iconHtml: `<img src="${DangerTriangle}" alt="" />`,
      title: formatMessage({
        id: hasFlaggedQuestions ? "confirm-session-finish-flaged" : "confirm-session-finish",
      }),
      denyButtonText: formatMessage({
        id: "finish",
      }),
      cancelButtonText: formatMessage({
        id: "cancel",
      }),
    }).then((result) => {
      if (result.isDenied) {
        terminate();
      }
    });
  };
  
  const submitSingleQuestion = (testAnswer = false) => {
    let {
      answer: choisenAnswerIndex,
      id,
      isSaved,
    } = questionsState?.[questionsIndex];

    // if (choisenAnswerIndex === undefined) {
    //   toast.error(formatMessage({ id: 'skipError' }));
    // } else {
    //   dispatch(
    //     answerQuestion({
    //       question: {
    //         id,
    //         choisenAnswerIndex,
    //         testAnswer,
    //         isSaved,
    //       },
    //       sessionId: activeSession?.id,
    //       navigate: () => {},
    //     })
    //   );
    //   next();

    //   console.log('choisenAnswerIndex----------------', choisenAnswerIndex);
    // }
    dispatch(
      answerQuestion({
        question: {
          id,
          choisenAnswerIndex,
          testAnswer,
          isSaved,
        },
        sessionId: activeSession?.id,
        navigate: () => { },
      })
    );
    next();
  };

  const onSubmit = () => {
    dispatch(
      answerQuestions({
        questions: questionsState
          ?.filter((e) => e.isCorrect == undefined && e.isSkipped == undefined)
          ?.map((q, i) => ({
            id: q?.id,
            choisenAnswerIndex: q?.answer || [],
            isSaved: q.isSaved,
          })),
        sessionId: activeSession?.id,
        navigate: () => { },
      })
    );
  };

  const savedQuestionCount = questionsState.filter((q) => q.isSaved);

  if (loading) return <Loader />;

  return (
    <form className="session-exam">
      {activeSession?.status === "expired" && (
        <FinalResutls
          savedQuestions={savedQuestions}
          userSession={activeSession}
        />
      )}

      {isQuizActive && ( // Conditionally render step-num
        <div className="step-num">
          <a
            onClick={() => {
              if (!question?.answered) {
                submitSingleQuestion();
              }
              setShowExplantion(false);
            }}
            className={`next btn_transparent ${questionsIndex + 1 === questions?.length && "disable"}`}
          >
            <FaArrowRightLong />
          </a>
          <div className="numbers text-end">
            {questions.map((_, index) => (
              <span
                key={index}
                className={`question-number ${questionsIndex === index ? 'active' : ''}`}
              // onClick={() => setQuestionsIndex(index)}
              >
                {index + 1}
              </span>
            ))}
          </div>
          <a
            onClick={back}
            className={`back btn_transparent ${questionsIndex === 0 && "disable"}`}
          >
            <FaArrowLeftLong />
          </a>
        </div>
      )}


      <Col lg={12} xs={12} className="details-wrapper exam_abdo_details">

        {activeSession?.status !== "expired" && (
          <>
            <Row className="header justify-content-between align-items-center">
              <Col sm={12} md={6}>
                <div className="tag question_time__ align-items-center">
                  <figure className="img_block__ justify-content-center mx-auto text-center">
                    <HiCalculator />
                  </figure>
                  <div className="timer">
                    <Timer
                      submit={() => {
                        toast(formatMessage({ id: "session-timeout" }), {
                          toastId: "timeout",
                        });
                        terminate();
                      }}
                    />
                  </div>
                </div>
              </Col>


            </Row>

            <QuestionComponent
              questionsState={questionsState}
              setQuestionsState={setQuestionsState}
              questionsIndex={questionsIndex}
              question={question}
              testAnswer={testAnswer}
              discussQuestion={discussQuestion}
              setDiscussion={setDiscussion}
              setTestAnswer={setTestAnswer}
              toggleDiscussion={toggleDiscussion}
              savedQuestions={savedQuestions}
              // setSavedQuestions={setSavedQuestions}
              savedCheck={checkQuestionSaved}
              showExplanation={showExplanation}
              setShowExplantion={setShowExplantion}
              setQuestionsIndex={setQuestionsIndex}
            />

            <Row className="footer mt-5">
              <Col>

                <a
                  onClick={terminateConfirmation}
                  className={`finish  `}
                >
                  <FormattedMessage id="finish" />
                </a>
              </Col>
              <Col>
                <a
                  onClick={() => {
                    if (!question?.answered) {
                      submitSingleQuestion();
                    }
                    setShowExplantion(false);
                  }}
                  className={`next ${questionsIndex + 1 == questions?.length && "disable"
                    }`}
                >
                  <FormattedMessage id="next" />
                </a>
                <a
                  onClick={back}
                  className={`back ${questionsIndex == 0 && "disable"}`}
                >
                  <FormattedMessage id="back" />
                </a>
              </Col>
            </Row>
          </>
        )}
      </Col>
    </form>
  );
};

export default View;

{/* <Col sm={12} md={6}>
              
                <div className="indicators d-flex">
                  <div className="save-wrapper">
                    <div>
                      <figure className="img_block__ justify-content-center mx-auto text-center">
                        <img
                          src={savedImg}
                          width={25}
                          height={25}
                          alt={savedImg}
                        />
                      </figure>
                      <span className="number">
                        {savedQuestionCount.length || 0}
                      </span>
                    </div>
                  </div>
                  <div className="save-wrapper">
                    <div>
                      <figure className="img_block__ justify-content-center mx-auto text-center">
                        <img
                          src={skipImg}
                          width={25}
                          height={25}
                          alt={skipImg}
                        />
                      </figure>
                      <span className="number">
                        {skippedQuestions.length || 0}
                      </span>
                    </div>
                  </div>
                   
                </div>
              </Col> */}

{/* <Col>
                <a
                  onClick={() => {
                    if (!question?.answered) {
                      submitSingleQuestion();
                    }
                    setShowExplantion(false);
                  }}
                  className={`next ${questionsIndex + 1 == questions?.length && "disable"
                    }`}
                >
                  <FormattedMessage id="next" />
                </a>
                <a
                  onClick={back}
                  className={`back ${questionsIndex == 0 && "disable"}`}
                >
                  <FormattedMessage id="back" />
                </a>
              </Col> */}