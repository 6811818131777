import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import Cookies from "js-cookie";

// Import Api files
import {
  getCurrentUser,
  getSettingsInfo,
  getCurrentUserStatistics,
  postUserLogin,
} from "../../api/users";

// Login Redux States
import {
  CURRENT_USER,
  CURRENT_USER_STATISTICS,
  DISMISS_ANNOUNCEMENT,
  LOGIN_USER,
  LOGOUT_USER,
} from "./actionTypes";
import {
  apiError,
  getCurrentUserFailure,
  getCurrentUserSuccess,
  getCurrentUserStatisticsSuccess,
  getCurrentUserStatisticsFailure,
  loginSuccess,
  logoutUserSuccess,
  loginFailure,
  dismissAnnouncementSuccess,
  dismissAnnouncementFailure,
} from "./actions";
import { toastErrorMessage } from "components/shared/toastErrorMessage";
import { getActiveSession } from "store/actions";
import { getId } from "helpers/functions";
import { dismissAnnouncement as dismissAnnouncementApi } from "api/announcement";

function* loginUser({ payload }) {
  try {
    const { data } = yield call(postUserLogin, payload.data);
    console.log('data', data)
    yield put(loginSuccess(data));
    Cookies.set("dash-token", data.token, {
      domain:process.env.REACT_APP_COOKIES_DOMAIN,
      expires: 365,
     });
    Cookies.set("specialization", data.user.specialization, {
     domain:process.env.REACT_APP_COOKIES_DOMAIN,
     expires: 365,
    });


    if (payload?.subscription?.length > 0)
      setTimeout(() =>
        payload?.navigate(`/payment/${payload?.subscription} `, 400)
      );
  } catch (error) {
    yield put(loginFailure(error?.response?.data?.errors[0]?.msg));

    // yield put(apiError(error?.response?.data?.errors[0]?.msg));
  }
}

function* logoutUser() {
  try {
    yield put(logoutUserSuccess());
    Cookies.set('dash-token', '', {
      domain: process.env.REACT_APP_COOKIES_DOMAIN,
      expires: 365,
    });
    Cookies.remove("dash-token");
    Cookies.remove("specialization");

  } catch (error) {
    yield put(apiError(error));
  }
}

function* currentUser() {
  try {
    const { data } = yield call(getCurrentUser);
    const logo = yield call(getSettingsInfo);
    yield put(getCurrentUserSuccess({ ...data, logo }));
    const activeSession = getId(data?.user?.currentSession);
    if (activeSession) {
      yield put(getActiveSession(activeSession));
      // window?.history?.pushState({}, null, "/sessions/exam");
      if (!document?.location?.pathname?.includes("exam"))
        document.location.pathname = "/sessions/exam";
    }
  } catch (error) {
    yield put(getCurrentUserFailure(error?.response?.data?.error || error));
    yield put(apiError(error));
  }
}
function* currentUserStatistics() {
  try {
    const { data } = yield call(getCurrentUserStatistics);
    yield put(getCurrentUserStatisticsSuccess(data));
  } catch (error) {
    yield put(
      getCurrentUserStatisticsFailure(error.response.data.error || error)
    );
  }
}

function* dismissAnnounce({ payload }) {
  try {
    const { data } = yield call(dismissAnnouncementApi, payload);
    yield put(dismissAnnouncementSuccess(data));
  } catch (error) {
    yield put(dismissAnnouncementFailure(error.response.data.error || error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export function* watchCurrentUser() {
  yield takeEvery(CURRENT_USER, currentUser);
}

export function* watchCurrentUserStatistics() {
  yield takeEvery(CURRENT_USER_STATISTICS, currentUserStatistics);
}

export function* watchDismissAnnounce() {
  yield takeEvery(DISMISS_ANNOUNCEMENT, dismissAnnounce);
}

function* authSaga() {
  yield all([
    fork(watchUserLogin),
    fork(watchUserLogout),
    fork(watchCurrentUser),
    fork(watchCurrentUserStatistics),
    fork(watchDismissAnnounce),
  ]);
}

export default authSaga;
