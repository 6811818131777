import React from 'react';

function QuestionProgress({ questions, currentIndex, setQuestionsIndex }) {

  console.log("questions", questions)
  return (
    <div className='question-progress'>
      {questions?.map((question, index) => {
        return (
          <div
            key={index}
            className={`progress-circle ${
              currentIndex === index ? 'active' : ''
            } ${currentIndex > index && question?.answer?.length > 0 && 'done'}`}
            onClick={() => setQuestionsIndex(index)}
          >
            {index === currentIndex && (
              <span>{`${currentIndex + 1}/${questions.length}`}</span>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default QuestionProgress;
