import images from "react-payment-inputs/images";
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import CouponDiscount from "./CouponDiscount";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { sendCouponCode } from "store/payment/actions";
import server from "api/server";
import { getSubscription } from "store/actions";

export default function TamaraPay({ setCouponCode, paymentMethods }) {
  const {
    meta,
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();

  const { id } = useParams();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { coupon, couponLoading } = useSelector((state) => state.payment);
  const { singleSubscription } = useSelector((state) => state?.subscriptions);

  const [subName, setSubName] = useState("");
  const [subPrice, setSubPrice] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");

  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    dispatch(getSubscription(id));
  }, [dispatch, id]);

  const handleInputChange = (setter) => (e) => setter(e.target.value);

  const TypepaymentMethods = paymentMethods.map((ele) => ele.id);

  const validateForm = () => {
    const errors = {};
    if (!firstName)
      errors.firstName = formatMessage({ id: "firstNameRequired" });
    if (!lastName) errors.lastName = formatMessage({ id: "lastNameRequired" });
    if (!phoneNumber)
      errors.phoneNumber = formatMessage({ id: "phoneNumberRequired" });
    if (!email) {
      errors.email = formatMessage({ id: "emailRequired" });
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = formatMessage({ id: "invalidEmail" });
    }
    return errors;
  };

  const handleSubmit = async () => {
    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      toast.error(formatMessage({ id: "formValidationFailed" }));
      return;
    }

    const data = {
      paymentMethod: TypepaymentMethods.join(", "),
      subName: singleSubscription?.price,
      subPrice: singleSubscription?.plan,
      subscription: singleSubscription?.id,
      type: "tamara",
      firstName,
      lastName,
      phoneNumber,
      email,
      couponCode: code,
    };

    try {
      const response = await server().post("/auth/userSubscriptions", data);
      toast.success(formatMessage({ id: "couponApplied" }));
      window.location = response.data.redirect_url;
    } catch (error) {
      if (error.response) {
        toast.error(`Error: ${error.response.data.message}`);
      } else if (error.request) {
        toast.error(formatMessage({ id: "networkError" }));
      } else {
        toast.error(error.message);
      }
    }
  };

  const onSubmit = () => {
    if (!code) {
      toast.error(formatMessage({ id: "couponRequired" }));
      return;
    }

    dispatch(
      sendCouponCode({ data: { code, subscription: singleSubscription.id } })
    );
  };

  return (
    <div className="card-body payment-method">
      <form className="card formTamar">
        <Row className="w-100">
          <Col xs="12">
            <div>
              <FormattedMessage id="firstName" />
            </div>
            <label>
              <input
                type="text"
                name="firstName"
                value={firstName}
                onChange={handleInputChange(setFirstName)}
                required
              />
            </label>
            {formErrors.firstName && (
              <div className="error">{formErrors.firstName}</div>
            )}
          </Col>
        </Row>

        <Row className="w-100">
          <Col xs="12">
            <div>
              <FormattedMessage id="lastName" />
            </div>
            <label>
              <input
                type="text"
                name="lastName"
                value={lastName}
                onChange={handleInputChange(setLastName)}
                required
              />
            </label>
            {formErrors.lastName && (
              <div className="error">{formErrors.lastName}</div>
            )}
          </Col>
        </Row>

        <Row className="w-100">
          <Col xs="12">
            <div>
              <FormattedMessage id="phoneNumber" />
            </div>
            <label>
              <input
                type="text"
                name="phoneNumber"
                value={phoneNumber}
                onChange={handleInputChange(setPhoneNumber)}
                required
              />
            </label>
            {formErrors.phoneNumber && (
              <div className="error">{formErrors.phoneNumber}</div>
            )}
          </Col>
        </Row>

        <Row className="w-100">
          <Col xs="12">
            <div>
              <FormattedMessage id="email" />
            </div>
            <label>
              <input
                type="email"
                name="email"
                value={email}
                onChange={handleInputChange(setEmail)}
                required
              />
            </label>
            {formErrors.email && (
              <div className="error">{formErrors.email}</div>
            )}
          </Col>
        </Row>

        <Row className="w-100 mt-2">
          <div>
            <FormattedMessage id="coupon" />
          </div>
          <label>
            <input
              type="text"
              name="couponCode"
              onChange={(e) => setCode(e.target.value)}
              disabled={coupon}
            />
          </label>
          <button
            style={{
              backgroundColor: coupon ? "#35a735" : "",
            }}
            disabled={couponLoading || coupon}
            onClick={onSubmit}
            type="button"
          >
            {couponLoading ? (
              "Loading..."
            ) : coupon ? (
              <FormattedMessage id="couponApplied" />
            ) : (
              <FormattedMessage id="applyCoupon" />
            )}
          </button>

          <button onClick={handleSubmit} type="button">
            <FormattedMessage id="pay" />
          </button>
        </Row>
      </form>
    </div>
  );
}
