import {
  GET_APPLE_PAY_MERCHANT_SESSION,
  GET_APPLE_PAY_MERCHANT_SESSION_FAILURE,
  GET_APPLE_PAY_MERCHANT_SESSION_SUCCESS,
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS_FAILURE,
  GET_PAYMENT_METHODS_SUCCESS,
  SEND_COUPON_CODE,
 SEND_COUPON_CODE_FAILURE,
 SEND_COUPON_CODE_SUCCESS,
  SEND_PAYMENT,
  SEND_PAYMENT_FAILURE,
  SEND_PAYMENT_SUCCESS,
} from "./actionTypes";

export const sendPayment = (payload) => {
  return {
    type: SEND_PAYMENT,
    payload: payload,
  };
};
export const sendPaymentSuccess = (payload) => {
  return {
    type: SEND_PAYMENT_SUCCESS,
    payload: payload,
  };
};

export const sendPaymentFailure = (error) => {
  return {
    type: SEND_PAYMENT_FAILURE,
    payload: error,
  };
};
//--------------------------------


export const sendCouponCode = (payload) => {
  return {
    type: SEND_COUPON_CODE,
    payload: payload,
  };
};
export const sendCouponCodeSuccess = (payload) => {
  return {
    type: SEND_COUPON_CODE_SUCCESS,
    payload: payload,
  };
};

export const sendCouponCodeFailure = (error) => {
  return {
    type: SEND_COUPON_CODE_FAILURE,
    payload: error,
  };
};




//--------------------------------

export const getPaymentMethods = (payload) => {
  return {
    type: GET_PAYMENT_METHODS,
    payload: payload,
  };
};
export const getPaymentMethodsSuccess = (payload) => {
  return {
    type: GET_PAYMENT_METHODS_SUCCESS,
    payload: payload,
  };
};

export const getPaymentMethodsFailure = (error) => {
  return {
    type: GET_PAYMENT_METHODS_FAILURE,
    payload: error,
  };
};
//--------------------------------

export const getApplePayMerchantSession = (payload) => {
  return {
    type: GET_APPLE_PAY_MERCHANT_SESSION,
    payload: payload,
  };
};
export const getApplePayMerchantSessionSuccess = (payload) => {
  return {
    type: GET_APPLE_PAY_MERCHANT_SESSION_SUCCESS,
    payload: payload,
  };
};

export const getApplePayMerchantSessionFailure = (error) => {
  return {
    type: GET_APPLE_PAY_MERCHANT_SESSION_FAILURE,
    payload: error,
  };
};
