import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { updatePasswordApi } from "api/users";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ResetPasswordForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showVerifyPassword, setShowVerifyPassword] = useState(false);
  const [activeInput, setActiveInput] = useState("");

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleShowVerifyPassword = () => {
    setShowVerifyPassword((prev) => !prev);
  };

  const { formatMessage } = useIntl();
  const { email } = useSelector((state) => state?.authentication);
  const navigate = useNavigate();

  useEffect(() => {
    if (!email) navigate("/login");
  }, [email]);

  // useEffect(() => {
  //   if (email) navigate("/login");
  // }, [email]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    // mode: "onBlur",
  });

  const onSubmit = async (data) => {
    try {
      let response = await await updatePasswordApi({
        ...data,
        email: email?.toLowerCase(),
      });
      if (response?.statusCode != 200) throw "";

      toast.success("تم ارسال البيانات ", {
        position: "bottom-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      navigate("/login");
      reset();
    } catch (error) {
      toast.error("يوجد خطأ فى إرسال البيانات ", {
        position: "bottom-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <form className="form_content" onSubmit={handleSubmit(onSubmit)}>
      {/* <div
            className="input_group"
            // data-aos="fade-up"
            // data-aos-delay="100"
            // data-aos-duration="500"
          >
            <label className="label required">
              <FormattedMessage id="otp" />
            </label>
            <input
              className={`input ${errors.otp && "error_input"}`}
              type="text"
              {...register("verificationCode", {
                required: <FormattedMessage id="required" />,
              })}
            />

            {errors.otp && (
              <span className="error"> {errors?.otp.message}</span>
            )}
          </div> */}
      <div
        className="input_group "
        // data-aos="fade-up"
        // data-aos-delay="100"
        // data-aos-duration="500"
      >
        <label
          className={`label required ${
            activeInput === "password" ? "active" : ""
          }`}
        >
          <FormattedMessage id="newPassword" />
        </label>
        <div className="position-relative password_input_wrapper">
          <input
            className={`input ${
              errors.password && "error_input"
            } rounded-3 password_input`}
            type={showPassword ? "text" : "password"}
            {...register("password", {
              required: <FormattedMessage id="required" />,
            })}
            onFocus={() => setActiveInput("password")}
            onBlur={() => setActiveInput("")}
          />
          <button
            className="show_password_btn"
            onClick={toggleShowPassword}
            type="button"
          >
            {!showPassword ? (
              <img src="/eye_password.svg" />
            ) : (
              <img src="/eye_password_active.svg" />
            )}
          </button>
        </div>

        {errors.password && (
          <span className="error"> {errors?.password.message}</span>
        )}
      </div>

      {/* <div
            className="input_group"
            // data-aos="fade-up"
            // data-aos-delay="100"
            // data-aos-duration="500"
          >
            <label className="label required">
              <FormattedMessage id="password" />
            </label>
            <input
              className={`input ${errors.password && "error_input"}`}
              type="password"
              {...register("newPassword", {
                required: <FormattedMessage id="required" />,
              })}
            />

            {errors.password && (
              <span className="error"> {errors?.password.message}</span>
            )}
          </div> */}
      <div
        className="input_group "
        // data-aos="fade-up"
        // data-aos-delay="100"
        // data-aos-duration="500"
      >
        <label
          className={`label required ${
            activeInput === "passwordAgain" ? "active" : ""
          }`}
        >
          <FormattedMessage id="passwordAgain" />
        </label>
        <div className="position-relative password_input_wrapper">
          <input
            className={`input ${
              errors.password && "error_input"
            } rounded-3 password_input`}
            type={showVerifyPassword ? "text" : "password"}
            {...register("verifyPassword", {
              required: <FormattedMessage id="required" />,
            })}
            onFocus={() => setActiveInput("passwordAgain")}
            onBlur={() => setActiveInput("")}
          />
          <button
            className="show_password_btn"
            onClick={toggleShowVerifyPassword}
            type="button"
          >
            {!showVerifyPassword ? (
              <img src="/eye_password.svg" />
            ) : (
              <img src="/eye_password_active.svg" />
            )}
          </button>
        </div>

        {errors.password && (
          <span className="error"> {errors?.password.message}</span>
        )}
      </div>

      <input
        type="submit"
        className="submit link_button "
        value={formatMessage({ id: "save" })}
      />
    </form>
  );
};

export default ResetPasswordForm;
