import axios from "axios";
import Cookies from "js-cookie";

const server = () => {
  const headers = {};
  const token = `${Cookies.get("dash-token")}`;

  if (token) headers.Authorization = token;
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers,
    // timeout: 25000, //25 seconds
  });
};

export default server;
