import FsLightbox from "fslightbox-react";
import { useEffect, useState } from "react";

export const QuestionTitleImgsNodes = ({ html }) => {
  const [questionTitleImages, setQuestionTitleImages] = useState([]);

  useEffect(() => {
    let parsed = new window.DOMParser().parseFromString(html, "text/html");
    let imgsSrcArr = [...parsed.querySelectorAll("img")].map((e) => e?.src);
    setQuestionTitleImages(imgsSrcArr?.map((e) => e));
  }, [html]);
  return questionTitleImages?.map((e, index) => (
    <QuestionTitleImg
      src={e}
      index={index}
      questionTitleImages={questionTitleImages}
    />
  ));
};
const QuestionTitleImg = ({ src, index, questionTitleImages }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <div className="image" onClick={() => setToggle(!toggle)}>
      <img src={src} alt="" />
      <FsLightbox
        toggler={toggle}
        sources={questionTitleImages}
        currentSlide={index}
      />
    </div>
  );
};
