import {
  ADD_SESSION,
  ADD_SESSION_FAILURE,
  ADD_SESSION_SUCCESS,
  DELETE_SESSION,
  DELETE_SESSION_FAILURE,
  DELETE_SESSION_SUCCESS,
  EDIT_SESSION,
  EDIT_SESSION_FAILURE,
  EDIT_SESSION_SUCCESS,
  GET_ALL_SESSIONS,
  GET_ALL_SESSIONS_FAILURE,
  GET_ALL_SESSIONS_SUCCESS,
  GET_ALL_ARCHIVED_SESSIONS,
  GET_ALL_ARCHIVED_SESSIONS_FAILURE,
  GET_ALL_ARCHIVED_SESSIONS_SUCCESS,
  GET_SESSION,
  GET_SESSION_FAILURE,
  GET_SESSION_SUCCESS,
  ARCHIVE_SESSION,
  ARCHIVE_SESSION_SUCCESS,
  GET_QUESTION,
  GET_QUESTION_SUCCESS,
  GET_QUESTION_FAILURE,
  ANSWER_QUESTION,
  ANSWER_QUESTION_SUCCESS,
  TERMINATE_ACTIVE_SESSION,
  ANSWER_QUESTION_FAILURE,
  ANSWER_QUESTIONS,
  ANSWER_QUESTIONS_SUCCESS,
  ANSWER_QUESTIONS_FAILURE,
  ADD_QUESTION_COMMENT,
  ADD_QUESTION_COMMENT_SUCCESS,
  ADD_QUESTION_COMMENT_FAILURE,
  SAVED_QUESTIONS,
  SAVED_QUESTIONS_SUCCESS,
  SAVED_QUESTIONS_FAILURE,
  GET_COMMENTED_ON_QUESTIONS,
  GET_COMMENTED_ON_QUESTIONS_SUCCESS,
  GET_COMMENTED_ON_QUESTIONS_FAILURE,
  ARCHIVE_SESSION_FAILURE,
  GET_TYPE_ON_QUESTIONS,
  GET_TYPE_ON_QUESTIONS_SUCCESS,
  GET_TYPE_ON_QUESTIONS_FAILURE,
} from "./actionTypes";

const initialState = {
  sessions: [],
  archivedSessions: [],
  loading: false,
  archivedLoading: false,
  questionLoading: false,
  savedLoading: false,
  error: "",
  singleSession: {},
  singleQuestion: {},
  activeSession: {},
  savedQuestions: [],
  commentedOnLoading: false,
  commentedOnQuestions: [],
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    // get all sessions
    case GET_ALL_SESSIONS:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_SESSIONS_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        sessions: action.payload?.sessions,
        metadata: action.payload?.metadata,
      };
      break;

    case GET_ALL_SESSIONS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;
    // get all archived sessions
    case GET_ALL_ARCHIVED_SESSIONS:
      state = {
        ...state,
        error: "",
        archivedLoading: true,
      };
      break;
    case GET_ALL_ARCHIVED_SESSIONS_SUCCESS:
      state = {
        ...state,
        error: "",
        archivedLoading: false,
        archivedSessions: action.payload.sessions,
      };
      break;

    case GET_ALL_ARCHIVED_SESSIONS_FAILURE:
      state = { ...state, error: action.payload, archivedLoading: false };
      break;

    // add question comment
    case ADD_QUESTION_COMMENT:
      state = {
        ...state,
        error: "",
        commentLoading: true,
      };
      break;

    case ADD_QUESTION_COMMENT_SUCCESS:
      let session = {},
        saved = [],
        commentedOn = [],
        sngleSession = [],
        changeName = "",
        changeName2 = "",
        changeName3 = "",
        changeName4 = "";

      if (
        Object.keys(state?.activeSession)?.length > 0 &&
        state.activeSession?.questions[action.payload.index] != undefined
      ) {
        session = state.activeSession;
        changeName = "activeSession";
        session.questions[action.payload.index].question.comments =
          action.payload?.data?.comments;
        session.discussAnswer = true;
      }
      if (
        state?.savedQuestions?.length > 0 &&
        state.savedQuestions[action.payload.index] !== undefined
      ) {
        saved = state.savedQuestions;
        changeName2 = "savedQuestions";
        saved[action.payload.index].question.comments =
          action.payload?.data?.comments;
      }
      if (
        state?.commentedOnQuestions?.length > 0 &&
        state.commentedOnQuestions[action.payload.index] != undefined
      ) {
        commentedOn = state.commentedOnQuestions;
        changeName3 = "commentedOnQuestions";
        commentedOn[action.payload.index].comments =
          action.payload?.data?.comments;
      }

      if (
        state?.singleSession?.questions?.length > 0 &&
        state.singleSession?.questions?.[action.payload.index] != undefined
      ) {
        sngleSession = state.singleSession;
        changeName4 = "singleSession";
        sngleSession.questions[action.payload.index].question.comments =
          action.payload?.data?.comments;
      }

      // if (!session?.questions?.length > 0) {
      //   state = {
      //     ...state,
      //     error: "",
      //     commentLoading: false,
      //   };
      //   break;
      // }

      state = {
        ...state,
        error: "",
        commentLoading: false,
        ...(changeName == "activeSession" && { activeSession: session }),
        ...(changeName2 == "savedQuestions" && {
          savedQuestions: saved,
        }),
        ...(changeName3 == "commentedOnQuestions" && {
          commentedOnQuestions: commentedOn,
        }),
        ...(changeName4 == "singleSession" && { singleSession: sngleSession }),
      };
      break;

    case ADD_QUESTION_COMMENT_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        // activeSession: {},
      };
      break;

    // add Session
    case ADD_SESSION:
      state = {
        ...state,
        error: '',
        loading: true,
        activeSession: {},
        savedQuestions: [],
      };
      break;

    case ADD_SESSION_SUCCESS:
      state = {
        ...state,
        error: '',
        loading: false,
        activeSession: action.payload.session,
      };
      break;

    case ADD_SESSION_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        activeSession: {},
      };
      break;

    case ARCHIVE_SESSION:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case ARCHIVE_SESSION_SUCCESS:
      const sessions = [
        ...state?.sessions?.filter(
          (type) => (type?._id || type?.id) !== action.payload
        ),
      ];
      state = {
        ...state,
        error: "",
        loading: false,
        sessions,
      };
      break;
    case ARCHIVE_SESSION_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      };
      break;

    // get Session
    case GET_SESSION:
      state = {
        ...state,
        error: "",
        loading: true,
        singleSession: {},
      };
      break;

    case GET_SESSION_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleSession: action.payload,
      };
      break;

    case GET_SESSION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    //get question
    case GET_QUESTION:
      state = {
        ...state,
        error: "",
        loading: true,
        singleQuestion: {},
      };
      break;
    case GET_QUESTION_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleQuestion: action.payload.question,
      };
      break;
    case GET_QUESTION_FAILURE:
      state = {
        ...state,
        error: "",
        loading: false,
        singleQuestion: {},
      };
      break;

    //answer question
    case ANSWER_QUESTION:
      state = {
        ...state,
        error: "",
        questionLoading: true,
        // loading: true,
      };
      break;
    case ANSWER_QUESTION_SUCCESS:
      state = {
        ...state,
        error: "",
        questionLoading: false,
        activeSession: action.payload.session,
        // activeSession: {},
      };
      break;
    case ANSWER_QUESTION_FAILURE:
      state = {
        ...state,
        error: action.payload,
        questionLoading: false,
      };
      break;
    case TERMINATE_ACTIVE_SESSION:
      state = {
        ...state,
        error: "",
        loading: false,
        activeSession: {},
      };
      break;

    //answer questions
    case ANSWER_QUESTIONS:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case ANSWER_QUESTIONS_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        activeSession: action.payload.session,
        // activeSession: {},
      };
      break;
    case ANSWER_QUESTIONS_FAILURE:
      state = {
        ...state,
        error: "",
        loading: false,
      };
      break;

    // edit Session
    case EDIT_SESSION:
      state = {
        ...state,
        error: "",
        loading: true,
        singleSession: {},
      };
      break;

    case EDIT_SESSION_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleSession: action.payload.session,
      };
      break;

    case EDIT_SESSION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // delete Session
    case DELETE_SESSION:
      state = {
        ...state,
        error: "",
        loading: true,
        singleSession: {},
      };
      break;

    case DELETE_SESSION_SUCCESS:
      const sessionsAfterDeleteing = [
        ...state?.sessions?.filter(
          (type) => (type?._id || type?.id) !== action.payload
        ),
      ];
      const archivedSessionsAfterDeleteing = [
        ...state?.archivedSessions?.filter(
          (type) => (type?._id || type?.id) !== action.payload
        ),
      ];
      state = {
        ...state,
        error: '',
        loading: false,
        sessions: sessionsAfterDeleteing,
        archivedSessions: archivedSessionsAfterDeleteing,
        
      };
      break;

    case DELETE_SESSION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // saved questions
    case SAVED_QUESTIONS:
      state = {
        ...state,
        error: "",
        savedLoading: true,
        savedQuestions: [],
      };
      break;

    case SAVED_QUESTIONS_SUCCESS:
      state = {
        ...state,
        error: "",
        savedLoading: false,
        savedQuestions: action.payload.pinQuestions,
      };
      break;

    case SAVED_QUESTIONS_FAILURE:
      state = {
        ...state,
        error: action.payload,
        savedLoading: false,
        savedQuestions: [],
      };
      break;
    // commented on questions
    case GET_COMMENTED_ON_QUESTIONS:
      state = {
        ...state,
        error: "",
        commentedOnLoading: true,
        commentedOnQuestions: [],
      };
      break;

    case GET_COMMENTED_ON_QUESTIONS_SUCCESS:
      state = {
        ...state,
        error: "",
        commentedOnLoading: false,
        commentedOnQuestions: action.payload.commentedQuestions,
      };
      break;

    case GET_COMMENTED_ON_QUESTIONS_FAILURE:
      state = {
        ...state,
        error: action.payload,
        commentedOnLoading: false,
        commentedOnQuestions: [],
      };
      break;
    // type questions
    case GET_TYPE_ON_QUESTIONS:
      state = {
        ...state,
        error: "",
        loading: true,
        commentedOnQuestions: [],
      };
      break;

    case GET_TYPE_ON_QUESTIONS_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        typeQuestions: action.payload.questionsResult,
      };
      break;

    case GET_TYPE_ON_QUESTIONS_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        typeQuestions: [],
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
