import Cookies from 'js-cookie';
import server from './server';

export const getAllSessionsApi = async (params) => {
  const response = await server().get(`/auth/sessions?${params}`);

  return response.data;
};

export const getAllArchivedSessionsApi = async () => {
  const response = await server().get('/auth/sessions/achivedSessions');

  return response.data;
};

export const addSessionApi = async (data) => {
  const response = await server().post('/auth/sessions', data);

  return response.data;
};

export const addQuestionCommentApi = async ({ data }) => {
  let imagesArr;
  try {
    imagesArr = await Promise.all(
      Array.from(data.files).map(async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        return await server().post('/upload', formData);
      })
    );
  } catch (error) {}

  let response = await server().post('/auth/questionComments/' + data?.id, {
    comment: data.comment,
    images: imagesArr.flatMap((f) => f?.data?.data?.files?.map((f) => f?.path)),
  });

  return response.data;
};

export const archiveSessionApi = async (id) => {
  const response = await server().post(`/auth/sessions/achiveSession/${id}`);

  return response.data;
};

export const getSessionApi = async (id) => {
  const response = await server().get(`/auth/sessions/${id}`);

  return response.data;
};

export const getQuestionApi = async (id) => {
  const response = await server().get(`/questions/${id}`);

  return response.data;
};

const customWait = async (dur) =>
  await new Promise((res) => setTimeout(() => res(), dur));

//question

const answerOrSkip = async (answer, ids) =>
  await server().post(
    `/auth/sessions/${
      answer?.length > 0 ? 'answerQuestion' : 'skipQuestion'
    }/${ids}`,
    { choisenAnswerIndex: answer }
  );

export const answerQuestionApi = async ({ question, sessionId }) => {
  let response = await answerOrSkip(
    question?.choisenAnswerIndex,
    `${sessionId}/${question?.id}`
  );

  return {
    data: { session: { ...response?.data?.data?.session, id: sessionId } },
  };
};

//questions
export const answerQuestionsApi = async ({ questions, sessionId }) => {
  // try {
  //   // await Promise?.all([
  //   //   ...questions.map(async (q, i) => {
  //   //     await customWait(10);
  //   //     return await answerOrSkip(
  //   //       q?.choisenAnswerIndex,
  //   //       `${sessionId}/${q?.id}`
  //   //     );
  //   //   }),
  //   // ]);

  //   for (let i = 0; i < questions.length; i++) {
  //     const q = questions[i];
  //     if (i % 50 == 0)
  //       await answerOrSkip(q?.choisenAnswerIndex, `${sessionId}/${q?.id}`);
  //     else answerOrSkip(q?.choisenAnswerIndex, `${sessionId}/${q?.id}`);
  //   }
  // } catch (error) {
  //   console.log(error);
  // }
  // await customWait(100);

  await server().post(`/auth/sessions/endSession/${sessionId}`);
  let response = await server().get(`/auth/sessions/${sessionId}`);

  return {
    data: { session: { ...response?.data?.data?.session, id: sessionId } },
  };
};

export const saveQuestionApi = async (id, session) => {
  const response = await server().post(`/auth/pinQuestions/`, {
    question: id,
    session,
  });

  return response.data;
};

export const getSavedQuestionsApi = async () => {
  const response = await server().get(`/auth/pinQuestions/`);
  return response.data;
};

export const getCommentedOnQuestionsApi = async () => {
  const response = await server().get(`/auth/pinQuestions/commentedOn`);

  return response.data;
};

export const getTypeQuestionsApi = async (payload) => {
  const response = await server().get(
    `/auth/sessions/questionsResult/${payload}`
  );

  return response.data;
};

export const removeSavedQuestionApi = async (id, session) => {
  const response = await server().delete(`/auth/pinQuestions/${id}/${session}`);

  return response.data;
};

export const removeSavedQuestionNosession = async (id) => {
  const response = await server().delete(`/auth/pinQuestions/${id}/`);
  return response.data;
};


export const editSessionApi = async ({ id, data }) => {
  const response = await server().put(`/auth/sessions/${id}`, data);
  return response.data;
};

export const deleteSessionApi = async (id) => {
  const response = await server().delete(`/auth/sessions/${id}`);

  return response.data;
};

export const serarchInSaved = async (data) => {
  const response = await server().get("/auth/pinQuestions/search", { params: data });
  return response.data;
};