// import {
//   getAllSessions,
//   getCommentedOnQuestions,
//   getSavedQuestions,
// } from "store/actions";
import { useEffect } from "react";
// import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";

const Layout = (props) => {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getAllSessions({ navigate }));
    // dispatch(getSavedQuestions({ navigate }));
    // dispatch(getCommentedOnQuestions({ navigate }));
  }, []);
  return (
    <div className="main-wrapper">
      <Header />
      <Sidebar />
      <div className="content-wrap" id="content-wrapper">
        {props.children}
        <Outlet />
      </div>
    </div>
  );
};
export default Layout;
