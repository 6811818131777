import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { ResendVerificationCode, VerifyEmail } from '../../api/verifyEmail';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginUser } from 'store/actions';
import { toast } from 'react-toastify';

const OtpInput = ({ numOfDigits, label, setShowOtpModal, data }) => {
  const { formatMessage } = useIntl();

  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const [otp, setOtp] = useState(new Array(numOfDigits).fill(''));
  const [otpError, setOtpError] = useState(null);
  const otpBoxRef = useRef([]);
  const navigate = useNavigate();
  const handleChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < numOfDigits - 1) {
      otpBoxRef.current[index + 1].focus();
    }
  };

  const handleBackSpace = (e, index) => {
    if (e.key === 'Backspace' && !e.target.value && index > 0) {
      otpBoxRef.current[index - 1].focus();
    } else if (e.key === 'Enter' && e.target.value && index < numOfDigits - 1) {
      otpBoxRef.current[index + 1].focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('data.email', data.email);

    const finalOtp = otp.join('');
    if (!otp.every((item) => item !== '')) {
      setOtpError(<FormattedMessage id={'pleaseEnterAllDigits'} />);
    } else {
      setOtpError(null);

      try {
        const user = await VerifyEmail({
          email: data.email,
          verificationCode: finalOtp,
        });

        setShowOtpModal(() => false);
        dispatch(
          loginUser({
            data,
            navigate,
            subscription: searchParams?.get('sub') || '',
          })
        );
        navigate('/');
      } catch (err) {
        toast(formatMessage({ id: err.response.data.message }), {
          toastId: err.response.data.message,
        });
        console.log('err', err.response.data.message);
      }
    }
  };

  console.log('data', data);

  const resendCode = async () => {
    try {
      const user = await ResendVerificationCode({
        type: 'email',
        email: data.email,
      });
      toast(formatMessage({ id: 'resendCodeSuccess' }), {
        toastId: 'resendCodeSuccess',
      });
    } catch (err) {}
  };

  useEffect(() => {
    otpBoxRef.current[0].focus();
  }, []);

  return (
    <div className='otp_form__'>
      <form onSubmit={handleSubmit}>
        <div>
          {label && <label className='d-block text-center mb-4'>{label}</label>}
          <div className='otp_input_group__' dir='ltr'>
            {otp.map((digit, index) => (
              <input
                key={index}
                className='otp_input__'
                ref={(input) => (otpBoxRef.current[index] = input)}
                maxLength='1'
                value={digit}
                onChange={(e) => handleChange(e.target.value, index)}
                onKeyDown={(e) => handleBackSpace(e, index)}
              />
            ))}
          </div>
        </div>
        {otpError && <p className='error_msg__'>{otpError}</p>}

        <Button
          variant='link'
          className='back__ d-block mx-auto mt-3'
          onClick={resendCode}
          type='button'
        >
          <FormattedMessage id='resendCode' />
        </Button>

        <Button
          variant='primary'
          className='primary_button__ send_btn__'
          type='submit'
        >
          <FormattedMessage id='send' />
        </Button>
      </form>
    </div>
  );
};

export default OtpInput;
