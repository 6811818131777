import React from 'react';
import all from '../../assets/images/test-results/allQues.png';
import wrongIcon from '../../assets/images/test-results/wrong.png';
import rightIcon from '../../assets/images/test-results/correct.png';
import passedIcon from '../../assets/images/test-results/skip.png';
import savedIcon from '../../assets/images/test-results/save.svg';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
// import Quesion from "./Quesion";
// import QuesionWithTwoCols from "./QuesionWithTwoCols";
// import DeomQuestions from "./DeomQuestions";
import { Col, Row } from 'react-bootstrap';
// import MainHeading from "../shared/MainHeading";
import ResultChart from './ResultChart';
import QuestionComponent from './QuestionComponent';
// import { IoIosArrowDown } from "react-icons/io";
// import QuestionExplanation from "./QuestionExplanation";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { terminateActiveSession } from 'store/actions';
import ResultQuestionComponent from './ResultQuestionComponent';
import ResultQuestion from './ResultQuestion';
import { FaFileAlt } from 'react-icons/fa';
import { BsFillSkipForwardFill } from 'react-icons/bs';
import { IoClose } from 'react-icons/io5';
import { FaCheck } from 'react-icons/fa6';
import { RiSaveFill } from "react-icons/ri";
import { CgMenuGridR } from 'react-icons/cg';


const FinalResutls = ({ userSession, setTest }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState('all');
  const [isActive, setIsActive] = useState(false);

  const handleRetest = () => {
    dispatch(terminateActiveSession());
    navigate('/sessions/add');
  };

  const handleTestAnswer = (currentIndex) => {
    const questions = userSession?.questions;
    questions[currentIndex].showAnswerTest = !questions[currentIndex].showAnswerTest;

    setTest((prevQuesions) => {
      return {
        ...prevQuesions,
        questions: questions,
      };
    });
  };

  const filterCheck = (q) => {
    if (filter === 'all') {
      return true;
    }
    if (filter === 'inCorrect') {
      return q.choisenAnswerIndex.length && q.isCorrect === false;
    }
    if (filter === 'isCorrect') {
      return q.choisenAnswerIndex.length && q.isCorrect === true;
    }
    if (filter === 'isSkipped') {
      return q.isSkipped === true;
    }
    if (filter === 'isSaved') {
      return q.isSaved === true;
    }
  };

  const handleShowAns = (type) => {
    setFilter(type);
    setIsActive(true);
  };

  const renderQuestions = userSession?.questions?.map((item, index) => {
    if (!filterCheck(item)) return null;
  
    const { question, isCorrect, isSkipped, isSaved } = item;
    let questionClass = 'question'; 
    if (isCorrect) questionClass += ' correct';
    if (isSkipped) questionClass += ' skipped';
    if (isSaved) questionClass += ' saved';
    if (!isCorrect && !isSkipped) questionClass += ' incorrect'; 
  
    return (
      <div className={questionClass} key={index}>
      <ResultQuestion
        currentIndex={index}
        currentQuesion={question}
      />
    </div>
    );
  });

  const savedQuestions = userSession?.questions.filter((q) => q.isSaved);

  return (
    <div
      className='final_results__ form_content__'
      data-aos='fade-in'
      data-aos-duration='1500'
    >
      <div className="testt">
        <div className="image">
          <FaFileAlt />
        </div>
        <h2 className='title__'>
          <FormattedMessage id='testResults' />
        </h2>
      </div>

      <ResultChart userSession={userSession} />
      <div className='result_info mt-5 pt-2'>
        <div className='gridresult_info mt-5'>
          <div
            className={`final_result_block__ final-card ${filter === 'all' ? 'active' : ''}`}
            onClick={() => handleShowAns('all')}
          >
            <div className='image'>
              <FaFileAlt />
            </div>
            <h3 className='head__'>
              <FormattedMessage id='finalResult' />
            </h3>
            <span className='number__'>({userSession?.questions?.length})</span>
            <p><FormattedMessage id='show' /></p>

          </div>
          <div
            className={`final_result_block__ red-card ${filter === 'inCorrect' ? 'active' : ''}`}
            onClick={() => handleShowAns('inCorrect')}
          >
            <div className='image'>
              <IoClose />
            </div>
            <h3 className='head__'>
              <FormattedMessage id='wrongAnswers' />
            </h3>
            <span className='number__'>({userSession?.inCorrectAnswers})</span>
            <p><FormattedMessage id='show' /></p>
            </div>
          <div
            className={`final_result_block__ sky-card ${filter === 'isCorrect' ? 'active' : ''}`}
            onClick={() => handleShowAns('isCorrect')}
          >
            <div className='image'>
              <FaCheck />
            </div>
            <h3 className='head__'>
              <FormattedMessage id='rightAnswers' />
            </h3>
            <span className='number__'>({userSession?.correctAnswers})</span>
            <p><FormattedMessage id='show' /></p>
            </div>
          <div
            className={`final_result_block__ grey-card ${filter === 'isSkipped' ? 'active' : ''}`}
            onClick={() => handleShowAns('isSkipped')}
          >
            <div className='image'>
              <BsFillSkipForwardFill />
            </div>
            <h3 className='head__'>
              <FormattedMessage id='passedQuesions' />
            </h3>
            <span className='number__'>
              (
              {userSession?.numberOfQuestions -
                (userSession?.correctAnswers + userSession?.inCorrectAnswers) || '0'}
              )
            </span>
            <p><FormattedMessage id='show' /></p>
            </div>
          <div
            className={`final_result_block__ save-card ${filter === 'isSaved' ? 'active' : ''}`}
            onClick={() => handleShowAns('isSaved')}
          >
            <div className='image'>
              <RiSaveFill />
            </div>
            <h3 className='head__'>
              <FormattedMessage id='savedQuestions' />
            </h3>
            <span className='number__'>{savedQuestions.length || 0}</span>
            <p><FormattedMessage id='show' /></p>
            </div>
        </div>
      </div>

      <div className='final_results_btton__'>
        <button className='link_button__ fill mx-auto' onClick={handleRetest}>
          <FormattedMessage id='repeateTest' />
        </button>
      </div>
       <div className={`state_anser  `}>
      <div>{renderQuestions}</div>
</div>
      {/* <div className={`control ${isActive ? "active" : ""}`}>
        <div className="heading d-flex align-items-center justify-content-between gap-3">
          <h3>
            <FormattedMessage id="questions" />
          </h3>
          <a className="btn_transparent IoClose" onClick={() => setIsActive(false)}>
            <IoClose />
          </a>
        </div>
        <div className='final_cp tab-content-wrapper'>{renderQuestions}</div>
       
      </div> */}
    </div>
  );
};



export default FinalResutls;
