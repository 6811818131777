import { FormattedMessage } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";
import { handleSearchParamsChange } from "../../helpers/functions";

const BootyPagination = ({ metaData }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  let { totalResults, pagination } = metaData || {};
  let { limit, page } = pagination || { page: 1, limit: 10 };

  page = parseInt(page) || 1;

  const onChangePage = (clickedPage, limit) => {
    const search = Object.fromEntries([...searchParams]);

    const params = {};
    if (clickedPage) params["page"] = clickedPage;
    if (limit) params["limit"] = limit;

    const newSearch = handleSearchParamsChange(search, params);



    navigate(window.location.pathname + newSearch, { replace: true });
  };

  const handleBackButtonClick = () => {
    onChangePage(page - 1);
  };

  const handleNextButtonClick = () => {
    onChangePage(page + 1);
  };

  const handlePageNumber = (e) => {
    onChangePage(Number(e.target.value));
  };

  const pages = getNumberOfPages(totalResults, limit);
  const pageItems = toPages(pages);
  const nextDisabled = page === pageItems.length;
  const previosDisabled = page === 1;

  function getNumberOfPages(totalResults, limit) {
    const numberOfPages = Math.ceil(Number(totalResults / limit));
    return numberOfPages;
  }

  function toPages(pages) {
    const results = [];

    for (let i = 0; i < pages; i++) {
      results.push(i + 1);
    }

    return results;
  }

  const getSelectedLimit = () => {
    const url = new URLSearchParams(searchParams);
    const limit = url.get("limit");
    return limit;
  };

  return (
    <div className="d-flex justify-content-end align-items-center gap-2   ">
      <nav className="table-pager">
        <ul className="pagination">
          <li className="page-item">
            <button
              className="page-link prev-link"
              onClick={handleBackButtonClick}
              disabled={previosDisabled}
              aria-disabled={previosDisabled}
              aria-label="previous page"
            >
              <FormattedMessage id={"previous"} />
            </button>
          </li>
          {pageItems.map((pageItem) => {
            const className =
              pageItem === parseInt(page) ? "page-item active" : "page-item";

            return (
              <li key={pageItem} className={className}>
                <button
                  className="page-link"
                  onClick={handlePageNumber}
                  value={pageItem}
                >
                  {pageItem}
                </button>
              </li>
            );
          })}
          <li className="page-item">
            <button
              className="page-link nxt-link"
              onClick={handleNextButtonClick}
              disabled={nextDisabled}
              aria-disabled={nextDisabled}
              aria-label="next page"
            >
              <FormattedMessage id={"next"} />
            </button>
          </li>
        </ul>
      </nav>
      <div className="table-select">
        <select onChange={(e) => onChangePage("", e.target.value)} value={getSelectedLimit() || 10} >
          <option >
            5
          </option>
          <option

            value={10}
          >
            10
          </option>
          <option >
            15
          </option>

          <option >
            20
          </option>

          <option >
            25
          </option>
        </select>
      </div>
    </div>
  );
};

export default BootyPagination;
