import { addQuestionComment } from "store/actions";
import { ReactComponent as SendIcon } from "assets/svgs/comment-send.svg";
import { ReactComponent as ImagesHolder } from "assets/svgs/images-placeholder.svg";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import CheckToDisableComponent from "components/shared/CheckToDisableComponent";

export default function ExamCommentInput({ question }) {
  const files = useRef(null);
  const comment = useRef(null);
  const dispatch = useDispatch();
  const onsubmit = (e) => {
    e.preventDefault();

    dispatch(
      addQuestionComment({
        data: {
          files: files?.current?.files,
          id: question?.id,
          comment: comment?.current?.value || " ",
        },
        questionIndex: question?.index,
        discussAnswer: true,
      })
    );
    if (comment?.current?.value) comment.current.value = "";
    if (files?.current?.files) files.current.value = "";
  };

  return (
    <div className="comment-input-wrapper">
      <CheckToDisableComponent>
        <div className="form">
          <textarea ref={comment} type="text" name="comment" />
          <label className="file cursor-pointer">
            <ImagesHolder />
            <input
              ref={files}
              name="files"
              type="file"
              multiple
              onChange={onsubmit}
            />
          </label>
          <a onClick={onsubmit}>
            <SendIcon />
          </a>
        </div>
      </CheckToDisableComponent>
    </div>
  );
}
