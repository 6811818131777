import {
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS_FAILURE,
  GET_PAYMENT_METHODS_SUCCESS,
  SEND_PAYMENT,
  SEND_PAYMENT_FAILURE,
  SEND_PAYMENT_SUCCESS,
  SEND_COUPON_CODE,
  SEND_COUPON_CODE_FAILURE,
  SEND_COUPON_CODE_SUCCESS,
} from "./actionTypes";

const initialState = {
  transaction: {},
  redirectUrl: "",
  paymentMethods: [],
  loading: false,
  error: "",
  coupon: "",
  couponLoading: false,
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    // send Coupon Code

    case SEND_COUPON_CODE:
      state = {
        ...state,
        error: "",
        couponLoading: true,
      };
      break;
    case SEND_COUPON_CODE_SUCCESS:
      state = {
        ...state,
        couponLoading: false,
        error: "",
        coupon: action.payload.coupon,
      };
      break;
    case SEND_COUPON_CODE_FAILURE:
      state = {
        ...state,
        couponLoading: false,
        error: action.payload,
      };
      break;

    // send payment request
    case SEND_PAYMENT:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case SEND_PAYMENT_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        transaction: action.payload.transaction,
        redirectUrl: action.payload.redirect_url,
      };
      break;

    case SEND_PAYMENT_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;
    // get payment methods array
    case GET_PAYMENT_METHODS:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;

    case GET_PAYMENT_METHODS_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        paymentMethods: action.payload.paymentMethodes,
      };
      break;

    case GET_PAYMENT_METHODS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
