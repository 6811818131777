import React, { useMemo, useState, forwardRef } from 'react';
import DataTable from 'react-data-table-component';
import { Badge, Col, Dropdown } from 'react-bootstrap';
import Filter from 'components/shared/Filter';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Loader from 'components/shared/Loader';
import { FormattedMessage, useIntl } from 'react-intl';
import { deleteSubscription } from 'store/actions';
import { handleIsoDate } from 'helpers/functions';

const Table = (props) => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  let navigate = useNavigate();
 
  // Determine the link based on the locale
  const link = locale === 'ar' 
    ? process.env.REACT_APP_EXAMIST 
    : process.env.REACT_APP_EXAMISTEN;

  const BootyCheckbox = new forwardRef(({ onClick, ...rest }, ref) => (
    <div className='form-check'>
      <input
        htmlFor='booty-check'
        type='checkbox'
        className='form-check-input'
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className='form-check-label' id='booty-check' />
    </div>
  ));

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props?.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleDelete = (id) => {
    dispatch(deleteSubscription(id));
  };

  const handleShowSub = (id) => {
    navigate(`/subscriptions/${id}`);
  };

  const columns = [
    {
      name: <FormattedMessage id={'plan'} />,
      selector: (row) => {
        const monthsMessage = row?.subscription?.plan > 1 ? 'months' : 'month',
          content = `${
            row?.plan || row?.subscription?.plan || '0'
          } ${formatMessage({
            id: monthsMessage,
          })}`;
        return (
          <Link to={`/subscriptions/${row?.id}`}>
            {(row?.plan !== row?.subscription?.plan ||
              row?.price !== row?.subscription?.price) && (
              <Badge bg='warning' className='p-1 mx-1'>
                {' '}
              </Badge>
            )}
            {content}
          </Link>
        );
      },
      sortable: true,
    },
    {
      name: <FormattedMessage id={'price'} />,
      selector: (row) =>
        `${row?.price || row?.subscription?.price || '0'} ${formatMessage({
          id: 'SAR',
        })}`,
      sortable: true,
    },
    {
      name: <FormattedMessage id={'startAt'} />,
      selector: (row) => handleIsoDate(row?.startAt),
      sortable: true,
    },
    {
      name: <FormattedMessage id={'endAt'} />,
      // selector: (row) => new Date(row?.endAt).toLocaleString(locale),
      selector: (row) => handleIsoDate(row?.endAt),
      sortable: true,
    },
    {
      name: <FormattedMessage id={'status'} />,
      selector: (row) => {
        return (
          <span
            className={`badge rounded-pill ${
              row?.status == 'active' ? 'bg-success' : 'bg-danger'
            }`}
          >
            {formatMessage({
              id: row?.status || 'notFound',
            })}
          </span>
        );
      },
      sortable: true,
    },
    {
      cell: (row) => (
        <>
          {
            <div className='global-dropdown'>
              <Dropdown>
                <Dropdown.Toggle>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    fill='currentColor'
                    className='bi bi-three-dots-vertical'
                    viewBox='0 0 16 16'
                  >
                    <path d='M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z' />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {
                    <Dropdown.Item
                      onClick={(e) => handleShowSub(row?.id)}
                      // to={`/subscriptions/${row?.id}`}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='#fff'
                        className='bi bi-eye-fill show'
                        viewBox='0 0 16 16'
                      >
                        <path d='M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z' />
                        <path d='M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z' />
                      </svg>
                      <FormattedMessage id={'view'} />
                    </Dropdown.Item>
                  }
                </Dropdown.Menu>
              </Dropdown>
            </div>
          }
        </>
      ),
      allowOverflow: true,
      button: true,
      width: '56px',
    },
  ];

  return (
    <>
      <div className='table-wrap'>
        <div className=' '>
          <Col xl={6} md={12} xs={12}>
            <div className='card-head'>
              <div>
                <h4>
                  <FormattedMessage id={'subscriptionsTableShow'} />{' '}
                </h4>
                <p>
                  {' '}
                  <FormattedMessage id={'subscriptionsTableShowAll'} />
                </p>
              </div>
            </div>
          </Col>
          <div className='card-body'>
            <DataTable
              columns={columns}
              data={filteredItems}
              progressPending={props.loading}
              progressComponent={<Loader />}
              defaultSortField='name'
              selectableRows
              subHeader
              className='DataTableSub'
              subHeaderComponent={subHeaderComponent}
              selectableRowsComponent={BootyCheckbox}
            />
            {(!filteredItems?.length > 0 ||
              !props?.data?.find((sub) => sub?.status == 'active')) && (
              <a
                href={`${link}subscriptions`}
                className='w-100 text-center py-4'
              >
                <FormattedMessage id='pick-subscription' />
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
