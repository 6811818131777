import server from "./server";

export const getAllSubscriptionsApi = async () => {
  const response = await server().get("/auth/userSubscriptions");
  return response.data;
};

export const addSubscriptionApi = async (data) => {
  const response = await server().post("/userSubscriptions", data);

  return response.data;
};



export const getUserSubscriptionApi = async (id) => {
  const response = await server().get(`/auth/userSubscriptions/${id}`);

  return response.data;
};

export const getSubscriptionApi = async (id) => {
  const response = await server().get(`/subscriptions/${id}`);

  return response.data;
};
export const editSubscriptionApi = async ({ id, data }) => {
  const response = await server().put(`/subscriptions/${id}`, data);

  return response.data;
};

export const deleteSubscriptionApi = async (id) => {
  const response = await server().delete(`/auth/userSubscriptions/${id}`);

  return response.data;
};
