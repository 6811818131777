import Modal from "react-bootstrap/Modal";
import { FormattedMessage } from "react-intl";

import { obfuscateEmail } from "../../helpers/functions";
import OtpInput from "./OtpInput";

const OtpModal = ({ show, setShow, data }) => {
  const handleClose = () => setShow(false);

  return (
    <>
      <Modal className='otp_modal__' show={show} onHide={handleClose}>
        <Modal.Body>
          <div className='body_content__'>
            <img src='/otp.png' alt='OTP' className='success_register_img__' />
            <h4 className='head__'>
              <FormattedMessage id='verificationCode' />
            </h4>

            <p className='parg__ '>
              <FormattedMessage id='enterVerifcationCodeRelatedTo' />
              <span className='email__ d-block'>
                {obfuscateEmail(data.emailProp)}
              </span>
            </p>

            <OtpInput
              numOfDigits={4}
              label={<FormattedMessage id='enterVerifcationCode' />}
              setShowOtpModal={setShow}
              data={data}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <p className='rights_parg__'>
            <FormattedMessage id='AllRightsReserved' />
          </p>
          <img src='/logo.png' alt='Logo' />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OtpModal;
