import { toast } from "react-toastify"; 
import Arabic from "lang/ar.json";
import English from "lang/en.json";
import Cookies from "js-cookie"; 

export const toastErrorMessage = (message) => {
  let locale = Cookies.get('site-lang') || 'en';

  if (!message?.includes("card scheme") && !message?.includes("Card Scheme")) {
    toast.error(
      <p dir="auto">
        {(locale === "ar" ? Arabic?.[message] : English?.[message]) || message}
      </p>,
      {
        position: locale === "ar" ? "bottom-left" : "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }
};
