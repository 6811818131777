import {
  GET_ALL_CATEGORIES,
  GET_ALL_CATEGORIES_FAILURE,
  GET_ALL_CATEGORIES_SUCCESS,
} from "./actionTypes";

export const getAllCategories = (payload) => {
  return {
    type: GET_ALL_CATEGORIES,
    payload: payload,
  };
};
export const getAllCategoriesSuccess = (payload) => {
  return {
    type: GET_ALL_CATEGORIES_SUCCESS,
    payload: payload,
  };
};

export const getAllCategoriesFailure = (error) => {
  return {
    type: GET_ALL_CATEGORIES_FAILURE,
    payload: error,
  };
};
