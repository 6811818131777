import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { FormattedMessage, useIntl } from 'react-intl';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
// import { postUserLogin } from "../../api/register";
import { loginUser } from '../store/authentication/actions';
import Loading from '../components/shared/Loader';
import icon from 'assets/svgs/login.svg';
import { getSubscriptionSuccess } from 'store/actions';
import OtpModal from 'components/login/OtpModal';
import { checkUserVerify } from 'api/users';
//  import imgg from "/src/assets/images/imgg.png";
// import img from "/src/assets/images/log.png";
// import line from "/src/assets/images/Line.png";
import styles from "./logincss/page.module.css"
import { FaHome } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { TiLockClosed } from "react-icons/ti";
import Cookies from 'js-cookie';

// import { useRouter } from "next/router";


const Login = ({ cols = 4, logo }) => {
  const dispatch = useDispatch();
  const { loading, error, user } = useSelector((state) => state.authentication);
  const { singleSubscription } = useSelector((state) => state?.subscriptions);

  const [showPassword, setShowPassword] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [activeInput, setActiveInput] = useState('');

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const { formatMessage, locale } = useIntl();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    toast.dismiss();
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
  } = useForm();

  useEffect(() => {
    document.querySelector('body').classList.remove('dark');
  }, []);



  const checkUser = async (userData) => {
    try {
      const { data } = await checkUserVerify(userData);
      return data.user;
    } catch (error) {
      throw error;
    }
  };

  const onSubmit = async (data) => {
    try {
      data.email = data.email.toLowerCase();
      const user = await checkUser(data);

      if (!user.isVerified) {
        setShowOtpModal(() => true);
      } else {
        dispatch(
          loginUser({
            data,
            navigate,
            subscription: searchParams?.get('sub') || '',
          })
        );
      }
    } catch (err) {
      toast(formatMessage({ id: err.response.data.message }), {
        toastId: err.response.data.message,
      });
    }
  };

  if (loading) return <Loading />;

const backHomeLa = locale === "ar" ? 'https://examist.sa/' : 'https://examist.sa/en' 
  console.log(locale, "locale")
  return (
    <>
      <OtpModal
        show={showOtpModal}
        setShow={setShowOtpModal}
        data={{ email: getValues('email'), password: getValues('password') }}
      />

      <div className='register_page'>
        <div className='register_page_wrapper'>
          <div className='form_contetn'>
            
            <a href={backHomeLa} className={styles.btnBack}>
              <FormattedMessage id='backHome' />
            </a>
            <p className='parg'>
              <span></span>
              <FormattedMessage id='loginWithEmailAndPassword' />
            </p>
            <h3 className='head'>
              <FormattedMessage id='login' />
            </h3>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Row className='login_form_row'>
                <Col sm='12' md='12' className=''>
                  <div className='input_group'>
                    <label className={`label required ${activeInput === 'email' ? 'active' : ''}`}>
                      <FormattedMessage id='email' />
                    </label>
                    <input
                      style={{ direction: 'ltr' }}
                      className={`input ${errors.email && 'error_input'} rounded-3`}
                      type='email'
                      {...register('email', {
                        required: <FormattedMessage id='required' />,
                      })}
                      onFocus={() => setActiveInput('email')}
                      onBlur={() => setActiveInput('')}
                    />
                    {errors.email && (
                      <span className='error'>{errors?.email.message}</span>
                    )}
                  </div>
                </Col>

                <Col sm='12' md='12' className=''>
                  <div className='input_group'>
                    <label className={`label required ${activeInput === 'password' ? 'active' : ''}`}>
                      <FormattedMessage id='password' />
                    </label>
                    <div className='position-relative password_input_wrapper'>
                      <input
                        dir='ltr'
                        className={`input ${errors.password && 'error_input'} rounded-3 password_input`}
                        type={showPassword ? 'text' : 'password'}
                        {...register('password', {
                          required: <FormattedMessage id='required' />,
                        })}
                        onFocus={() => setActiveInput('password')}
                        onBlur={() => setActiveInput('')}
                      />
                      <button
                        className='show_password_btn'
                        onClick={toggleShowPassword}
                        type='button'
                      >
                        {!showPassword ? (
                          <img src='/eye_password.svg' alt='' />
                        ) : (
                          <img src='/eye_password_active.svg' alt='' />
                        )}
                      </button>
                    </div>
                    {errors.password && (
                      <span className='error'>{errors?.password.message}</span>
                    )}
                  </div>
                </Col>

                <Col lg={12}>
                  <div className='remember_and_forgot_password'>
                    <div className='remember_me_label'>
                      <Form.Check type='switch' defaultChecked />
                      <span className='text'>
                        <FormattedMessage id='rememberMe' />
                      </span>
                    </div>
                    <div className='forgot_password_block'>
                      <Link
                        to='/forgot-password'
                        className='forgot_password_link'
                      >
                        <FormattedMessage id='didYouForgotPassword' />
                      </Link>
                    </div>
                  </div>
                </Col>

                <Col lg='12' className='text-center'>
                  <input
                    type='submit'
                    className='submit w-100 link_button'
                    value={formatMessage({ id: 'enter' })}
                  />
                </Col>
              </Row>
            </form>

            <span className='new_page_register_link'>
              <FormattedMessage id='youDontHaveAccount' />
              <a
                href={
                  searchParams?.get('sub')
                    ? `https://examist.sa/${locale === "ar" ? "register" : "en/register"}?sub=${searchParams?.get('sub')}`
                    : `https://examist.sa/${locale === "ar" ? "register" : "en/register"}`
                }
                className='signup_link'
              >
                <FormattedMessage id='newAccount' />
              </a>

            </span>
          </div>

          <div className='img_side'>
            <img src='/log.png' alt='' className={styles.img_login} />
            <div className={styles.content}>
              <h2>
                <FormattedMessage id='sing_card' />
              </h2>
              <img src='/Line.png' alt='' className={styles.line} />
              <img src='/imgg.png' alt='' className={styles.imgg} />
              <h3>+500</h3>
              <p className='p'>
                <FormattedMessage id='Userplaform' />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};



export default Login;
