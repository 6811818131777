import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "react-bootstrap";
import Loader from "components/shared/Loader";
import PaymentOptions from "components/Payment/paymentOptions";
import { FormattedMessage, useIntl } from "react-intl";
import "styles/payment.css";
import VisaPayment from "components/Payment/VisaPayment";
import PackageDetails from "components/Payment/PaymentDetails";
import { getPaymentMethods, sendPayment } from "store/payment/actions";
import { toast } from "react-toastify";
import { toastErrorMessage } from "components/shared/toastErrorMessage";
import StcPayPayment from "components/Payment/StcPayPayment";
import visa from "assets/images/Visa_Logo.png";
import mastercard from "assets/images/mastercard-logo-mastercard-logo-png-vector-download-19.png";
import stcPay from "../../assets/images/stc-pay.png";
import CouponDiscount from "components/Payment/CouponDiscount";
import TamaraPay from "components/Payment/TamaraPay";
import BtnPayment from "components/Payment/BtnPayment";
import Cookies from "js-cookie";
var Moyasar, MoyasarLoaded;

const Payment = () => {
  const { formatMessage } = useIntl();
  const token = Cookies.get("dash-token");
  const { singleSubscription } = useSelector((state) => state?.subscriptions);

  const { loading, redirectUrl, paymentMethods, error } = useSelector(
    (state) => state?.payment || {}
  );
  const dispatch = useDispatch();
  const [paymentOption, setPaymentOption] = useState({
    id: "",
    name: "moyasar",
    type: "creditcard",
  });

  const [couponCode, setCouponCode] = useState();
  const [safariBrowser, setSafariBrowser] = useState(false);

  useEffect(() => {
    dispatch(getPaymentMethods());
    var browserName = (function (agent) {
      switch (true) {
        case agent.indexOf("edge") > -1:
        // return "MS Edge";
        case agent.indexOf("edg/") > -1:
        //  return "Edge ( chromium based)";
        case agent.indexOf("opr") > -1:
        //  && !!window.opr: return "Opera";
        case agent.indexOf("chrome") > -1:
        //  && !!window.chrome: return "Chrome";
        case agent.indexOf("trident") > -1:
        // return "MS IE";
        case agent.indexOf("firefox") > -1:
          //  return "Mozilla Firefox";
          return setSafariBrowser(false);

        case agent.indexOf("safari") > -1:
          return setSafariBrowser(true);
        default:
          return;
      }
    })(window.navigator.userAgent.toLowerCase());
  }, [window]);

  useEffect(() => {
    if (redirectUrl?.length > 0) document.location.href = redirectUrl;
  }, [redirectUrl]);

  useEffect(() => {
    if (typeof window.Moyasar == "undefined" && MoyasarLoaded == undefined) {
      const script = document.createElement("script");
      script.src = "https://cdn.moyasar.com/mpf/1.7.3/moyasar.js";
      script.async = true;
      document.body.appendChild(script);
      const script1 = document.createElement("script");
      script1.src = "https://polyfill.io/v3/polyfill.min.js?features=fetch";
      script1.async = true;
      document.body.appendChild(script1);
      MoyasarLoaded = true;
      const link = document.createElement("link");
      link.href = "https://cdn.moyasar.com/mpf/1.7.3/moyasar.css";
      link.rel = "stylesheet";
      // link.async = true;
      document.head.appendChild(link);
      MoyasarLoaded = true;
    }
    // }
  }, [paymentOption]);

  useEffect(() => {
    if (error)
      toastErrorMessage(
        error?.errors?.[0]?.msg?.message || error?.message || "sendError"
      );
  }, [error]);

  const onSubmit = (event) => {
    event.preventDefault();

    let formData = new FormData(event.target);
    let data = Object.fromEntries(formData);
    data.number = data?.number?.replace(/\s+/g, "") || "";

    console.log(data, "data");

    if (paymentOption.type === "creditcard") {
      dispatch(
        sendPayment({
          data: {
            ...data,
            type: paymentOption.type,
            month: data?.date?.split(" / ")?.[0],
            year: "20" + data?.date?.split(" / ")?.[1],
            token,
            subscription: singleSubscription?.id,
          },
        })
      );
    } else if (paymentOption.type === "stcpay") {
      dispatch(
        sendPayment({
          data: {
            ...data,
            couponCode,
            type: paymentOption.type,
          },
        })
      );
    }
  };

  const paymentOptionsCard = [
    {
      title: "credit-card",
      img: [visa, mastercard],
      type: "creditcard",
    },
  ];

  if (loading) return <Loader />;

  return (
    <div className="view-page payment-page">
      <div className="">
        {!safariBrowser && (
          <div className="safari-warn alert alert-warning bg-warning text-center text-black">
            <FormattedMessage id="safari-only" />
            <Button
              className="btn btn-outline-light rounded-pill px-3 ms-3 h-75 my-0 w-auto d-inline-flex align-items-center justify-content-center"
              onClick={() => {
                navigator.clipboard.writeText(document.location.href);
                toast(formatMessage({ id: "copied" }), {
                  toastId: "copied",
                });
              }}
            >
              <FormattedMessage id="copy-link" />
            </Button>
          </div>
        )}
        <div className="card-body ">
          <Col md={12}>
            <form onSubmit={onSubmit}>
              <Row>
                <Col sm={12} md={4} xl={4}>
                  <div>
                    {paymentOptionsCard?.map((item) => {
                      return (
                        <PaymentOptions
                          setPaymentOption={setPaymentOption}
                          paymentOption={paymentOption}
                          safariBrowser={safariBrowser}
                          paymentMethods={paymentMethods}
                          paymentOptionsCard={item}
                        />
                      );
                    })}
                  </div>
                </Col>

                <Col sm={12} md={4} xl={4}>
                  <div className="payment-info-row">
                    {paymentOption?.type === "creditcard" && (
                      <VisaPayment setCouponCode={setCouponCode} />
                    )}
                    {paymentOption?.type === "tamara" && (
                      <TamaraPay
                        setCouponCode={setCouponCode}
                        paymentMethods={paymentMethods}
                      />
                    )}
                    {paymentOption?.name === "apple" && (
                      <div className="payment-methods-options card">
                        <div className="payment-info-row">
                          <CouponDiscount />
                        </div>
                        {/* <BtnPayment  /> */}
                      </div>
                    )}

                    {/* {paymentOption?.type === "apple" && safariBrowser && (
                      <div className="apple-pay-form">
                         <FormattedMessage id="apple-pay-selected" />
                      </div>
                    )} */}
                  </div>
                </Col>

                <Col sm={12} md={4} xl={4}>
                  <PackageDetails
                    paymentOption={paymentOption}
                    paymentMethods={paymentMethods}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        </div>
      </div>
    </div>
  );
};

export default Payment;
