import BlocksTop from "components/Home/BlocksTop";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCommentedOnQuestions,
  getCurrentUserStatistics,
  getNotifications,
  getSavedQuestions,
} from "store/actions";
import Blocks from "components/Home/Blocks";
import Loader from "components/shared/Loader";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userStats, loading, error, currentUserLoading } = useSelector(
    (state) => state.authentication
  );
  const {
    savedQuestions,
    commentedOnQuestions,
    loading: sessionLoading,
  } = useSelector((state) => state.sessions);

  useEffect(() => {
    // if (!Object.entries(userStats)?.length > 0)
    dispatch(getCurrentUserStatistics());
    dispatch(getSavedQuestions({ navigate }));
    dispatch(getCommentedOnQuestions({ navigate }));
    dispatch(getNotifications());

    // dispatch(getSavedQuestions());
  }, [dispatch, navigate]);

  if (loading || sessionLoading || currentUserLoading) return <Loader />;
  return (
    <div>
      <BlocksTop />
 {/* <a href="http://localhost:3000/ar/payment/?sub=6377e25e5cf2f6b61d90709e">
fdfdfd
</a>   */}
      <Blocks
        savedQuestions={savedQuestions}
        commentedOnQuestions={commentedOnQuestions}
      />
    </div>
  );
};

export default Dashboard;
