import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { resetPasswordApi } from "api/users";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveTempEmail } from "store/actions";

const ForgotPasswordForm = () => {
  const { formatMessage } = useIntl();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activeInput, setActiveInput] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    // mode: "onBlur",
  });

  const onSubmit = async (data) => {
    try {
      // TODO
      // await addContacts(data);
      let response = await resetPasswordApi(data);
      if (response?.statusCode != 200) throw "";
      dispatch(saveTempEmail(data.email));

      toast.success(formatMessage({ id: "data-sent" }), {
        position: "bottom-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      navigate("/reset-password");

      reset();
    } catch (error) {
      toast.error(formatMessage({ id: "data-sending-failed" }), {
        position: "bottom-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <form className="form_content" onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col lg="12">
          <div
            className="input_group"
            // data-aos="fade-up"
            // data-aos-delay="100"
            // data-aos-duration="500"
          >
            <label
              className={`label required ${
                activeInput === "email" ? "active" : ""
              }`}
            >
              <FormattedMessage id="email" />
            </label>
            <input
              className={`input ${errors.email && "error_input"}`}
              type="text"
              {...register("email", {
                required: <FormattedMessage id="required" />,
              })}
              onFocus={() => setActiveInput("email")}
              onBlur={() => setActiveInput("")}
            />

            {errors.email && (
              <span className="error"> {errors?.email.message}</span>
            )}
          </div>
        </Col>

        <Col lg="12" className="text-center">
          <input
            type="submit"
            style={{ maxWidth: "170px", marginTop: "10px" }}
            className="submit link_button rounded-2 px-2 py-3"
            value={formatMessage({ id: "send" })}
          />
        </Col>
      </Row>
    </form>
  );
};

export default ForgotPasswordForm;
