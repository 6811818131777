import React from "react";
import { Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const BlockItem = ({
  title,
  count = "",
  date = false,
  element = false,
  className = "",
  Icon = false,
  type = false,
  url = "",
  breakPoints = {},
}) => {
  return (
    <>
      <Col xs={12} sm={6} md={4} xl="3" {...breakPoints}>
        <div className={`card ${className}`}>
          <div className="b-info">
            <h4>
              <FormattedMessage id={title} />
            </h4>
            {element !== false && element}
            {!!date && <h4>{date}</h4>}
            {!date && (
              <div className="d-flex align-items-center justify-content-between">
                <p className="align-items-center" title={count}>
                  {count}
                </p>
                {type && count > 0 && (
                  <Link
                    to={type != "page" ? `/sessions/type/${type}` : url}
                    className="btn btn-outline-light rounded-pill px-3 my-0 h-75 d-inline-flex align-items-center justify-content-center"
                  >
                    <FormattedMessage id="more" />
                  </Link>
                )}
              </div>
            )}
          </div>
          {Icon ? (
            <Icon width="40" fill="white" />
          ) : (
            <svg
              width="69"
              height="22"
              viewBox="0 0 69 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M66.8945 9.86943C66.1159 13.8603 63.0145 21.2878 55.8087 20.7335C48.6029 20.1793 47.7398 1.88763 35.1257 1.88763C22.5116 1.88763 22.5116 22.9824 1.48812 4.73828"
                stroke="white"
                strokeWidth="2.21717"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          )}
        </div>
      </Col>
    </>
  );
};

export default BlockItem;
