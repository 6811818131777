import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  ADD_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  EDIT_SUBSCRIPTION,
  GET_ALL_SUBSCRIPTIONS,
  GET_SUBSCRIPTION,
  GET_USER_SUBSCRIPTION,
} from "./actionTypes";
import {
  addSubscriptionApi,
  getAllSubscriptionsApi,
  getSubscriptionApi,
  getUserSubscriptionApi,
  editSubscriptionApi,
  deleteSubscriptionApi,
} from "api/subscriptions";
import {
  addSubscriptionFailure,
  addSubscriptionSuccess,
  deleteSubscriptionFailure,
  deleteSubscriptionSuccess,
  editSubscriptionFailure,
  editSubscriptionSuccess,
  getAllSubscriptionsFailure,
  getAllSubscriptionsSuccess,
  getUserSubscriptionFailure,
  getUserSubscriptionSuccess,
  getSubscriptionFailure,
  getSubscriptionSuccess,
} from "./actions";

function* getAllSubscriptions() {
  try {
    const { data } = yield call(getAllSubscriptionsApi);

    yield put(getAllSubscriptionsSuccess(data));
  } catch (error) {
    yield put(getAllSubscriptionsFailure(error.response.data || error));
  }
}

function* addSubscription({ payload }) {
  try {
    const { data } = yield call(addSubscriptionApi, payload.data);

    yield put(addSubscriptionSuccess(data));
    payload.navigate("/subscriptions");
  } catch (error) {
    yield put(addSubscriptionFailure(error.response.data || error));
  }
}

function* getUserSubscription({ payload }) {
  try {
    const { data } = yield call(getUserSubscriptionApi, payload);
    yield put(getUserSubscriptionSuccess(data));
  } catch (error) {
    yield put(getUserSubscriptionFailure(error.response.data || error));
  }
}
function* getSubscription({ payload }) {
  try {
    const { data } = yield call(getSubscriptionApi, payload);
    yield put(getSubscriptionSuccess(data));
  } catch (error) {
    yield put(getSubscriptionFailure(error.response.data || error));
  }
}

function* editSubscription({ payload }) {
  try {
    const { data } = yield call(editSubscriptionApi, {
      data: payload.data,
      id: payload.id,
    });
    yield put(editSubscriptionSuccess(data));
  } catch (error) {
    yield put(editSubscriptionFailure(error.response.data || error));
  }
}

function* deleteSubscription({ payload }) {
  try {
    yield call(deleteSubscriptionApi, payload);
    yield put(deleteSubscriptionSuccess(payload));
  } catch (error) {
    yield put(deleteSubscriptionFailure(error.response.data || error));
  }
}

export function* watchGetAllSubscriptions() {
  yield takeEvery(GET_ALL_SUBSCRIPTIONS, getAllSubscriptions);
}

export function* watchAddSubscription() {
  yield takeEvery(ADD_SUBSCRIPTION, addSubscription);
}

export function* watchUserGetSubscription() {
  yield takeEvery(GET_USER_SUBSCRIPTION, getUserSubscription);
}
export function* watchGetSubscription() {
  yield takeEvery(GET_SUBSCRIPTION, getSubscription);
}

export function* watchEditSubscription() {
  yield takeEvery(EDIT_SUBSCRIPTION, editSubscription);
}

export function* watchDeleteSubscription() {
  yield takeEvery(DELETE_SUBSCRIPTION, deleteSubscription);
}

function* subscriptionsSaga() {
  yield all([
    fork(watchGetAllSubscriptions),
    fork(watchAddSubscription),
    fork(watchUserGetSubscription),
    fork(watchGetSubscription),
    fork(watchEditSubscription),
    fork(watchDeleteSubscription),
  ]);
}

export default subscriptionsSaga;
