import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import LightBox from '../shared/LightBox';
import QuestionExplanation from './QuestionExplanation';
import { extractImagesSrcsNodes } from '../../helpers/functions';
import Modal from 'react-bootstrap/Modal';
import { IoClose } from 'react-icons/io5';

const ResultQuestion = ({ currentQuesion, currentIndex, className }) => {
  const [imagesSrcs, setImagesSrcs] = useState([]);
  const [discussQuestion, setDiscussQuestion] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const srcs = extractImagesSrcsNodes(currentQuesion?.title);
    setImagesSrcs(srcs);
  }, [currentQuesion]);

  const toggleActiveClass = () => {
    setIsActive((prevState) => !prevState);
  };

  const handleShowModal = () => {
    setDiscussQuestion(true);
    setShow(true);
  };

  return (
    <div
      className={`demo_question_content__ ${className}`}
      dir="auto"
      data-aos="fade-in"
      data-aos-duration="1500"
    >
      <div className={`question-data nonActive ${isActive ? 'active' : ''}`}>
        <div className="question_number__ mt-4" onClick={toggleActiveClass}>
          <FormattedMessage id="questionNumber" /> {currentIndex + 1 || currentQuesion?.title}
        </div>
        <div className="tailQuestuin">
          <div
            className="question_"
            dir="auto"
            dangerouslySetInnerHTML={{ __html: currentQuesion?.title }}
          />
          <LightBox imagesSrcs={imagesSrcs} />
          <button
            className="question_explantion_head__ d-block m-auto mb-4 text-left"
            onClick={handleShowModal}
            type="button"
          >
            explanation
            {/* <FormattedMessage id='explanation' /> */}

          </button>
          <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          >

            <Modal.Body>

              {discussQuestion && (
                  <div className="question_explantion_area__ question_comments_area__">
                  <a className="btn_transparent IoClose" onClick={() => setShow(false)}>
                    <IoClose />
                  </a>
                    <QuestionExplanation currentQuesion={currentQuesion} />
                  </div>
              )}
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default ResultQuestion;


