import { useCallback, useContext, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Col, Dropdown, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";

import userimg from "../../assets/images/user.png";
import placeholderImg from "../../assets/images/placeholder-image.jpg";
import logoIcon from "../../assets/images/logo-icon.svg";
import logoInfo from "../../assets/images/Logo.svg";
import { Context } from "../../wrapper";
import Arabic from "../../lang/ar.json";
import English from "../../lang/en.json";
import { getId, handleImage, sidebarToggleFunction } from "helpers/functions";
import { firebaseConfig } from "data";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Cookies from "js-cookie";

const Header = () => {
  const context = useContext(Context);
  const { locale } = context;
  const [zIndex, setZIndex] = useState("99");
  const { user, logo } = useSelector((state) => state.authentication);
  const navigate = useNavigate();  
  const location = useLocation();  

  const sidebarToggle = () => {
    const body = document.querySelector("body");
    const overlay = document.querySelector(".overlay-s");
    const html = document.querySelector("html");
    body.classList.toggle("sidebarToggled");
    overlay.classList.toggle("fire");
    html.classList.toggle("offScroll");
  };

  const handleChangeLangOnClick = (newLocale) => {
    context.setLocale({
      lang: newLocale,
      messages: newLocale === "ar" ? Arabic : English,
    });
  
    Cookies.set("site-lang", newLocale);
    document.body.setAttribute("dir", newLocale === "ar" ? "rtl" : "ltr");
    document.documentElement.lang = newLocale === "ar" ? "ar" : "en";
    document.querySelector("title").textContent =
      newLocale === "ar" ? "لوحة التحكم" : "Dashboard";
  
    const currentPath = location.pathname;
  

    if (currentPath.includes('/ar') || currentPath.includes('/en')) {
      const updatedPath = currentPath.replace(/\/(ar|en)/, `/${newLocale}`);
      navigate(updatedPath);
    } else {
      navigate(currentPath);  
    }
  };
  
  


  const sidebarStatus = () => {
    sidebarToggle();
  };

  return (
    <>
      <div className="main-head" style={{ zIndex }}>
        <div className="logo log0_exa">
          <div className="inner">
            <NavLink to="/" onClick={sidebarStatus}>
              <img src={logoInfo} alt="" className="logo-icon" />
              {/* <img src={logoInfo} alt="" className="logo-info" /> */}
            </NavLink>
          </div>
        </div>
        {/* <div className="logo">
          <div className="inner">
            <NavLink to="/">
              <div className="logo-icon">
                <img src={logo || ""} alt="" />
              </div>
            </NavLink>
          </div>
        </div> */}
        <div className="head-wrp">
          <div className="burgerBtn">
            <button className="toggleSidebar" onClick={sidebarToggle}>
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.75 1C15.75 0.585786 15.4142 0.25 15 0.25H6C5.58579 0.25 5.25 0.585786 5.25 1C5.25 1.41421 5.58579 1.75 6 1.75H15C15.4142 1.75 15.75 1.41421 15.75 1Z"
                  fill="#346BF4"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.75 13C15.75 12.5858 15.4142 12.25 15 12.25H9C8.58579 12.25 8.25 12.5858 8.25 13C8.25 13.4142 8.58579 13.75 9 13.75H15C15.4142 13.75 15.75 13.4142 15.75 13Z"
                  fill="#346BF4"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.75 7C15.75 6.58579 15.4142 6.25 15 6.25H1C0.585787 6.25 0.25 6.58579 0.25 7C0.25 7.41421 0.585787 7.75 1 7.75H15C15.4142 7.75 15.75 7.41421 15.75 7Z"
                  fill="#346BF4"
                />
              </svg>
            </button>
          </div>

          <div className="authWrap">
            <div className="menu-item-has-children lang">
              <span>
                {locale.lang === "ar" ? "العربية" : "English"}
              </span>
              <ul className="sub-menu">
                <li>
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeLangOnClick("ar");
                    }}
                  >
                    العربية
                  </a>
                </li>
                <li>
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeLangOnClick("en");
                    }}
                  >
                    English
                  </a>
                </li>
              </ul>
            </div>
            <Notifications
              locale={locale}
              setZIndex={setZIndex}
              zIndex={zIndex}
            />
            <ul>
              <li className="menu-item-has-children">
                <Dropdown>
                  <Dropdown.Toggle>
                    <div className="au-flow">
                      <div className="au-img">
                        <img
                          src={
                            user?.image && !user?.image?.includes("undefined")
                              ? handleImage(user?.image)
                              : userimg
                          }
                          alt=""
                        />
                      </div>
                      <div className="au-data">
                        <h4>{user?.fullName}</h4>
                      </div>
                    </div>
                    <svg
                      width="11"
                      height="7"
                      viewBox="0 0 11 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.14376 6.13396L1.06422 2.05441C0.867468 1.85766 0.867468 1.53867 1.06422 1.34194L1.54003 0.866126C1.73645 0.66971 2.05478 0.669332 2.25166 0.865287L5.5 4.09841L8.74832 0.865287C8.9452 0.669332 9.26353 0.66971 9.45995 0.866126L9.93576 1.34194C10.1325 1.53869 10.1325 1.85768 9.93576 2.05441L5.85625 6.13396C5.65949 6.3307 5.34051 6.3307 5.14376 6.13396Z"
                        fill="black"
                      />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <NavLink to="/account" className="dropdown-item">
                        <FormattedMessage id={"myAccount"} />
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink to="/logout" className="dropdown-item">
                        <FormattedMessage id={"logout"} />
                      </NavLink>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="overlay-s" onClick={sidebarToggle}></div>
    </>
  );
};

const Notifications = ({ locale, setZIndex, zIndex }) => {
  const { user } = useSelector((state) => state.authentication);

  // const [notifsIds, setNotifsIds] = useState([]);
  const [notifications, setNotifications] = useState([]);

  const unreadNotifs = notifications?.filter((e) => !e?.isRead);

  if (firebase.apps.length === 0) firebase.initializeApp(firebaseConfig);

  useEffect(() => {
    const db = firebase.firestore();

    const collectionRef = db.collection("notifications");

    if (!getId(user)) return;

    const filteredQuery = collectionRef.where("userId", "==", getId(user));

    let listener = filteredQuery.onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        let notifications = [];
        // Handle added document

        setNotifications((notifs) => {
          notifications = notifs;
          return notifs;
        });
        // if (change.type === "added" && notifications?.length > 0) {
        //   // let color = change.doc.data()?.body?.color;
        //   if (change.doc.data()?.body?.title?.[locale])
        //     toast.info(change.doc.data()?.body?.title?.[locale], {
        //       // toastId: getId(change.doc.data()?.body),
        //       // autoClose: false,
        //       position: "top-right",
        //       autoClose: 5000,
        //       hideProgressBar: false,
        //       closeOnClick: true,
        //       pauseOnHover: true,
        //       draggable: true,
        //       progress: undefined,
        //       theme: "colored",
        //       // style: {
        //       //   backgroundColor: color != "#000000" ? color : "#fff",
        //       // },
        //     });
        // }
        if (change.type === "modified") {
          // Handle modified document
          // console.log("Modified:", change.doc.data());
        }
        if (change.type === "removed") {
          // Handle removed document
          // console.log("Removed:", change.doc.data());
        }
      });
      setNotifications(
        snapshot?.docs
          ?.map((e) => ({ ...e?.data(), id: e?.id }))
          ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
          .reverse()
      );
    });

    return () => {
      listener();
      setNotifications([]);
    };
  }, [locale, user]);

  const readNotifs = useCallback(
    (open) => {
      if (open) return;
      const db = firebase.firestore();

      const collectionRef = db.collection("notifications");
      unreadNotifs?.map((notif) => {
        collectionRef.doc(getId(notif)).update({ isRead: true });
      });
    },
    [unreadNotifs]
  );

  const renderSlides = notifications?.map(
    ({ body: announcement, ...rest }, index) => (
      <div
        key={index}
        className={`b-info ${rest?.isRead ? "" : "alert"}`}
        style={{
          background: announcement?.color,
          borderRadius: "10px",
          padding: "10px",
        }}
        title={announcement?.description?.[locale]}
      >
        <div className="i-img">
          <img
            src={
              handleImage(announcement?.image)?.includes("undefined")
                ? placeholderImg
                : handleImage(announcement?.image)
            }
            alt=""
          />
        </div>

        <div className="i-data">
          <h4 title={announcement?.title?.[locale]}>
            {announcement?.title?.[locale]}
          </h4>
          <p>{announcement?.description?.[locale]}</p>
        </div>
      </div>
    )
  );

  // Create a real-time listener for document changes
  return (
    <Dropdown
      className=""
      onToggle={(ev) => {
        ev ? setZIndex("999") : setZIndex("99");
        readNotifs(ev);
      }}
    >
      <Dropdown.Toggle className="align-items-center">
        <div>
          {unreadNotifs?.length > 0 && (
            <div className="count">{unreadNotifs?.length}</div>
          )}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="white"
            className="bi bi-bell-fill position-relative"
            style={{ transform: "none" }}
            viewBox="0 0 16 16"
          >
            <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
          </svg>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="projects-blocks ">
        {/* <Dropdown.Item> */}
        <div className="close d-flex m-2 mb-0">
          <Dropdown.Toggle className="fs-3 m-0">X</Dropdown.Toggle>
        </div>
        <div className="card">
          {renderSlides}
          {notifications?.length === 0 && (
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <div className="b-info">
                <div className="i-data">
                  <h4>
                    <FormattedMessage id="noNotifications" />
                  </h4>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* </Dropdown.Item> */}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Header;
