import server from "./server";

export const VerifyEmail = async (data) => {
  const response = await server().post("/auth/verifyRegisterOTP", data);

  return response.data.data.user;
};

export const ResendVerificationCode = async (data) => {
  const response = await server().post("/auth/ResendVerificationCode", data);

  return response.data.data.user;
};
