import { useState } from "react";
import { useEffect } from "react";
import {
  FormattedDate,
  FormattedMessage,
  FormattedTime,
  useIntl,
} from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getTypeQuestions } from "store/actions";
import Icon from "assets/svgs/sessions.svg";
import Table from "./Table";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import ControlArea from "components/Layout/ControlArea";
import ExamResultComponent from "components/session/ExamResultComponent";
import { useParams } from "react-router-dom";
import Loader from "components/shared/Loader";
import "styles/sessions.css";
import { Col, Row } from "react-bootstrap";
import ResultQuestionComponent from "components/session/ResultQuestionComponent";

const TypeView = () => {
  const { typeQuestions, loading, error } = useSelector(
    (state) => state.sessions
  );
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  const [isDispatched, setIsDispatched] = useState(false);
  const { filter } = useParams();

  const [pageNum, setPageNum] = useState(1);

  const paginationsLimit = 37,
    paginationInnerNum = 6;

  useEffect(() => {
    setIsDispatched(true);
    // if(!sessions?.length>0)
    dispatch(getTypeQuestions(filter));
  }, [dispatch]);

  // error
  useEffect(() => {
    if (error && isDispatched) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error, formatMessage, locale, isDispatched]);

  let filterName = "notFound";
  if (filter === "inCorrect") {
    filterName = "inCorrectAnswers";
  }
  if (filter === "isCorrect") {
    filterName = "correctAnswers";
  }
  if (filter === "isSkipped") {
    filterName = "skippedAnswers";
  }

  console.log("typeQuestions" , typeQuestions)
  // const filterCheck = (q) => {
  //   // if (!filterFlag) return true;

  //   if (filter == "inCorrect") {
  //     return q.isCorrect == false;
  //   }
  //   if (filter == "isCorrect") {
  //     return q.isCorrect == true;
  //   }
  //   if (filter == "isSkipped") {
  //     return (
  //       (q.isSkipped == undefined && q.isCorrect == undefined) ||
  //       q.isSkipped == true
  //     );
  //   }
  //   return false;
  // };

  // const checkFilteredQuestions = (questions) =>
  //   questions?.some((q) => filterCheck(q));

  if (loading) return <Loader />;

  return (
    <>
      <div className="control-wrp">
        <Row>
          <Col>
            <div className="card">
              <h5>
                <img src={Icon} width={20} height={20} alt="" />
                <FormattedMessage id={"sessions"} />
                {" / "}
                <FormattedMessage id={filterName} />
              </h5>
            </div>
          </Col>
        </Row>
      </div>

      <div className="session-add">
        <div className="card">
          <div className="card-body "></div>
          <form className="session-exam">
            <div className="row tabs" id="accordionExample">
              {/* <div className="tabs"  id="accordionExample"> */}
              {(typeQuestions?.length > paginationsLimit
                ? typeQuestions?.slice(0, pageNum * paginationInnerNum)
                : typeQuestions
              )?.map((question, index) => (
                <ResultQuestionComponent
                  key={index}
                  questionsIndex={index}
                  question={{
                    ...question?.question,
                    ...question,
                    question: "",
                    index,
                  }}
                  page={"type"}
                />
              ))}
            </div>
          </form>
          {typeQuestions?.length > paginationsLimit &&
            typeQuestions?.length > pageNum * paginationInnerNum && (
              <button
                className="show-more-button"
                // className={`page-link page-item next-link`}
                onClick={(e) => {
                  e.preventDefault();
                  setPageNum(pageNum + 1);
                }}
              >
                <FormattedMessage id="show-more" />
              </button>
            )}
        </div>
      </div>
    </>
  );
};

export default TypeView;
