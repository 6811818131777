import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Router, useParams } from 'react-router-dom';
import { getSubscription } from 'store/actions';
import { toastErrorMessage } from 'components/shared/toastErrorMessage';
import { getId } from 'helpers/functions';
import { sendPayment } from 'store/payment/actions';

export default function PackageDetails({ paymentOption, paymentMethods }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [canUseApple, setCanUseApple] = useState(false);
  const { singleSubscription } = useSelector((state) => state?.subscriptions);
  const { coupon } = useSelector((state) => state?.payment);
  const { user } = useSelector((state) => state?.authentication);
  const [price, setPrice] = useState(0);

  // Calculate coupon discount
  let couponDiscount = (coupon?.amount / 100) * price || 0;
  const { locale } = useIntl();

  // Fetch subscription details if not already loaded
  useEffect(() => {
    if (singleSubscription?.id !== id) dispatch(getSubscription(id));
  }, [dispatch]);

  // Update price based on subscription, without applying coupon discount here
  useEffect(() => {
    let updatedPrice = singleSubscription?.hasDiscount
      ? singleSubscription?.priceAfterDiscount
      : singleSubscription?.price;

    // Set the price without applying coupon discount
    setPrice(updatedPrice);
  }, [singleSubscription]);

  // Handle payment logic
  useEffect(() => {
    if (paymentOption?.name === 'apple') {
      if (typeof window.Moyasar !== 'undefined') {
        try {
          window.Moyasar.init({
            element: '.payment-form',
            amount: Number(price - couponDiscount) * 100,  // Apply coupon discount here
            currency: 'SAR',
            description: (singleSubscription?.plan || '0') + ' Months payment',
            publishable_api_key: 'pk_live_1qKMEBh9iCmKjYCFvYhwAxGesCkznssU4szhPbSd',
            callback_url:
              process.env.REACT_APP_API_URL +
              '/auth/userSubscriptions/paymentCallback',
            methods: ['applepay'],
            apple_pay: {
              country: 'SA',
              label: 'Examist Subscription',
              validate_merchant_url: 'https://api.moyasar.com/v1/applepay/initiate',
            },
            metadata: {
              user: getId(user),
              subscription: getId(singleSubscription),
              paymentMethod: getId(paymentMethods?.[0]) || '',
              couponCode: coupon?.code,
            },
            on_completed: (paymentData) => {
              dispatch(
                sendPayment({
                  data: {
                    ...paymentData,
                    amount: Number(price - couponDiscount) * 100,  // Apply coupon discount here
                    subscription: getId(singleSubscription),
                    type: 'applepay',
                    paymentMethod: getId(paymentMethods?.[0]) || '',
                  },
                })
              );
            },
          });
        } catch (error) {
          console.error('Error initializing payment:', error);
        }
      } else {
        toastErrorMessage('error-moyasar');
      }
    }
  }, [paymentOption, price, couponDiscount]);

  // Check if Apple Pay can be used
  useEffect(() => {
    if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
      setCanUseApple(true);
    }
  }, []);
  const link = locale === 'ar' 
  ? process.env.REACT_APP_EXAMIST 
  : process.env.REACT_APP_EXAMISTEN;

  return (
    <div className="">
      <div className="card-body">
        <Row>
          {/* Payment details header can be placed here */}
        </Row>
        <div className="card payment-package-details">
          {singleSubscription != null ? (
            <>
              {/* Subscription Details */}
              <Row className="detail-row">
                <div>
                  <FormattedMessage id="sub-plan" />
                </div>
                <div>
                  {singleSubscription?.plan || '0'} <FormattedMessage id={singleSubscription?.plan > 1 ? 'months' : 'month'} />
                </div>
              </Row>

              {/* Original Price */}
              <Row className="detail-row">
                <div>
                  <FormattedMessage id="secondary-sum" />
                </div>
                <div>
                  {singleSubscription?.price || '0'}
                  <FormattedMessage id="sar" />
                </div>
              </Row>

              {/* Price after any initial discount */}
              <Row className="detail-row">
                <div>
                  <FormattedMessage id="priceAfterDiscount" />
                </div>
                <div>
                  {singleSubscription?.priceAfterDiscount || singleSubscription?.price}
                  <FormattedMessage id="sar" />
                </div>
              </Row>

              {/* Coupon Discount */}
              {coupon && (
                <Row className="detail-row">
                  <div>
                    <FormattedMessage id="discounCoupon" /> ({coupon.amount}%)
                  </div>
                  <div>
                    {couponDiscount}
                    <FormattedMessage id="sar" />
                  </div>
                </Row>
              )}

              {/* Total Price with Coupon Discount */}
              <Row className="detail-row last-row">
                <div>
                  <FormattedMessage id="total" />
                </div>
                <div>
                  {price - couponDiscount || '0'}
                  <FormattedMessage id="sar" />
                </div>
              </Row>

              {/* Payment Button or Apple Pay */}
              {paymentOption?.type !== 'tamara' && (
                <Row>
                  {paymentOption?.name !== 'apple' ? (
                    <button>
                      <FormattedMessage id="pay" />
                    </button>
                  ) : canUseApple ? (
                    <div className="payment-form"></div>
                  ) : (
                    <span className="alert alert-danger text-black text-center">
                      <FormattedMessage id="apple-pay-no-support" />
                    </span>
                  )}
                </Row>
              )}
            </>
          ) : (
            <div className="text-center p-3 pb-5">
              <FormattedMessage id="notFound" /> <FormattedMessage id="subscription" />
              <a href={`${link}subscriptions`} className="w-100 text-center py-1">
                <FormattedMessage id="pick-subscription" />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

 