import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const AuthRoute = ({ children }) => {
  const { search } = useLocation();
  const { isLoggedIn } = useSelector((state) => state.authentication);
  return isLoggedIn ? children : <Navigate to={`/login${search}`} />;
};

export default AuthRoute;
