import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import { getSession, getQuestionSuccess } from "store/actions";
import { ReactComponent as Correct } from "assets/svgs/correct.svg";
import { ReactComponent as InCorrect } from "assets/svgs/inCorrect.svg";
import { ReactComponent as SeeIcon } from "assets/svgs/seeIcon.svg";
import { handleIsoDate } from "helpers/functions";
import ExamResultComponent from "components/session/ExamResultComponent";
import "styles/sessions.css";
import Icon from "assets/svgs/sessions.svg";

const View = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { loading, error, singleSession } = useSelector(
    (state) => state.sessions
  );

  useEffect(() => {
    // if (singleSession.id != id)
    dispatch(getSession(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
      navigate("/sessions");
    }
  }, [error, formatMessage, locale, navigate]);

  const questionsRender =
    singleSession?.questions?.length > 0 &&
    singleSession?.questions.map((data, index) => {
      let { question } = data;
      if (question == undefined) question = {};
      return (
        <tr key={question?.id || index}>
          <td>{index + 1}</td>
          <td>
            <FormattedMessage id={question?.type || "notFound"} />
          </td>

          <td>
            {data?.answerAt ? (
              handleIsoDate(data?.answerAt)
            ) : data?.isSkipped ? (
              <FormattedMessage id="skipped" />
            ) : (
              <FormattedMessage id="notYet" />
            )}
          </td>
          <td>
            {data.isCorrect != undefined ? (
              data.isCorrect ? (
                <span className="badge rounded-pill bg-success">
                  <Correct width="24" height="24" fill="white" />
                </span>
              ) : (
                <span className="badge rounded-pill bg-danger">
                  <InCorrect width="24" height="24" fill="white" />
                </span>
              )
            ) : (
              <span className="badge rounded-pill bg-secondary">
                {data?.isSkipped ? (
                  <FormattedMessage id="skipped" />
                ) : (
                  <FormattedMessage id="notFound" />
                )}
              </span>
            )}
          </td>
          <td>
            <span className="badge rounded-pill bg-primary">
              <Link
                to={`/sessions/${id}/question/`}
                onClick={() =>
                  dispatch(
                    getQuestionSuccess({
                      question: {
                        ...question,
                        choisenAnswerIndex: data?.choisenAnswerIndex,
                      },
                    })
                  )
                }
              >
                <SeeIcon fill="white" />
              </Link>
            </span>
          </td>
        </tr>
      );
    });

  if (loading) return <Loader />;

  return (
    <>
      <div className="control-wrp">
        <Row>
          <Col>
            <div className="card">
              <h5>
                <img src={Icon} width={20} height={20} alt="" />
                <FormattedMessage id={"sessions"} />
                {" / "}
                {`${singleSession?.category?.name?.[locale] || ""} ${
                  singleSession?.category?.category?.name?.[locale] || ""
                }`}
              </h5>
            </div>
          </Col>
        </Row>
      </div>
      <div className="view-page session-add">
        <div className="card">
          <div className="card-body "></div>
          <form className="session-exam">
            <ExamResultComponent
              activeSession={singleSession}
              page="session-view"
              filterFlag={false}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default View;
