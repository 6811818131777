// import Image from 'next/image'
// import Link from 'next/link'
import React from "react";
import ResetPasswordForm from "components/login/resetPasswordForm";
// import icon from "/public/images/register/login.svg";
// import logo from "/public/images/logo.png";
import { FormattedMessage } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import icon from "assets/svgs/login.svg";

import "styles/login.css";
import { Button } from "react-bootstrap";

const ResetPassword = () => {
  const navigate = useNavigate();

  return (
    // <div className="register_page register_page">
    //   <div className="register_page_wrapper">
    //     <div className="logo_content">
    //       <Link href="/">
    //         <a className="form_logo">
    //           <img src={logo?.value} alt="examist" />
    //         </a>
    //       </Link>
    //     </div>
    //     <div className="form_contetn">
    //       <figure className="svg_shape">
    //         <img src={icon} alt="login icon" />
    //       </figure>
    //       <h3 className="head">
    //         <FormattedMessage id="returnPassword" />
    //       </h3>
    //       <p className="parg">
    //         <FormattedMessage id="willResetPassword" />
    //       </p>

    //       <ResetPasswordForm />

    //       <Link to="/login">
    //         <a className="back_to_home_page">
    //           <FormattedMessage id="back" />
    //         </a>
    //       </Link>
    //     </div>
    //   </div>
    // </div>
    <div className="reset_password_page">
      <div className="reset_password_page_wrapper">
        <div className="img_side">
          <img src="/reset_password.png" alt="Forget Password" />
        </div>
        <div className="form_side">
          <figure className="svg_shape lock_head_shape">
            <img src="/forget_password_head.png" alt="login icon" />
            <img src="/lock.svg" alt="Lock" className="lock" />
          </figure>
          <h3 className="head">
            <FormattedMessage id="returnPassword" />
          </h3>
          <p className="parg">
        <FormattedMessage id="enterNewPassword" />
          </p>

          <ResetPasswordForm />
          <Button
            variant="link"
            className="back mt-4"
            onClick={() => navigate(-1)}
          >
            <FormattedMessage id="back" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

// ResetPassword.getLayout = function pageLayout(page) {
//   return <>{page}</>;
// };
