import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import {
  ADD_SESSION,
  DELETE_SESSION,
  EDIT_SESSION,
  GET_ALL_SESSIONS,
  GET_ALL_ARCHIVED_SESSIONS,
  GET_SESSION,
  ARCHIVE_SESSION,
  GET_QUESTION,
  ANSWER_QUESTION,
  ANSWER_QUESTIONS,
  ADD_QUESTION_COMMENT,
  SAVED_QUESTIONS,
  GET_COMMENTED_ON_QUESTIONS,
  GET_ACTIVE_SESSION,
  GET_TYPE_ON_QUESTIONS,
} from './actionTypes';
import {
  addSessionApi,
  getAllSessionsApi,
  getAllArchivedSessionsApi,
  getSessionApi,
  getQuestionApi,
  editSessionApi,
  deleteSessionApi,
  archiveSessionApi,
  answerQuestionApi,
  answerQuestionsApi,
  addQuestionCommentApi,
  getSavedQuestionsApi,
  getCommentedOnQuestionsApi,
  getAllSessionsTypesApi,
  getTypeQuestionsApi,
} from 'api/sessions';
import {
  getActiveSessionFailure,
  getActiveSessionSuccess,
  deleteSessionFailure,
  deleteSessionSuccess,
  editSessionFailure,
  editSessionSuccess,
  getAllSessionsFailure,
  getAllSessionsSuccess,
  getAllArchivedSessionsFailure,
  getAllArchivedSessionsSuccess,
  getSessionFailure,
  getSessionSuccess,
  getQuestionFailure,
  getQuestionSuccess,
  archiveSessionSuccess,
  answerQuestionSuccess,
  answerQuestionFailure,
  answerQuestionsSuccess,
  answerQuestionsFailure,
  addQuestionCommentSuccess,
  addQuestionCommentFailure,
  getSavedQuestionsSuccess,
  getSavedQuestionsFailure,
  getCommentedOnQuestionsSuccess,
  getCommentedOnQuestionsFailure,
  archiveSessionFailure,
  getTypeQuestionsSuccess,
  getTypeQuestionsFailure,
} from './actions';

import { toastErrorMessage } from 'components/shared/toastErrorMessage';
import { formatMessage } from '@formatjs/intl';
import Cookies from 'js-cookie';
import { getId } from 'helpers/functions';

function* sagaCheckActiveSub() {
  // const state = yield select();
  // // console.log("state add sessopn", state);
  // if (state?.authentication?.user?.currentSubscription?.status != "active") {
  //   toastErrorMessage("subscription-not-active");
  // if (typeof setTimeout != "undefined")
  //   setTimeout(() => (document.location.pathname = "/payment/required"), 900);
  return true;
  // }
}

function handleError(err) {
  // console.log("err", err, err?.response?.data);
  if (err?.response?.data?.message == 'notSubscribed') {
    toastErrorMessage('subscription-not-found');
    if (typeof setTimeout != 'undefined')
      setTimeout(
        () => (document.location.pathname = '/payment/required'),
        1500
      );
  } else if ((err?.statusCode || err?.response?.status || err?.status) == 403) {
    Cookies.set('dash-token', '', {
      domain: process.env.REACT_APP_COOKIES_DOMAIN,
      expires: 365,
    });

    Cookies.remove('dash-token');
    toastErrorMessage('token-expired');

    setTimeout(() => window?.location?.reload(), 900);
  } else {
    return err?.response?.data || err;
  }
}
function* getAllSessions({ payload }) {
  try {
    const { data } = yield call(getAllSessionsApi, payload);

    // const sessions =
    data?.sessions?.sort((a, b) => a?.endAt - b?.endAt);
    yield put(getAllSessionsSuccess(data));

    // let activeSession = data.sessions?.find((s) => s?.status != "expired");
    // if (activeSession) {
    //   // let questionsUnanswered = activeSession?.questions?.find(
    //   // (q) => !q?.choisenAnswerIndex
    //   // );
    //   // if (questionsUnanswered) {
    //   yield call(getActiveSession, getId(activeSession));
    //   payload?.navigate("/sessions/exam");
    //   // }
    // }
  } catch (error) {
    console.log(error);
    yield put(getAllSessionsFailure(error?.response?.data || error));
  }
}

function* getAllArchivedSessions() {
  try {
    const { data } = yield call(getAllArchivedSessionsApi);
    yield put(getAllArchivedSessionsSuccess(data));
  } catch (error) {
    yield put(getAllArchivedSessionsFailure(error?.response?.data || error));
  }
}

function* addSession({ payload }) {
  try {
    let check = yield sagaCheckActiveSub();
    if (!check) return;

    const { data } = yield call(addSessionApi, payload.data);

    yield call(getActiveSession, data?.session?.id);
    payload?.navigate('/sessions/exam');
  } catch (error) {
    console.log(error);
    yield put(
      getActiveSessionFailure(handleError(error?.response?.data || error))
    );
  }
}

function* archiveSession({ payload }) {
  try {
    yield call(archiveSessionApi, payload);
    yield put(archiveSessionSuccess(payload));
  } catch (error) {
    yield put(archiveSessionFailure(error?.response?.data || error));
  }
}

function* getActiveSession(id) {
  id = id?.payload || id;
  try {
    const { data } = yield call(getSessionApi, id);

    yield put(getActiveSessionSuccess({ session: { ...data.session, id } }));
  } catch (error) {
    console.log(error);
    yield put(getActiveSessionFailure(error?.response?.data || error));
  }
}

function* getSession({ payload: id }) {
  try {
    const { data } = yield call(getSessionApi, id);

    yield put(getSessionSuccess({ ...data.session, id }));
  } catch (error) {
    yield put(getSessionFailure(error?.response?.data || error));
  }
}

function* getQuestion({ payload }) {
  try {
    const { data } = yield call(getQuestionApi, payload);

    yield put(getQuestionSuccess(data));
  } catch (error) {
    yield put(getQuestionFailure(error?.response?.data || error));
  }
}

function* addQuestionComment({ payload }) {
  let check = yield sagaCheckActiveSub();
  if (!check) return;

  try {
    const { data } = yield call(addQuestionCommentApi, payload);
    yield put(
      addQuestionCommentSuccess({ data, index: payload?.questionIndex || 0 })
    );
  } catch (error) {
    console.log(error);
    yield put(addQuestionCommentFailure(handleError(error)));
  }
}

function* answerQuestion({ payload }) {
  try {
    let check = yield sagaCheckActiveSub();
    if (!check) return;
    const { data } = yield call(answerQuestionApi, payload);
    yield put(
      answerQuestionSuccess({
        session: { ...data.session, testAnswer: payload?.question?.testAnswer },
      })
    );
    // payload?.navigate("/sessions");
  } catch (error) {
    yield put(
      answerQuestionFailure(handleError(error?.response.data || error))
    );
  }
}

function* answerQuestions({ payload }) {
  try {
    let check = yield sagaCheckActiveSub();
    if (!check) return;

    const { data } = yield call(answerQuestionsApi, payload);

    yield put(
      answerQuestionsSuccess({ session: { ...data?.session, result: true } })
    );
    // payload?.navigate("/sessions");
  } catch (error) {
    yield put(
      answerQuestionsFailure(handleError(error?.response?.data || error))
    );
  }
}

function* editSession({ payload }) {
  try {
    const { data } = yield call(editSessionApi, {
      data: payload.data,
      id: payload.id,
    });
    yield put(editSessionSuccess(data));
  } catch (error) {
    yield put(editSessionFailure(error?.response?.data || error));
  }
}

function* deleteSession({ payload }) {
  try {
    yield call(deleteSessionApi, payload);
    yield put(deleteSessionSuccess(payload));
  } catch (error) {
    yield put(deleteSessionFailure(error?.response?.data || error));
  }
}

function* savedQuestions() {
  try {
    const { data } = yield call(getSavedQuestionsApi);
    yield put(getSavedQuestionsSuccess(data));
  } catch (error) {
    yield put(getSavedQuestionsFailure(error?.response?.data || error));
  }
}

function* commentedOnQuestions() {
  try {
    const { data } = yield call(getCommentedOnQuestionsApi);
    yield put(getCommentedOnQuestionsSuccess(data));
  } catch (error) {
    yield put(getCommentedOnQuestionsFailure(error?.response?.data || error));
  }
}

function* typeQuestions({ payload }) {
  try {
    const { data } = yield call(getTypeQuestionsApi, payload);
    yield put(getTypeQuestionsSuccess(data));
  } catch (error) {
    yield put(getTypeQuestionsFailure(error?.response?.data || error));
  }
}

export function* watchGetAllSessions() {
  yield takeEvery(GET_ALL_SESSIONS, getAllSessions);
}
export function* watchGetAllArchivedSessions() {
  yield takeEvery(GET_ALL_ARCHIVED_SESSIONS, getAllArchivedSessions);
}

export function* watchAddSession() {
  yield takeEvery(ADD_SESSION, addSession);
}
export function* watchArchiveSession() {
  yield takeEvery(ARCHIVE_SESSION, archiveSession);
}

export function* watchGetSession() {
  yield takeEvery(GET_SESSION, getSession);
}

export function* watchGetActiveSession() {
  yield takeEvery(GET_ACTIVE_SESSION, getActiveSession);
}

export function* watchGetQuestion() {
  yield takeEvery(GET_QUESTION, getQuestion);
}

export function* watchAddAnswerCommentQuestion() {
  yield takeEvery(ADD_QUESTION_COMMENT, addQuestionComment);
}

export function* watchanswerQuestion() {
  yield takeEvery(ANSWER_QUESTION, answerQuestion);
}

export function* watchanswerQuestions() {
  yield takeEvery(ANSWER_QUESTIONS, answerQuestions);
}

export function* watchEditSession() {
  yield takeEvery(EDIT_SESSION, editSession);
}

export function* watchDeleteSession() {
  yield takeEvery(DELETE_SESSION, deleteSession);
}

export function* watchGetSavedQuestions() {
  yield takeEvery(SAVED_QUESTIONS, savedQuestions);
}

export function* watchGetCommentedOnQuestions() {
  yield takeEvery(GET_COMMENTED_ON_QUESTIONS, commentedOnQuestions);
}

export function* watchGetTypeQuestions() {
  yield takeEvery(GET_TYPE_ON_QUESTIONS, typeQuestions);
}

function* sessionsSaga() {
  yield all([
    fork(watchGetAllSessions),
    fork(watchGetAllArchivedSessions),
    fork(watchAddSession),
    fork(watchGetSession),
    fork(watchEditSession),
    fork(watchDeleteSession),
    fork(watchArchiveSession),
    fork(watchGetQuestion),
    fork(watchAddAnswerCommentQuestion),
    fork(watchanswerQuestion),
    fork(watchanswerQuestions),
    fork(watchGetActiveSession),
    fork(watchGetSavedQuestions),
    fork(watchGetCommentedOnQuestions),
    fork(watchGetTypeQuestions),
  ]);
}

export default sessionsSaga;
