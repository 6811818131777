import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const SubscriptionRoute = ({ children }) => {
  const { userStats } = useSelector((state) => state.authentication);
  return userStats?.currentSubscription ? (
    children
  ) : (
    <Navigate to={`/subscriptions`} />
  );
};

export default SubscriptionRoute;
