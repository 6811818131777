export const SEND_PAYMENT = "SEND_PAYMENT";
export const SEND_PAYMENT_FAILURE = "SEND_PAYMENT_FAILURE";
export const SEND_PAYMENT_SUCCESS = "SEND_PAYMENT_SUCCESS";

export const SEND_COUPON_CODE = "SEND_COPOUN_CODE";
export const SEND_COUPON_CODE_FAILURE = "SEND_COUPON_CODE_FAILURE";
export const SEND_COUPON_CODE_SUCCESS = "SEND_COUPON_CODE_SUCCESS";


export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS";
export const GET_PAYMENT_METHODS_SUCCESS = "GET_PAYMENT_METHODS_SUCCESS";
export const GET_PAYMENT_METHODS_FAILURE = "GET_PAYMENT_METHODS_FAILURE";

export const GET_APPLE_PAY_MERCHANT_SESSION = "GET_APPLE_PAY_MERCHANT_SESSION";
export const GET_APPLE_PAY_MERCHANT_SESSION_SUCCESS =
  "GET_APPLE_PAY_MERCHANT_SESSION_SUCCESS";
export const GET_APPLE_PAY_MERCHANT_SESSION_FAILURE =
  "GET_APPLE_PAY_MERCHANT_SESSION_FAILURE";
