// import Image from 'next/image'
// import Link from 'next/link'
import React from "react";
import ForgotPasswordForm from "components/login/forgotPasswordForm";
// import icon from "/public/images/register/login.svg";
// import logo from "/public/images/logo.png";
import { FormattedMessage } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import icon from "assets/svgs/login.svg";

import { Button } from "react-bootstrap";

const ForgotPassword = ({ logo }) => {
  const navigate = useNavigate();

  const submit = () => {
    navigate("resetPassword");
  };

  return (
    <div className="forget_password_page">
      <div className="forget_password_page_wrapper">
        <div className="img_side">
          <img src="/forget_password.png" alt="Forget Password" />
        </div>
        <div className="form_side">
          <figure className="svg_shape lock_head_shape">
            <img src="/forget_password_head.png" alt="login icon" />
            <img src="/lock.svg" alt="Lock" className="lock" />
          </figure>
          <h3 className="head">
            <FormattedMessage id="returnPassword" />
          </h3>
          <p className="parg">
            <FormattedMessage id="willSendToRestPassword" />
          </p>

          <ForgotPasswordForm />

          <Button
            variant="link"
            className="back mt-4"
            onClick={() => navigate(-1)}
          >
            <FormattedMessage id="back" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

ForgotPassword.getLayout = function pageLayout(page) {
  return <>{page}</>;
};
