import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
  const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Chart = ({ userSession, answersPercentage }) => {
  const data = [
    {
      name: `${Math.round((userSession?.correctAnswers / userSession?.numberOfQuestions) * 100)}%`,
      value: +answersPercentage?.percentageRight,
      color: "#2AD352",
    },
    {
      name: `${Math.round((userSession?.correctAnswers / userSession?.numberOfQuestions) * 100)}%`,
      value: +answersPercentage?.percentageWrong,
      color: "#FF0059",
    },
    {
      name: `${Math.round((userSession?.correctAnswers / userSession?.numberOfQuestions) * 100)}%`,
      value: +answersPercentage?.percentagePassed,
      color: "#456EEA",
    },
  ];

  const COLORS = ["#2AD352", "#FF0059", "#456EEA"];

  return (
    <ResponsiveContainer width="100%" height={200}>
      <PieChart width={200} height={200}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default Chart;
